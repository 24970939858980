import {useEffect} from 'react';
import {NotificationManager} from 'react-notifications';

import useCurrentBusinessId from '../../lib/hooks/use-current-business-id';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import Confirmation from '../../components/organisms/Confirmation';

import {useIntl} from '../../lib/intl';
import useAppLanguage from '../../lib/intl/hooks/use-app-language';
import {useMoveBusinessInformationSectionBlockMutation} from '../../redux/businessInformationSectionsApi';

const MoveBusinessInformationSectionBlock = ({
  direction,
  open,
  sectionBlock,
  onClose,
}: {
  direction: 'up' | 'down';
  open: boolean;
  sectionBlock: BusinessInformationSectionBlock;
  onClose: () => void;
}) => {
  const lang = useAppLanguage();
  const businessId = useCurrentBusinessId();
  const [move, mutation] = useMoveBusinessInformationSectionBlockMutation();
  const intl = useIntl();
  const directionLabel =
    direction === 'up'
      ? intl.formatMessage({
          id: 'moveBusinessInformationSectionBlock.up',
        })
      : intl.formatMessage({
          id: 'moveBusinessInformationSectionBlock.down',
        });

  useQueryInternalError(mutation);

  const label = sectionBlock.headingIntl?.[lang]
    ? `"${sectionBlock.headingIntl[lang]}"`
    : intl.formatMessage({
        id: 'moveBusinessInformationSectionBlock.subsection',
      });

  useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success(
        intl.formatMessage({
          id: 'moveBusinessInformationSectionBlock.success',
        }),
      );
      onClose();
    }
  }, [mutation.isSuccess]);

  return (
    <Confirmation
      open={open}
      isLoading={mutation.isLoading}
      onClose={onClose}
      description={intl.formatMessage({
        id: 'moveBusinessInformationSectionBlock.description',
        values: {
          label,
          directionLabel,
        },
      })}
      onConfirm={() =>
        move({
          id: sectionBlock._id,
          business: businessId,
          direction,
        })
      }
    />
  );
};

export default MoveBusinessInformationSectionBlock;
