export default {
  'settings.breadcrumbs': 'Configuración',
  'settings.title': 'Configuración',
  'settings.accountInformation': 'Información de la cuenta',
  'settings.updateBusinessInformation':
    'Modificar la información de mi establecimiento',
  'settings.downloadQRCode': 'Descargar código QR',
  'settings.extraSettings': 'Configuraciones adicionales',
  'settings.updateBusinessLogo': 'Cambiar el logotipo de mi empresa',
  'settings.changeBusiness': `Cambiar establecimiento`,
  'settings.contactUs': 'Contáctanos',
  'settings.paymentAndInvoices': 'Pagos y Facturas',
  'settings.deleteAccount': 'Eliminar mi cuenta',
  'settings.logout': 'Cerrar sesión',
};
