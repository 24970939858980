import {LoadingButton, LoadingButtonProps} from '@mui/lab';
import {Box} from '@mui/material';
import {FormattedMessage} from '../../lib/intl';

const SaveButton = (props: LoadingButtonProps) => (
  <>
    <Box
      sx={{
        height: 56,
        width: '100%',
      }}
    />
    <LoadingButton
      sx={{
        position: 'fixed',
        bottom: 2,
        left: theme => theme.spacing(2),
        right: theme => theme.spacing(2),
      }}
      fullWidth
      size="large"
      variant="contained"
      color="primary"
      {...props}>
      <FormattedMessage id="common.save" />
    </LoadingButton>
  </>
);

export default SaveButton;
