export default {
  'authPhoneNumber.invalidPhoneNumber':
    'Le Numéro téléphone que vous avez entré est invalide (Ex: +33611222333, +12481237654, +34600000000)',
  'authPhoneNumber.tooManyRequests':
    'Vous avez essayer de vous connecter plusieurs fois. Réssayer plus tard',
  'completeInformation.title': 'Créer votre établissement',
  'completeInformation.accountInformation': 'Informations du compte',
  'completeInformation.phoneNumber': 'Numéro de téléphone',
  'completeInformation.changePhoneNumber': 'Changer le numéro de téléphone',
  'completeInformation.generalInformation': 'Informations générales',
  'completeInformation.businessName': `Nom de l'établissement`,
  'completeInformation.uploadLogo': 'Télécharger un logo',
  'completeInformation.email': 'Email',
  'completeInformation.lastName': 'Nom',
  'completeInformation.firstName': 'Prénom',
  'completeInformation.description': 'Description',
  'completeInformation.activitySector': `Secteur d'activité`,
  'completeInformation.website': 'Site web',
  'completeInformation.receivedCode': 'Le code de validation reçu par SMS',
  'completeInformation.confirmationCode': 'Code de confirmation',
  'completeInformation.successMessage': 'Votre inscription est complète',
  'formikPhoneNumberInput.countryNotFound':
    'Aucun pays correspond à votre recherche',
  'googleRecaptchaDisclaimer.message': `Ce site est protégé par reCAPTCHA et la poltique de confidentialité de Google et les termes de services s'appliquent.`,
  'helpSection.bullet1': `Créez vous rubriques et vos sous-rubriques : Insérez la description de vos services avec vos plus belles photos`,
  'helpSection.bullet2': `Téléchargez et affichez votre QR code : vos clients accèdent à toutes vos prestations sur un QR code unique`,
  'helpSection.bullet3': `Créez des QR Codes Professionnels Personnalisés : Concevez des QR codes uniques pour vos menus de restaurants, répertoires d'hôtel ou présentations de services, avec des options de personnalisation avancées.`,
  'helpSection.bullet4': `Gérez plusieurs Etablissements : Vous pouvez créer autant d'établissements que vous le souhaitez. Chaque établissement a son QR code`,
  'helpSection.bullet5': `Profitez d'un Support Client Réactif : Notre équipe dévouée est là pour vous aider à chaque étape du processus, que ce soit pour résoudre un problème technique ou vous conseiller sur les meilleures pratiques en matière de QR codes professionnels.`,
  'helpSection.bullet6':
    'Gérez vos QR Codes en Toute Simplicité : Gérez et mettez à jour vos QR codes en temps réel, avec des fonctionnalités multiples.',
  'helpSection.forMoreInfo': "Pour plus d'informations sur",
  'phoneNumberForm.digitalizeServices': 'Digitalisez vos prestations',
  'phoneNumberForm.printedSupports':
    'Donnez vie à vos supports imprimés avec des QR codes',
  'phoneNumberForm.yourMobileNumber': 'Votre numéro de mobile :',
  'phoneNumberForm.cgu1': `En continuant, vous autorisez FluXence Pro à conserver votre numéro
            de téléphone, et vous acceptez nos`,
  'phoneNumberForm.cgu2': `conditions d'utilisations`,
  'phoneNumberForm.cgu3': `et`,
  'phoneNumberForm.cgu4': `notre politique de confidentialité`,
  'resendCodeButton.didNotReceiveCode': `J'ai pas reçu de code!`,
  'signinConfirmationCode.invalidCode':
    'Le code que vous avez entré est invalide',
  'signinConfirmationCode.tooManyRequests':
    'Vous avez essayer de vous connecter plusieurs fois. Réssayer plus tard',
  'signinConfirmationCode.smsSending':
    'Envoi de SMS en cours. Veuillez patienter',
  'signupConfirmationCode.invalidCode':
    'Le code que vous avez entré est invalide',
  'signupConfirmationCode.tooManyRequests':
    'Vous avez essayer de vous connecter plusieurs fois. Réssayer plus tard',
  'signupConfirmationCode.smsSending':
    'Envoi de SMS en cours. Veuillez patienter',
  'helpSection.whyChooseUs': 'Pourquoi nous choisir ?',
  'helpSection.forWho': 'Pour qui?',
  'helpSection.forWho1': `Restaurants : votre menu sur QR code`,
  'helpSection.forWho2': `Hôtels, AirBnb : votre livret d’accueil sur QR code`,
  'helpSection.forWho3': `Professionnels : vos prestations sur QR code`,
};
