export default {
  'authPhoneNumber.invalidPhoneNumber':
    'El número de teléfono que ingresó no es válido (Ej: +33611222333, +12481237654, +34600000000)',
  'authPhoneNumber.tooManyRequests':
    'Has intentado iniciar sesión varias veces. Vuelve a intentarlo más tarde',
  'completeInformation.title': 'Crea tu establecimiento',
  'completeInformation.accountInformation': 'Información de la cuenta',
  'completeInformation.phoneNumber': 'Número de teléfono',
  'completeInformation.email': 'Email',
  'completeInformation.lastName': 'Apellido',
  'completeInformation.changePhoneNumber': 'Cambiar número de teléfono',
  'completeInformation.generalInformation': 'Información general',
  'completeInformation.businessName': `Nombre del establecimiento`,
  'completeInformation.uploadLogo': 'Subir un logotipo',
  'informacióncompleta.email': 'Correo electrónico',
  'Información completa.apellido': 'Nombre',
  'completeInformation.firstName': 'Nombre',
  'completeInformation.activitySector': `Sector de actividad`,
  'completeInformation.website': 'Sitio web',
  'completeInformation.description': 'Descripción',
  'completeInformation.receivedCode':
    'El código de validación recibido por SMS',
  'completeInformation.successMessage': 'Tu registro está completo',
  'completeInformation.confirmationCode': 'Código de confirmación',
  'formikPhoneNumberInput.countryNotFound':
    'Ningún país coincide con su búsqueda',
  'googleRecaptchaDisclaimer.message': `Este sitio está protegido por reCAPTCHA y se aplican la Política de privacidad y los Términos de servicio de Google.`,
  'helpSection.bullet1': `Crea tus secciones y subsecciones: Inserta la descripción de tus servicios con tus mejores fotos`,
  'helpSection.bullet2': `Descarga y muestra tu código QR: tus clientes acceden a todos tus servicios en un único código QR`,
  'helpSection.bullet3': `Cree códigos QR profesionales personalizados: diseñe códigos QR únicos para los menús de sus restaurantes, directorios de hoteles o presentaciones de servicios, con opciones de personalización avanzadas.`,
  'helpSection.bullet4': `Administrar múltiples Establecimientos: Puede crear tantos establecimientos como desee. Cada establecimiento tiene su código QR`,
  'helpSection.bullet5': `Disfrute de una atención al cliente receptiva: nuestro equipo dedicado está aquí para ayudarlo en cada paso del camino, ya sea resolviendo un problema técnico o asesorándolo sobre las mejores prácticas para códigos QR profesionales.`,
  'helpSection.bullet6':
    'Administre sus códigos QR con facilidad: administre y actualice sus códigos QR en tiempo real, con múltiples funciones.',
  'helpSection.forMoreInfo': 'Para obtener más información sobre',
  'phoneNumberForm.digitalizeServices': 'Digitaliza tus servicios',
  'phoneNumberForm.printedSupports':
    'Da vida a tus medios impresos con códigos QR',
  'phoneNumberForm.yourMobileNumber': 'Su número de móvil:',
  'phoneNumberForm.cgu1': `Al continuar, autorizas a FluXence Pro a almacenar tu número
            número de teléfono, y aceptas nuestro`,
  'phoneNumberForm.cgu2': `condiciones de uso`,
  'phoneNumberForm.cgu3': `y`,
  'phoneNumberForm.cgu4': `nuestra política de privacidad`,
  'resendCodeButton.didNotReceiveCode': `¡No recibí un código!`,
  'signinConfirmationCode.invalidCode': 'El código que ingresaste no es válido',
  'signinConfirmationCode.tooManyRequests':
    'Has intentado iniciar sesión varias veces. Vuelve a intentarlo más tarde',
  'signinConfirmationCode.smsSending':
    'Envío de SMS en curso. Espere por favor',
  'signupConfirmationCode.invalidCode': 'El código que ingresaste no es válido',
  'signupConfirmationCode.tooManyRequests':
    'Has intentado iniciar sesión varias veces. Vuelve a intentarlo más tarde',
  'signupConfirmationCode.smsSending':
    'Envío de SMS en curso. Espere por favor',
  'helpSection.whyChooseUs': '¿Por qué elegirnos?',
  'helpSection.forWho': '¿Para quién?',
  'helpSection.forWho1': `Restaurantes: tu menú en código QR`,
  'helpSection.forWho2': `Hoteles, AirBnb: tu folleto de bienvenida en código QR`,
  'helpSection.forWho3': `Profesionales: tus servicios en código QR`,
};
