import LoadingButton from '@mui/lab/LoadingButton';
import {FormControlLabel, Stack} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import {ComponentProps, useEffect, useState} from 'react';
import {useDebouncedCallback} from 'use-debounce';
import {useTranslateIntlContentMutation} from '../../../redux/commonApi';
import useQueryInternalError from '../../hooks/useQueryInternalError';
import {LanguageCode} from '../index';
import {IntlContent} from '../types';

const IntlContentTranslationButton = ({
  text,
  baseLang,
  format = 'text',
  shouldTranslate,
  onTranslateComplete,
  ...props
}: {
  text: string;
  format?: 'text' | 'html';
  baseLang: LanguageCode;
  shouldTranslate: boolean;
  onTranslateComplete: (val: IntlContent) => void;
} & ComponentProps<typeof Stack>) => {
  const [automatic, setAutomatic] = useState<boolean>(true);
  const [translateIntlContent, mutation] = useTranslateIntlContentMutation();
  const debouncedTranslateIntlContent = useDebouncedCallback(
    translateIntlContent,
    1000,
  );

  useQueryInternalError(mutation, true);

  useEffect(() => {
    if (shouldTranslate && automatic && text?.length > 3) {
      debouncedTranslateIntlContent({
        baseLang,
        text,
        format,
      });
    }
  }, [shouldTranslate, automatic, text, debouncedTranslateIntlContent]);

  useEffect(() => {
    if (mutation.isSuccess && mutation.data) {
      onTranslateComplete(mutation.data);
    }
  }, [mutation.isSuccess, onTranslateComplete]);

  return (
    <Stack spacing={1} direction="row" {...props}>
      <FormControlLabel
        label={'Traduction automatique'}
        control={
          <Checkbox
            checked={automatic}
            onChange={() => setAutomatic(!automatic)}
          />
        }
      />
      <LoadingButton
        size="small"
        onClick={() =>
          translateIntlContent({
            baseLang,
            text,
            format,
          })
        }
        loading={mutation.isLoading}
        disabled={automatic}
        variant="contained"
        color="secondary">
        Traduire
      </LoadingButton>
    </Stack>
  );
};

export default IntlContentTranslationButton;
