import React, {ComponentProps} from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import {
  CountryCode,
  getCountryCallingCode,
  parsePhoneNumber,
} from 'libphonenumber-js';

import {Search} from '@mui/icons-material';
import {useField, useFormikContext} from 'formik';
import countries from 'i18n-iso-countries';
import replaceAccents from '../../../lib/helpers/removeAccents';
import {FormattedMessage, useIntl} from '../../../lib/intl';
import useAppLanguage from '../../../lib/intl/hooks/use-app-language';
import EmptyList from '../../molecules/EmptyList';

const ComponentContext = React.createContext({
  name: '',
  countryName: '',
  defaultCountryValue: '',
});

const CustomInput = React.forwardRef(({onKeyDown, ...props}: any, ref: any) => {
  const {name} = React.useContext(ComponentContext);
  const [, meta, helpers] = useField(name);
  const theme = useTheme();

  return (
    <Box
      sx={{
        flex: 1,
        '& > input': {
          border: `1px solid ${
            meta.touched && !!meta.error ? theme.palette.error.main : '#AAA'
          }`,
          borderRadius: 1,
          padding: '18px 14.5px',
          fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontSize: '1rem',
          width: '100%',
        },
      }}>
      <input ref={ref} {...props} value={props.value} />
    </Box>
  );
});

const Container = (props: any) => (
  <Stack direction="row" spacing={1} {...props} />
);

const SelectComponent = React.forwardRef(
  ({iconComponent, options, ...props}: any, ref) => {
    const intl = useIntl();
    const lang = useAppLanguage();
    const {name, countryName, defaultCountryValue} =
      React.useContext(ComponentContext);
    const [, meta] = useField(name);
    const [countryField] = useField(countryName);

    const [search, setSearch] = React.useState('');

    const filteredOptions = React.useMemo(() => {
      return options.filter((option: any) => {
        if (!search) {
          return true;
        }

        const accentlessSearch = replaceAccents(search.toLowerCase());

        const optionValue = replaceAccents(option.value?.toLowerCase());
        const optionLabel = replaceAccents(
          getCountryName(option.value, lang)?.toLowerCase(),
        );

        return (
          optionValue.includes(accentlessSearch) ||
          optionLabel.includes(accentlessSearch) ||
          (option.value &&
            getCountryCallingCode(option.value as any)?.includes(
              accentlessSearch,
            ))
        );
      });
    }, [options, search]);

    const IconComponent = iconComponent;
    const countryValue = countryField.value || defaultCountryValue;

    return (
      <FormControl>
        <InputLabel>
          <FormattedMessage id="formikPhoneNumberInput.country" />
        </InputLabel>
        <Select
          ref={ref}
          {...props}
          label={intl.formatMessage({
            id: 'formikPhoneNumberInput.country',
          })}
          renderValue={value => {
            if (!value) {
              return null;
            }

            return (
              <Stack direction="row" spacing={1} alignItems="center">
                <IconComponent country={value as any} label={value as any} />
                <Typography>
                  {getCountryCodeLabel(value as any, false, lang.toLowerCase())}
                </Typography>
              </Stack>
            );
          }}
          value={props.value}
          error={meta.touched && !!meta.error}
          MenuProps={{
            sx: {
              paddingTop: 70,
            },
            PaperProps: {
              sx: {
                width: '100%',
                height: '100%',
                top: '0!important',
              },
            },
          }}
          onChange={evt => {
            props.onChange(evt.target.value);
            setSearch('');
          }}>
          <Box
            p={1}
            sx={{
              position: 'sticky',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 1,
              backgroundColor: 'white',
            }}>
            <TextField
              InputProps={{
                startAdornment: <Search />,
                inputProps: {
                  autoComplete: 'nothing',
                },
              }}
              label={intl.formatMessage({id: 'formikPhoneNumberInput.search'})}
              onChange={evt => setSearch(evt.target.value)}
              fullWidth
            />
          </Box>
          {countryValue && (
            <MenuItem value={countryValue}>
              <Stack direction="row" spacing={1} alignItems="center">
                {countryValue && (
                  <IconComponent
                    country={countryValue}
                    label={getCountryCodeLabel(
                      countryValue,
                      false,
                      lang.toLowerCase(),
                    )}
                  />
                )}
                <Typography>
                  {getCountryCodeLabel(
                    countryField.value,
                    true,
                    lang.toLowerCase(),
                  )}
                </Typography>
              </Stack>
            </MenuItem>
          )}
          {filteredOptions.length === 0 && (
            <EmptyList
              message={intl.formatMessage({
                id: 'formikPhoneNumberInput.countryNotFound',
              })}
            />
          )}
          {filteredOptions
            .filter(
              (option: any) => !!option.value && option.value !== countryValue,
            )
            .map((option: any) => (
              <MenuItem key={option.value} value={option.value}>
                <Stack direction="row" spacing={1} alignItems="center">
                  {option.value && option.label && (
                    <IconComponent
                      country={option.value}
                      label={option.label}
                    />
                  )}
                  <Typography>
                    {getCountryCodeLabel(
                      option.value,
                      true,
                      lang.toLowerCase(),
                    )}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  },
);

const OTHER_COUNTRIES: {
  [key: string]: {
    [lang: string]: string;
  };
} = {
  AC: {
    fr: "Île de l'Ascension",
    en: 'Ascension Island',
    es: 'Isla de la Ascensión',
    de: 'Ascension',
  },
  TA: {
    fr: 'Sainte-Hélène',
    en: 'Saint Helena',
    es: 'Santa Elena',
    de: 'St. Helena',
  },
};

const getCountryName = (val: string, lang: string) => {
  if (val) {
    return countries.getName(val, lang) || OTHER_COUNTRIES[val]?.[lang] || val;
  }

  return '';
};

const getCountryCodeLabel = (
  val: string,
  countryVisible: boolean,
  lang: string,
) => {
  if (val) {
    const callingCode = getCountryCallingCode(val as any);
    const countryName =
      countries.getName(val, lang) || OTHER_COUNTRIES[val]?.[lang] || val;
    return `(+${callingCode})${countryVisible ? ' ' + countryName : ''}`;
  }

  return '';
};

const FormikPhoneNumberInput = ({
  name,
  extName,
  countryName,
  disabled,
}: {
  name: string;
  countryName: string;
  extName: string;
} & Omit<ComponentProps<typeof PhoneInput>, 'onChange' | 'value'>) => {
  const form = useFormikContext<{
    [key: string]: string;
  }>();
  const [field, meta] = useField(name);
  return (
    <ComponentContext.Provider
      value={{
        name,
        countryName,
        defaultCountryValue: form.values[countryName] as string,
      }}>
      <Box>
        <PhoneInput
          defaultCountry={form.values[countryName] as CountryCode}
          value={field.value}
          onChange={value => {
            if (value) {
              try {
                const phoneNumber = parsePhoneNumber(
                  value,
                  form.values[countryName] as CountryCode,
                );
                form.setFieldValue(
                  name,
                  phoneNumber?.format('E.164') || value || '',
                );
                form.setFieldValue(extName, phoneNumber?.nationalNumber || '');
              } catch (err) {
                form.setFieldValue(name, value);
                form.setFieldValue(extName, '');
              }
            } else {
              form.setFieldValue(name, '');
              form.setFieldValue(extName, '');
            }
          }}
          disabled={disabled}
          inputComponent={CustomInput}
          containerComponent={Container}
          countrySelectComponent={SelectComponent}
          onCountryChange={val => {
            form.setFieldValue(countryName, val);
          }}
        />
        {meta.touched && meta.error && (
          <FormHelperText error>{meta.error as string}</FormHelperText>
        )}
      </Box>
    </ComponentContext.Provider>
  );
};

export default FormikPhoneNumberInput;
