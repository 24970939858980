export default {
  'validation.fieldRequired': 'Erforderliches Feld',
  'validation.inValidEmail': 'Ungültige E-Mail',
  'validation.tryAnotherAddress':
    'Geben Sie eine andere Adresse ein oder versuchen Sie es erneut',
  'validation.inValidUrl':
    'Die URL ist ungültig. Geben Sie die URL mit http(s):// ein, z. B.: https://www.fluxence.com',
  'validation.requiredField': 'Erforderliches Feld',
  'validation.emailUsed': 'E-Mail bereits verwendet',
  'validation.phoneUsed': 'Telefonnummer bereits verwendet',
  'validation.invalidPhoneNumber':
    'Ungültige Telefonnummer (z. B.: +33611222333, +12481237654, +34600000000)',
};
