export default {
  'settings.breadcrumbs': 'Settings',
  'settings.title': 'Settings',
  'settings.accountInformation': 'Account Information',
  'settings.updateBusinessInformation': 'Update my business information',
  'settings.downloadQRCode': 'Download QR Code',
  'settings.extraSettings': 'Extra Settings',
  'settings.updateBusinessLogo': 'Change my business logo',
  'settings.changeBusiness': `Change business`,
  'settings.contactUs': 'Contact us',
  'settings.paymentAndInvoices': 'Payments and Invoices',
  'settings.deleteAccount': 'Delete my account',
  'settings.logout': 'Logout',
};
