import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate} from 'react-router-dom';

import {internalError} from '../../lib/helpers/internalError';

import {loggedIn} from '../../redux/auth/authSlice';

import {StringParam, useQueryParam} from 'use-query-params';
import {useAppDispatch} from '../../app/hooks';
import {useIntl} from '../../lib/intl';
import {resendSignInCode, signinConfirmCode} from '../../redux/signin/thunks';
import ConfirmationCode from './ConfirmationCode';

const SigninConfirmationCode = ({onBack}: {onBack: () => void}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const [redirectUrl] = useQueryParam('redirect_url', StringParam);

  const handleSubmit = React.useCallback(
    async ({confirmationCode}: any) => {
      try {
        setLoading(true);
        await dispatch(signinConfirmCode(confirmationCode)).unwrap();
        dispatch(loggedIn());
        if (redirectUrl) {
          navigate(decodeURIComponent(redirectUrl));
        } else {
          navigate('/');
        }
      } catch (err) {
        if (err === 'auth/invalid-verification-code') {
          NotificationManager.error(
            intl.formatMessage({id: 'signinConfirmationCode.invalidCode'}),
          );
        } else if (err === 'auth/too-many-requests') {
          NotificationManager.error(
            intl.formatMessage({id: 'signinConfirmationCode.tooManyRequests'}),
          );
        } else if (err === 'signInRequired') {
          NotificationManager.warning(
            intl.formatMessage({id: 'signinConfirmationCode.smsSending'}),
          );
        } else {
          internalError(err);
        }
      } finally {
        setLoading(false);
      }
    },
    [dispatch],
  );

  return (
    <ConfirmationCode
      loading={loading}
      onSubmit={handleSubmit}
      onBack={onBack}
      onResend={() => {
        dispatch(resendSignInCode());
      }}
    />
  );
};

export default SigninConfirmationCode;
