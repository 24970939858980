export default {
  'updateBusinessInformationSectionBlock.message':
    'The content exceeds the maximum allowed size of 15 MB.',
  'updateBusinessInformationSectionBlock.successMessage':
    'The subsection has been successfully modified',
  'updateBusinessInformationSectionBlock.block': 'the subsection',
  'updateBusinessInformationSectionBlock.title': 'Title',
  'updateBusinessInformationSectionBlock.content': 'Content',
  'updateBusinessInformationSectionBlock.blockTitle': 'Update of {label}',
};
