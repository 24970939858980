import {useField} from 'formik';
import React from 'react';
import FirebaseStorageImage from '../../../lib/firebase-image/components/FirebaseStorageImage';
import AspectRatioImage from '../AspectRatioImage';
import AspectRatioPicker from '../AspectRatioPicker';
import FormikFileInput from './FormikFileInput';

const FormikUpdateFileInputAspectRatio = ({
  name,
  aspectRatioName,
  defaultValue,
  defaultAspectRatio,
  maxSize,
}: {
  name: string;
  aspectRatioName: string;
  defaultValue?: string;
  defaultAspectRatio?: string;
  maxSize?: number;
}) => {
  const [field, , helpers] = useField(name);
  const [aspectRatioField, , aspectRatioHelpers] = useField(aspectRatioName);

  const ref = React.useRef<string | undefined>(defaultValue);
  const aspectRatioRef = React.useRef<string | undefined>(defaultAspectRatio);

  return (
    <>
      {ref.current && !field.value && (
        <FirebaseStorageImage
          sx={{
            margin: '0 auto',
          }}
          Component={AspectRatioImage}
          reference={ref.current as string}
          ratio={aspectRatioRef.current as AspectRatio}
        />
      )}
      <FormikFileInput
        name={name}
        maxSize={maxSize}
        crop
        aspectRatio={aspectRatioField.value}
      />
      <AspectRatioPicker
        value={aspectRatioField.value}
        onChange={e => {
          if (e.target.value !== aspectRatioField.value) {
            helpers.setValue('');
            aspectRatioHelpers.setValue(e.target.value as AspectRatio);
          }
        }}
      />
    </>
  );
};

export default FormikUpdateFileInputAspectRatio;
