import {IconButton, Stack, Typography} from '@mui/material';
import {FACEBOOK_LOGO, INSTAGRAM_LOGO} from '../../assets/images';

const SocialMedia = () => (
  <Stack direction="column" spacing={1}>
    <Typography align="center">Suivez nous sur les réseaux sociaux:</Typography>
    <Stack direction="row" alignItems="center" justifyContent="center">
      <a href="https://www.instagram.com/fluxencepro/" target="_blank">
        <IconButton>
          <img
            src={INSTAGRAM_LOGO}
            style={{
              width: '35px',
              height: '35px',
            }}
          />
        </IconButton>
      </a>
      <a
        href="https://web.facebook.com/profile.php?id=100089810847410"
        target="_blank">
        <IconButton>
          <img
            src={FACEBOOK_LOGO}
            style={{
              width: '35px',
              height: '35px',
            }}
          />
        </IconButton>
      </a>
    </Stack>
  </Stack>
);

export default SocialMedia;
