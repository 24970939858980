import BusinessPreviewComponent from '../../components/organisms/BusinessPreview';
import useCurrentBusinessId from '../../lib/hooks/use-current-business-id';

const BusinessPreview = () => {
  const businessId = useCurrentBusinessId();

  return <BusinessPreviewComponent businessId={businessId} />;
};

export default BusinessPreview;
