import React from 'react';

import {Button} from '@mui/material';

import padNumber from '../../lib/helpers/numbers';

import addSeconds from 'date-fns/addSeconds';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import isFuture from 'date-fns/isFuture';
import {useIntl} from '../../lib/intl';

const ResendCodeButton = ({onClick}: {onClick: () => void}) => {
  const intl = useIntl();
  const [lastSentAt, setLastSentAt] = React.useState<Date>();
  const [shouldDisplayCounter, setShouldDisplayCounter] = React.useState(false);
  const [counter, setCounter] = React.useState(0);
  const handlePress = () => {
    if (!shouldDisplayCounter) {
      setLastSentAt(new Date());
      onClick();
    }
  };

  const minutes = React.useMemo(() => {
    return Math.floor(counter / 60);
  }, [counter]);

  const seconds = React.useMemo(() => {
    return counter % 60;
  }, [counter]);

  React.useEffect(() => {
    if (lastSentAt) {
      const interval = setInterval(() => {
        const timeToEnable = addSeconds(lastSentAt, 20);
        const result = !!lastSentAt && isFuture(timeToEnable);

        const diff = differenceInSeconds(timeToEnable, Date.now());

        setShouldDisplayCounter(result);
        if (diff >= 0) {
          setCounter(diff);
        } else {
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [lastSentAt]);

  return (
    <Button
      autoCapitalize="none"
      disabled={shouldDisplayCounter}
      variant="text"
      size="medium"
      onClick={() => handlePress()}>
      {shouldDisplayCounter
        ? `${padNumber(minutes, 2)}:${padNumber(seconds, 2)}`
        : intl.formatMessage({id: 'resendCodeButton.didNotReceiveCode'})}
    </Button>
  );
};

export default ResendCodeButton;
