import {Box, BoxProps} from '@mui/material';

const getPaddingBottom = (ratio: AspectRatio) => {
  switch (ratio) {
    case '1:1':
      return '100%';
    case '16:9':
      return '56.25%';
    case '9:16':
      return '177.778%';
    case '1.91:1':
      return '52.36%';
    case '10:4':
      return '40%';
    case '1:1.41':
      return '141%';
    case '1.41:1':
      return '70.92%';
    default:
      return '100%';
  }
};

const AspectRatioImage = ({
  src,
  ratio,
  sx,
  style,
}: {
  src: string;
  ratio: AspectRatio;
  sx?: BoxProps['sx'];
  style?: any;
}) => {
  return (
    <Box
      style={style}
      sx={{
        maxWidth: '600px',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        resize: 'horizontal',
        ...(ratio === '9:16'
          ? {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }
          : {}),
        ...sx,
      }}>
      <Box
        sx={{
          backgroundImage: `url(${src})`,
          width: '100%',
          paddingBottom: getPaddingBottom(ratio),
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      />
    </Box>
  );
};

export default AspectRatioImage;
