import {useEffect} from 'react';
import {NotificationManager} from 'react-notifications';

import useCurrentBusinessId from '../../lib/hooks/use-current-business-id';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import Confirmation from '../../components/organisms/Confirmation';

import {useIntl} from '../../lib/intl';
import useAppLanguage from '../../lib/intl/hooks/use-app-language';
import {useDeleteBusinessInformationSectionBlockImageMutation} from '../../redux/businessInformationSectionsApi';

const DeleteBusinessInformationSectionBlockImage = ({
  open,
  sectionBlock,
  onClose,
}: {
  open: boolean;
  sectionBlock: BusinessInformationSectionBlock;
  onClose: () => void;
}) => {
  const lang = useAppLanguage();

  const intl = useIntl();
  const businessId = useCurrentBusinessId();
  const [remove, mutation] =
    useDeleteBusinessInformationSectionBlockImageMutation();

  useQueryInternalError(mutation);

  useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success(
        intl.formatMessage({
          id: 'deleteBusinessInformationSectionBlockImage.messageSuccess',
        }),
      );
      onClose();
    }
  }, [mutation.isSuccess]);

  return (
    <Confirmation
      open={open}
      isLoading={mutation.isLoading}
      onClose={onClose}
      description={intl.formatMessage({
        id: 'deleteBusinessInformationSectionBlockImage.description',
        values: {
          name: sectionBlock.headingIntl?.[lang]
            ? `"${sectionBlock.headingIntl[lang]}"`
            : intl.formatMessage({
                id: 'deleteBusinessInformationSectionBlockImage.block',
              }),
        },
      })}
      onConfirm={() =>
        remove({
          id: sectionBlock._id,
          business: businessId,
        })
      }
    />
  );
};

export default DeleteBusinessInformationSectionBlockImage;
