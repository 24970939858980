import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {apiBaseQuery} from './apiBaseQuery';

const paymentApi = createApi({
  reducerPath: 'paymentApi',
  baseQuery: apiBaseQuery,
  tagTypes: ['paymentApi'],
  endpoints(build) {
    return {
      getSubscriptionByUser: build.query({
        query: ({userId}) => ({
          url: `/b2b-subscriptions?$limit=1&user=${userId}&type=b2b&$select[]=endDate&$select[]=status&$select[]=info&$sort[endDate]=-1`,
          method: 'get',
        }),
        transformResponse: (response: any) => {
          if (response.data.length > 0) {
            return response.data[0];
          }

          return null;
        },
        providesTags: ['paymentApi'],
      }),
      createSubscription: build.mutation<any, any>({
        query: data => ({
          url: '/b2b-subscriptions',
          method: 'post',
          data,
        }),
        invalidatesTags: ['paymentApi'],
      }),
      getUserBillingPortal: build.query({
        query: ({user, locale = 'fr'}: {user: any; locale: string}) => ({
          url: `/payments/0?user=${user}&locale=${locale.toLocaleLowerCase()}&mode=billingPortal&app=b2b`,
          method: 'get',
          keepUnusedDataFor: 1000000,
        }),
        providesTags: ['paymentApi'],
      }),
    };
  },
});

export const {
  useGetSubscriptionByUserQuery,
  useCreateSubscriptionMutation,
  useGetUserBillingPortalQuery,
} = paymentApi;

export default paymentApi;
