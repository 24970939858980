import {Check} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import React, {useState} from 'react';
import {FormattedMessage} from '../../lib/intl';

const AccordionSummary = styled(MuiAccordionSummary)({
  padding: '0 10px',
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
});

const AccordionDetails = styled(MuiAccordionDetails)({
  padding: 0,
});

const CheckItem = ({children}: {children: React.ReactNode}) => {
  return (
    <Stack alignItems="flex-start" direction="row" spacing={2}>
      <Box
        sx={{
          top: 5,
        }}>
        <Check color="success" fontSize="small" />
      </Box>
      <Typography variant="body1">{children}</Typography>
    </Stack>
  );
};

const HelpSection = () => {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Accordion
        sx={{
          backgroundColor: 'inherit',
        }}>
        <AccordionSummary>
          <b>Comment ça marche ?</b>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            px: 1,
          }}>
          <Stack direction="column" spacing={2}>
            <CheckItem>
              <FormattedMessage id="helpSection.bullet1" />
            </CheckItem>
            <CheckItem>
              <FormattedMessage id="helpSection.bullet2" />
            </CheckItem>
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          backgroundColor: 'inherit',
        }}>
        <AccordionSummary>
          <b>
            <FormattedMessage id="helpSection.whyChooseUs" />
          </b>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="column" spacing={2}>
            <CheckItem>
              <FormattedMessage id="helpSection.bullet3" />
            </CheckItem>
            <CheckItem>
              <FormattedMessage id="helpSection.bullet4" />
            </CheckItem>
            <CheckItem>
              <FormattedMessage id="helpSection.bullet6" />
            </CheckItem>
            <CheckItem>
              <FormattedMessage id="helpSection.bullet5" />
            </CheckItem>
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          backgroundColor: 'inherit',
        }}>
        <AccordionSummary>
          <b>
            <FormattedMessage id="helpSection.forWho" />
          </b>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="column" spacing={2}>
            <CheckItem>
              <FormattedMessage id="helpSection.forWho1" />
            </CheckItem>
            <CheckItem>
              <FormattedMessage id="helpSection.forWho2" />
            </CheckItem>
            <CheckItem>
              <FormattedMessage id="helpSection.forWho3" />
            </CheckItem>
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Box ml={1} mt={1}>
        <Typography>
          <FormattedMessage id="helpSection.forMoreInfo" />{' '}
          <a
            style={{
              textDecoration: 'none',
            }}
            href="https://www.fluxence.com"
            target="_blank">
            www.fluxence.com
          </a>
        </Typography>
      </Box>
    </Box>
  );
};

export default HelpSection;
