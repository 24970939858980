export default {
  'updateBusinessInformationSectionBlock.message':
    'Le contenu dépasse la taille maximale autorisée de 15 Mo.',
  'updateBusinessInformationSectionBlock.successMessage':
    'La sous-rubrique a été bien modifié',
  'updateBusinessInformationSectionBlock.block': 'la sous-rubrique',
  'updateBusinessInformationSectionBlock.title': 'Titre',
  'updateBusinessInformationSectionBlock.content': 'Contenu',
  'updateBusinessInformationSectionBlock.blockTitle': 'Modification de {label}',
};
