const wait = (ms: number) =>
  new Promise(resolve => setTimeout(() => resolve(null), ms));

const tryAsync = (
  callback: (...args: any) => Promise<unknown>,
  times: number = 3,
  interval = 7000,
) => {
  let tries = times;

  const tryToCall: any = async () => {
    try {
      return await callback();
    } catch (err) {
      if (tries > 0) {
        tries--;
        await wait(interval);
        return await tryToCall();
      }
      throw err;
    }
  };

  return tryToCall();
};

export default tryAsync;
