import {Box, Button, Stack, Typography, useTheme} from '@mui/material';
import {LanguageCode, supportedLanguagesMap} from '../../lib/intl';

const LanguagesTabs = ({
  selectedLang,
  onLangChange,
}: {
  selectedLang: string;
  onLangChange: (lang: string) => void;
}) => {
  const theme = useTheme();

  return (
    <Stack direction="row" spacing={1}>
      {Object.keys(supportedLanguagesMap).map(lang => (
        <Button
          variant="text"
          key={lang}
          onClick={() => {
            onLangChange(lang);
          }}>
          <Box pr={1} py={1}>
            <Typography
              sx={{
                color:
                  lang === selectedLang ? theme.palette.primary.main : '#aaa',
              }}
              variant="body1">
              {supportedLanguagesMap[lang as LanguageCode]}
            </Typography>
          </Box>
        </Button>
      ))}
    </Stack>
  );
};

export default LanguagesTabs;
