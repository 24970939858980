import {Edit} from '@mui/icons-material';
import {IconButton, Stack, Typography} from '@mui/material';
import Link from '../../components/atoms/Link';
import {useIntl} from '../../lib/intl';

const SettingLine = ({
  title,
  value,
  helperText,
  editable = false,
  ...props
}: {
  title: string;
  value: string;
  helperText?: string;
  editable?: boolean;
  onEdit?: () => void;
  to?: string;
}) => {
  const intl = useIntl();

  return (
    <Stack spacing={1} position="relative">
      <Typography variant="h6" color="text.primary">
        {title}
      </Typography>
      <Typography variant="body1" color="grey">
        {value || intl.formatMessage({id: 'settingLine.empty'})}
      </Typography>
      {helperText && (
        <Typography variant="body2" color="dimgrey">
          {helperText}
        </Typography>
      )}
      {editable &&
        (props.to ? (
          <Link to={props.to}>
            <IconButton
              size="small"
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
              }}>
              <Edit fontSize="small" />
            </IconButton>
          </Link>
        ) : (
          <IconButton
            size="small"
            onClick={props.onEdit}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
            }}>
            <Edit fontSize="small" />
          </IconButton>
        ))}
    </Stack>
  );
};

export default SettingLine;
