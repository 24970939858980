import {useState} from 'react';

const useModalState = () => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const toggle = () => setIsOpen(!isOpen);

  return [isOpen, open, close, toggle] as [
    boolean,
    () => void,
    () => void,
    () => void,
  ];
};

export default useModalState;
