import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate} from 'react-router-dom';

import {FormControlLabel, Stack} from '@mui/material';

import FormikGeoLocationPicker from '../../lib/googlemaps/components/GeoLocationPicker/FormikGeoLocationPicker';
import {internalError} from '../../lib/helpers/internalError';
import useUploadFile from '../../lib/hooks/useUploadFile';

import {Formik} from 'formik';
import * as yup from 'yup';

import FormikFileInput from '../../components/atoms/formik/FormikFileInput';
import FormikTextField from '../../components/atoms/formik/FormikTextField/FormikTextField';
import FormikBusinessCategoryPicker from '../../components/molecules/formik/FormikBusinessCategoryPicker';
import PageAction from '../../components/organisms/PageAction';

import FormikSingleChoice from '../../components/atoms/formik/FormikSingleChoice';
import SaveButton from '../../components/atoms/SaveButton';
import PageActionLayout from '../../components/organisms/PageActionLayout';
import {FormattedMessage, useIntl} from '../../lib/intl';
import {setDefaultBusinessId} from '../../lib/storage/business-id';
import {useCreateBusinessMutation} from '../../redux/businessApi';

const validationSchema = yup.object({
  image: yup.string().required('validation.fieldRequired'),
  name: yup.string().required('validation.fieldRequired'),
  category: yup.string().required('validation.fieldRequired'),
  address: yup.string().required('validation.fieldRequired'),
  location: yup.object().locationRequired('validation.tryAnotherAddress'),
  website: yup.string().url('validation.inValidUrl'),
  enabled: yup.boolean(),
});

const CreateBusiness = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const uploadFile = useUploadFile();
  const [createBusiness, mutation] = useCreateBusinessMutation();
  const [loading, setLoading] = React.useState(false);

  const handleCreateBusiness = async ({
    image,
    name,
    descriptionIntl,
    address,
    location,
    category,
    website,
    enabled,
  }: any) => {
    try {
      setLoading(true);
      const coverImage = await uploadFile(image);
      const result = await createBusiness({
        image: coverImage._id,
        name,
        category,
        address,
        descriptionIntl,
        location,
        website,
        enabled,
      }).unwrap();
      NotificationManager.success(
        intl.formatMessage({id: 'createBusiness.successMessage'}),
      );
      setDefaultBusinessId(result._id);
      navigate('/');
    } catch (e: any) {
      internalError(mutation.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageActionLayout>
      <PageAction title={intl.formatMessage({id: 'createBusiness.title'})}>
        <Formik
          validationSchema={validationSchema}
          onSubmit={({
            image,
            name,
            descriptionIntl,
            address,
            location,
            category,
            website,
            enabled,
          }: any) =>
            handleCreateBusiness({
              image,
              name,
              descriptionIntl,
              address,
              location,
              category,
              website,
              enabled,
            })
          }
          initialValues={{
            image: '',
            name: '',
            descriptionIntl: {},
            category: '',
            address: '',
            location: '',
            website: '',
            enabled: true,
          }}>
          {({handleSubmit}) => (
            <Stack py={2} px={1} direction="column" spacing={3}>
              <Stack direction="column" spacing={2}>
                <FormikFileInput
                  crop
                  aspectRatio="1:1"
                  name="image"
                  maxSize={5}
                  label={intl.formatMessage({
                    id: 'completeInformation.uploadLogo',
                  })}
                />
                <FormikTextField
                  name="name"
                  label={intl.formatMessage({id: 'createBusiness.name'})}
                />
                <FormikTextField
                  name="description"
                  label={intl.formatMessage({id: 'createBusiness.description'})}
                  multiline
                  rows={4}
                  enableIntl
                  automaticTranslation
                />
                <FormikBusinessCategoryPicker
                  label={intl.formatMessage({id: 'createBusiness.category'})}
                  name="category"
                />
                <FormikGeoLocationPicker
                  locationName="location"
                  addressName="address"
                />
                <FormikTextField
                  name="website"
                  label={intl.formatMessage({id: 'createBusiness.website'})}
                  placeholder="https://www.fluxence.com"
                />
                <FormControlLabel
                  control={<FormikSingleChoice name="enabled" />}
                  label={intl.formatMessage({id: 'createBusiness.status'})}
                />
              </Stack>
              <SaveButton
                onClick={loading ? undefined : () => handleSubmit()}
                loading={loading}
                variant="contained"
                color="primary">
                <FormattedMessage id="common.finish" />
              </SaveButton>
            </Stack>
          )}
        </Formik>
      </PageAction>
    </PageActionLayout>
  );
};

export default CreateBusiness;
