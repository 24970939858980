import * as yup from 'yup';
import {IntlKeys} from '../intl';

yup.addMethod(
  yup.object,
  'intlRequiredIf',
  function (
    condition: (form: any) => boolean,
    message: string = 'validation.requiredField' as IntlKeys,
  ) {
    return this.test({
      name: 'intlRequiredIf',
      message: '${path} is required',
      test: function (intlValue: {[key: string]: string}) {
        const isConditionValid = condition(this.parent);

        if (!isConditionValid) {
          return true;
        }

        if (
          typeof intlValue === 'object' &&
          Object.keys(intlValue).length !== 0 &&
          Object.values(intlValue).some(value => !!value)
        ) {
          return true;
        } else {
          return this.createError({
            message,
            path: this.path,
          });
        }
      },
    });
  },
);

declare module 'yup' {
  // @ts-ignore
  interface ObjectSchema {
    intlRequiredIf(
      condition: (form: any) => boolean,
      message: IntlKeys,
    ): StringSchema;
  }
}
