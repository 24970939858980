export default {
  'reportIssue.title': 'Contáctenos',
  'reportIssue.subject': 'Asunto *',
  'reportIssue.message': 'Contenido del mensaje *',
  'reportIssue.security': 'Seguridad',
  'reportIssue.payment': 'Pago',
  'reportIssue.display': 'Pantalla',
  'reportIssue.profile': 'Perfil',
  'reportIssue.information': 'Información',
  'reportIssue.other': 'Otros',
  'reportIssue.bug': 'Error/Problema técnico',
  'reportIssue.subjectPlaceholder': 'Asunto *',
  'reportIssue.successMessage':
    '¡Gracias!. Su mensaje ha sido enviado correctamente. Le responderemos lo antes posible.',
};
