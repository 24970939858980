import {getAnalytics} from 'firebase/analytics';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import firebaseConfig from '../../firebase.json';

const app = firebase.initializeApp(firebaseConfig);

getAnalytics(app);

export default firebase;
