import {Stack} from '@mui/material';
import {Formik} from 'formik';
import {useEffect} from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import FormikTextField from '../../components/atoms/formik/FormikTextField/FormikTextField';
import SaveButton from '../../components/atoms/SaveButton';
import PageAction from '../../components/organisms/PageAction';
import useCurrentBusinessId from '../../lib/hooks/use-current-business-id';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';
import {useIntl} from '../../lib/intl';
import {useUpdateBusinessExternalSettingsMutation} from '../../redux/businessApi';
import useGetCurrentBusinessQuery from '../../redux/get-current-business';

const validationSchema = Yup.object({
  qrCodeQuantity: Yup.number()
    .required('validation.fieldRequired')
    .min(0, 'editGenerateCodeStats.qrCodeNotNegative'),
});

const EditGeneratedQRCodeStats = () => {
  const intl = useIntl();
  const businessId = useCurrentBusinessId();
  const navigate = useNavigate();

  const [updateExtraSettings, mutation] =
    useUpdateBusinessExternalSettingsMutation();
  const {data: business} = useGetCurrentBusinessQuery();

  useQueryInternalError(mutation);

  useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success(
        intl.formatMessage({id: 'editGenerateCodeStats.successMessage'}),
      );
      navigate(-1);
    }
  }, [mutation.isSuccess]);

  return (
    <PageAction title={intl.formatMessage({id: 'editGenerateCodeStats.title'})}>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{
          qrCodeQuantity: business?.qrCodeQuantity || 0,
        }}
        onSubmit={({qrCodeQuantity}) =>
          updateExtraSettings({
            business: businessId,
            extraSettings: {
              qrCodeQuantity,
            },
          })
        }>
        {({handleSubmit}) => (
          <Stack>
            <FormikTextField
              type="number"
              name="qrCodeQuantity"
              label={intl.formatMessage({
                id: 'editGenerateCodeStats.qrCodeQuantity',
              })}
              helperText={intl.formatMessage({
                id: 'editGenerateCodeStats.qrCodeQuantityHelperText',
              })}
            />
            <SaveButton
              onClick={mutation.isLoading ? undefined : () => handleSubmit()}
              loading={mutation.isLoading}
            />
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default EditGeneratedQRCodeStats;
