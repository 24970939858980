export default {
  'reportIssue.title': 'Contact us',
  'reportIssue.subject': 'Subject *',
  'reportIssue.message': 'Message content *',
  'reportIssue.security': 'Security',
  'reportIssue.payment': 'Payment',
  'reportIssue.display': 'Display',
  'reportIssue.profile': 'Profile',
  'reportIssue.information': 'Information',
  'reportIssue.other': 'Others',
  'reportIssue.bug': 'Bug/Technical problem',
  'reportIssue.subjectPlaceholder': 'Subject *',
  'reportIssue.successMessage':
    'Thank you!. Your message has been sent successfully. We will respond to you as soon as possible.',
};
