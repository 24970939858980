import {LoadingButton} from '@mui/lab';
import {Alert, Stack, Typography} from '@mui/material';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, {useEffect} from 'react';

import {useAlertContext} from '../../lib/alert';
import {FormattedMessage} from '../../lib/intl';
import stripePromise from '../../lib/stripe/stripePromise';

const CheckoutForm = ({clientSecret}: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [paymentIntent, setPaymentIntent] = React.useState<any>();
  const {showAlert} = useAlertContext();

  useEffect(() => {
    const getPaymentIntent = async () => {
      if (stripe) {
        const {paymentIntent: pi} = await stripe.retrievePaymentIntent(
          clientSecret,
        );
        setPaymentIntent(pi);
      }
    };
    getPaymentIntent();
  }, [clientSecret, stripe]);

  const confirmPayment = React.useCallback(async () => {
    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);
    const {error, paymentIntent} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.origin,
      },
      redirect: 'if_required',
    });

    /* if (
      paymentIntent &&
      paymentIntent.client_secret &&
      paymentIntent.payment_method &&
      paymentIntent.status === "requires_action"
    ) {
      const { error }  = await stripe.confirmCardPayment(paymentIntent.client_secret, {
        payment_method: paymentIntent.payment_method as string,
      });
    } */

    if (paymentIntent && paymentIntent.status === 'succeeded') {
      window.location.href = window.location.origin;
    }

    if (error) {
      showAlert({
        message: <Typography variant="body1">{error.message}</Typography>,
        title: 'Erreur de paiement',
      });
    }

    setIsProcessing(false);
  }, [elements, stripe, showAlert]);

  const confirmCardPayment = React.useCallback(async () => {
    if (!stripe) {
      return;
    }

    setIsProcessing(true);

    const {error, paymentIntent: pm} = await stripe.confirmCardPayment(
      paymentIntent.client_secret,
      {
        payment_method: paymentIntent.payment_method as string,
      },
    );

    if (pm && pm.status === 'succeeded') {
      window.location.href = window.location.origin;
    }

    if (error) {
      showAlert({
        message: <Typography variant="body1">{error.message}</Typography>,
        title: 'Erreur de paiement',
      });
    }

    setIsProcessing(false);
  }, [stripe, paymentIntent, showAlert]);

  if (paymentIntent?.status === 'requires_action') {
    return (
      <Stack spacing={4} p={6}>
        <Alert severity="warning">
          Étape supplémentaire ! Votre banque exige une vérification 3D Secure
          pour cet paiement. <br />
          Cliquez sur "Continuer" et suivez les instructions de votre banque
          pour finaliser votre paiement.
        </Alert>
        <LoadingButton
          onClick={confirmCardPayment}
          loading={isProcessing}
          disabled={isProcessing}
          variant="contained"
          color="primary"
          fullWidth>
          <FormattedMessage id="common.continue" />
        </LoadingButton>
      </Stack>
    );
  }

  return (
    <Stack spacing={4} p={6}>
      <Alert severity="warning">
        Vos informations de paiement ne sont pas à jour. Veuillez les mettre à
        jour.
      </Alert>
      <PaymentElement />
      <LoadingButton
        onClick={confirmPayment}
        loading={isProcessing}
        disabled={!paymentIntent || isProcessing}
        variant="contained"
        color="primary"
        fullWidth>
        Payer
      </LoadingButton>
    </Stack>
  );
};

export const StripeWrapper = ({children, clientSecret}: any) => {
  return (
    stripePromise &&
    clientSecret && (
      <Elements stripe={stripePromise} options={{locale: 'fr', clientSecret}}>
        {children}
      </Elements>
    )
  );
};
export default CheckoutForm;
