import OTPInput from 'react-otp-input';

import {
  Box,
  FormGroup,
  FormLabel,
  Input,
  Stack,
  Typography,
} from '@mui/material';

import {useField, useFormikContext} from 'formik';

const MAX_LENGTH = 6;

const FormikOTPInput = ({
  name,
  disabled,
  label,
}: {
  name: string;
  disabled: boolean;
  label: string;
}) => {
  const [field, meta, helpers] = useField(name);
  const form = useFormikContext();

  return (
    <FormGroup>
      <FormLabel>{label}</FormLabel>
      <Stack
        direction="row"
        sx={{py: 2, position: 'relative', justifyContent: 'center'}}>
        <OTPInput
          inputStyle={{
            textAlign: 'center',
            width: '100%',
          }}
          value={field.value}
          onChange={val => {
            helpers.setValue(val);

            if (val.length === MAX_LENGTH) {
              form.handleSubmit();
            }
          }}
          onPaste={val => helpers.setValue(val)}
          numInputs={MAX_LENGTH}
          renderSeparator={() => (
            <Box
              sx={{
                p: 1,
              }}>
              -
            </Box>
          )}
          renderInput={props => (
            <Input
              disabled={disabled}
              inputProps={props}
              sx={{
                border: '1px solid #aaa',
                width: '40px!important',
                height: 40,
                backgroundColor: '#fff!important',
              }}
            />
          )}
        />
        {meta.touched && meta.error && <Typography>{meta.error}</Typography>}
      </Stack>
    </FormGroup>
  );
};

export default FormikOTPInput;
