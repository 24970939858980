import {Fragment, useEffect, useMemo} from 'react';

import {Box, IconButton, Stack, Typography} from '@mui/material';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import LoadingOverlay from '../../components/molecules/LoadingOverlay';

import {useGetBusinessInformationSectionsQuery} from '../../redux/businessInformationSectionsApi';

import {AddCircle} from '@mui/icons-material';
import Link from '../../components/atoms/Link';
import {FormattedMessage} from '../../lib/intl';
import BusinessInformationSectionChip from './BusinessInformationSectionChip';

const BusinessInformationSectionsList = ({
  selectedSection,
  onSelectSection,
  previewMode,
  businessId,
}: {
  selectedSection?: string | null;
  businessId: string;
  onSelectSection: (block: string) => void;
  previewMode?: boolean;
}) => {
  const query = useGetBusinessInformationSectionsQuery(businessId);

  const maxPosition = useMemo(() => {
    if (!query.data) {
      return 0;
    }

    return Math.max(...query.data.map(s => s.position));
  }, [query.data]);

  const minPosition = useMemo(() => {
    if (!query.data) {
      return 0;
    }

    return Math.min(...query.data.map(s => s.position));
  }, [query.data]);

  useEffect(() => {
    if (
      query.isSuccess &&
      query.data.length > 0 &&
      (!selectedSection || !query.data.find(s => s._id === selectedSection))
    ) {
      setTimeout(() => {
        onSelectSection(query.data[0]._id);
      }, 100);
    }
  }, [previewMode, query, onSelectSection, selectedSection]);

  useQueryInternalError(query);

  if (query.isLoading || query.isFetching) {
    return (
      <Box py={4}>
        <LoadingOverlay />
      </Box>
    );
  }

  if (query.data?.length === 0) {
    return (
      <Stack alignItems="center" spacing={1} direction="row" px={2} py={2}>
        {!previewMode && (
          <Link to="/actions/business-information-section/add">
            <IconButton color="primary" size="small">
              <AddCircle />
            </IconButton>
          </Link>
        )}
        {!previewMode ? (
          <Typography sx={{flex: 1}} align="center" variant="body1">
            <FormattedMessage id="businessInformationSectionsList.addSection" />
          </Typography>
        ) : (
          <Typography sx={{flex: 1}} align="center" variant="body1">
            <FormattedMessage id="businessInformationSectionsList.noSections" />
          </Typography>
        )}
      </Stack>
    );
  }

  return (
    <Stack
      direction="row"
      pt={1}
      pb={2}
      px={1}
      mb={1}
      alignItems="center"
      spacing={1}
      sx={{
        position: 'sticky',
        top: 0,
        overflowX: 'scroll',
        borderRadius: '2px',
        backgroundColor: theme => theme.palette.background.paper,
        zIndex: 1,
      }}>
      {!previewMode && (
        <Link to="/actions/business-information-section/add">
          <IconButton color="primary" size="medium">
            <AddCircle fontSize="medium" />
          </IconButton>
        </Link>
      )}
      {query.data?.map((section, index) => (
        <Fragment key={section._id}>
          {!previewMode ? (
            <BusinessInformationSectionChip
              isFirst={section.position === minPosition}
              isLast={section.position === maxPosition}
              section={section}
              onSelect={() => onSelectSection(section._id)}
              selected={section._id === selectedSection}
            />
          ) : (
            <BusinessInformationSectionChip
              section={section}
              onSelect={() => onSelectSection(section._id)}
              selected={section._id === selectedSection}
              previewMode={previewMode}
            />
          )}
        </Fragment>
      ))}
    </Stack>
  );
};

export default BusinessInformationSectionsList;
