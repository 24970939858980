import {Add, AddCircle, ExitToApp, Visibility} from '@mui/icons-material';
import {IconButton, Stack, Typography} from '@mui/material';

import {StringParam, useQueryParam} from 'use-query-params';

import EmptyList from '../molecules/EmptyList';
import DataList from './DataList';

import {useGetBusinessInformationSectionBlocksQuery} from '../../redux/businessInformationSectionsApi';

import {theme} from '../../appTheme';
import {FormattedMessage} from '../../lib/intl';
import BusinessInformationSectionBlockCard from '../../pages/BusinessSite/BusinessInformationSectionBlockCard';
import BusinessInformationSectionsList from '../../pages/BusinessSite/BusinessInformationSectionsList';
import BusinessSiteActions from '../../pages/BusinessSite/BusinessSiteActions';
import BusinessSitePageName from '../../pages/BusinessSite/BusinessSitePageName';
import Link from '../atoms/Link';
import RoundTextIconButton from '../atoms/RoundButton';

const BusinessSite = ({
  previewMode,
  businessId,
  quitPreviewVisible = true,
}: {
  previewMode?: boolean;
  quitPreviewVisible?: boolean;
  businessId: string;
}) => {
  const [section, setSelection] = useQueryParam('section', StringParam, {
    updateType: 'replace',
  });

  return (
    <Stack
      sx={{
        position: 'relative',
      }}
      direction="column"
      spacing={1}>
      {!previewMode && <BusinessSiteActions />}
      <BusinessSitePageName previewMode={previewMode} />
      <BusinessInformationSectionsList
        selectedSection={section}
        onSelectSection={setSelection}
        previewMode={previewMode}
        businessId={businessId}
      />
      {section && (
        <DataList
          renderItem={(
            item: PopulatedBusinessInformationSectionBlock,
            {total}: {total: number},
          ) => (
            <BusinessInformationSectionBlockCard
              block={item}
              total={total}
              previewMode={previewMode}
            />
          )}
          useQuery={useGetBusinessInformationSectionBlocksQuery}
          emptyList={
            <EmptyList
              message={
                <Typography>
                  <FormattedMessage id="businessSite.help1" />
                  <IconButton color="primary">
                    <AddCircle />
                  </IconButton>{' '}
                  <FormattedMessage id="businessSite.help2" />
                </Typography>
              }
            />
          }
          extraQueryParams={{
            section,
            business: businessId,
          }}
        />
      )}
      {!previewMode && section && (
        <Link
          sx={{position: 'fixed', bottom: 70, right: theme.spacing(1)}}
          to={`/actions/business-information-section-block/add/${section}`}>
          <RoundTextIconButton
            icon={<Add />}
            text={<FormattedMessage id="businessSite.addSubsection" />}
            color="primary"
          />
        </Link>
      )}
      {!previewMode && (
        <Link
          sx={{position: 'fixed', bottom: 70, left: theme.spacing(1)}}
          to={`/preview`}>
          <RoundTextIconButton
            text={<FormattedMessage id="businessSite.preview" />}
            icon={<Visibility />}
            color="primary"
          />
        </Link>
      )}
      {quitPreviewVisible && previewMode && (
        <Link
          sx={{
            position: 'fixed',
            bottom: theme.spacing(1),
            left: theme.spacing(1),
          }}
          to={`/`}>
          <RoundTextIconButton
            text={<FormattedMessage id="businessSite.exit" />}
            icon={<ExitToApp />}
            color="primary"
          />
        </Link>
      )}
    </Stack>
  );
};

export default BusinessSite;
