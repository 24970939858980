import React from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';

import {Box, useTheme} from '@mui/material';

import AuthRoute from './lib/app/AuthRoute';
import BusinessRoute from './lib/app/BusinessRoute';
import PubRoute from './lib/app/PubRoute';

import UserPageOutlet from './lib/app-outlets/UserPageOutlet';

import {loggedIn, loggedOut} from './redux/auth/authSlice';
import {listenForAuthUser} from './redux/auth/getAuthUser';

import EditProfile from './pages/EditProfile/EditProfile';
import Logout from './pages/Logout/Logout';
import Settings from './pages/Settings/Settings';
import AuthPhoneNumber from './pages/Signin/AuthPhoneNumber';
import EditBusinessImage from './pages/UpdateBusinessImage/EditBusinessImage';
import EditBusinessInformation from './pages/UpdateBusinessInformation/EditBusinessInformation';
import UserBusinesses from './pages/UserBusinesses/UserBusinesses';

import CreateBusinessInformationSection from './modals/CreateBusinessInformationSection/CreateBusinessInformationSection';
import CreateBusinessInformationSectionBlock from './modals/CreateBusinessInformationSectionBlock/CreateBusinessInformationSectionBlock';
import UpdateBusinessInformationSection from './modals/UpdateBusinessInformationSection/UpdateBusinessInformationSection';
import UpdateBusinessInformationSectionBlock from './modals/UpdateBusinessInformationSectionBlock/UpdateBusinessInformationSectionBlock';
import UpdateBusinessInformationSectionBlockImage from './modals/UpdateBusinessInformationSectionBlockImage/UpdateBusinessInformationSectionBlockImage';

import {useAppSelector} from './app/hooks';
import store from './app/store';
import ActionOutlet from './lib/app-outlets/ActionOutlet';
import PaymentRoute from './lib/app/PaymentRoute';
import BusinessPreferences from './pages/BusinessPreferences/BusinessPreferences';
import BusinessPreview from './pages/BusinessPreview/BusinessPreview';
import BusinessSite from './pages/BusinessSite/BusinessSite';
import BusinessSitePreview from './pages/BusinessSitePreview/BusinessSitePreview';
import CreateBusiness from './pages/CreateBusiness/CreateBusiness';
import EditGeneratedQRCodeStats from './pages/EditGeneratedQRCodeStats/EditGeneratedQRCodeStats';
import ExtraSettings from './pages/ExtraSettings/ExtraSettings';
import Checkout from './pages/Payment/Checkout';
import Payments from './pages/Payment/Payments';
import FormIssue from './pages/ReportIssue/FormIssue';

listenForAuthUser(user => {
  if (user) {
    store.dispatch(loggedIn());
  } else {
    store.dispatch(loggedOut());
  }
});

const AppRoutes = () => {
  const theme = useTheme();
  const authenticated = useAppSelector(state => state.auth.authenticated);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (authenticated !== undefined) {
      if (authenticated) {
        if (location.pathname === '/signin') {
          navigate('/');
        }
      } else {
        let redirectionURLPart = '';
        if (location.pathname !== '/signin' && location.pathname !== '/') {
          redirectionURLPart = `?redirect_url=${encodeURIComponent(
            location.pathname,
          )}`;
        }

        navigate(`/signin${redirectionURLPart}`);
      }
    }
  }, [authenticated, location.pathname, navigate]);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        position: 'relative',
      }}>
      <Routes>
        <Route path="signin" element={<PubRoute />}>
          <Route path="" element={<AuthPhoneNumber />} />
        </Route>
        <Route path="" element={<AuthRoute />}>
          <Route path="checkouts/:token" element={<Checkout />} />
          <Route path="" element={<PaymentRoute />}>
            <Route path="my-businesses" element={<UserBusinesses />} />
            <Route
              path="actions/business/create"
              element={<CreateBusiness />}
            />
            <Route path="logout" element={<Logout />} />
            <Route path="" element={<BusinessRoute />}>
              <Route path="actions" element={<ActionOutlet />}>
                <Route
                  path="generated-qr-code-stats/edit"
                  element={<EditGeneratedQRCodeStats />}
                />
                <Route path="profile/edit" element={<EditProfile />} />
                <Route
                  path="preferences/edit"
                  element={<BusinessPreferences />}
                />
                <Route
                  path="business/edit"
                  element={<EditBusinessInformation />}
                />
                <Route
                  path="business/image/edit"
                  element={<EditBusinessImage />}
                />
                <Route
                  path="business-information-section/add"
                  element={<CreateBusinessInformationSection />}
                />
                <Route
                  path="business-information-section-block/add/:sectionId"
                  element={<CreateBusinessInformationSectionBlock />}
                />
                <Route
                  path="business-information-section-block/add"
                  element={<CreateBusinessInformationSectionBlock />}
                />
                <Route
                  path="business-information-section/update/:id"
                  element={<UpdateBusinessInformationSection />}
                />
                <Route
                  path="business-information-section-block/update/:id"
                  element={<UpdateBusinessInformationSectionBlock />}
                />
                <Route
                  path="business-information-section-block-image/update/:id"
                  element={<UpdateBusinessInformationSectionBlockImage />}
                />
                <Route path="report-issues" element={<FormIssue />} />
              </Route>
              <Route path="" element={<UserPageOutlet />}>
                <Route path="settings" element={<Settings />} />
                <Route path="extra-settings" element={<ExtraSettings />} />
                <Route path="" element={<BusinessSite />} />
                <Route index element={<BusinessSite />} />
              </Route>
              <Route path="preview" element={<BusinessPreview />} />
              <Route
                path="site-preview/:id"
                element={<BusinessSitePreview />}
              />
            </Route>
            <Route path="settings/payments" element={<Payments />} />
          </Route>
        </Route>
      </Routes>
    </Box>
  );
};

export default AppRoutes;
