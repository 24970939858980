import {
  ArrowDownward,
  ArrowUpward,
  Delete,
  Edit,
  Image,
} from '@mui/icons-material';
import {
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import Link from '../../components/atoms/Link';
import Dialogable from '../../components/molecules/Dialogable';

import {FormattedMessage} from '../../lib/intl';
import useAppLanguage from '../../lib/intl/hooks/use-app-language';
import DeleteBusinessInformationSectionBlock from '../DeleteBusinessInformationSectionBlock/DeleteBusinessInformationSectionBlock';
import DeleteBusinessInformationSectionBlockImage from '../DeleteBusinessInformationSectionBlockImage/DeleteBusinessInformationSectionBlockImage';
import MoveBusinessInformationSectionBlock from '../MoveBusinessInformationSectionBlock/MoveBusinessInformationSectionBlock';

const BusinessInformationSectionBlockActions = ({
  block,
  open,
  onClose,
  moveUpVisible,
  moveDownVisible,
}: {
  block: BusinessInformationSectionBlock;
  open: boolean;
  moveUpVisible: boolean;
  moveDownVisible: boolean;
  onClose: () => void;
}) => {
  const lang = useAppLanguage();

  return (
    <Dialog open={open} onClose={onClose}>
      {block.headingIntl?.[lang] && (
        <DialogTitle
          sx={{
            paddingLeft: 6,
          }}>
          <Typography variant="h6">{block.headingIntl[lang]}</Typography>
        </DialogTitle>
      )}
      <DialogContent>
        <MenuList>
          <Link
            to={`/actions/business-information-section-block/update/${block._id}`}>
            <MenuItem>
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              <ListItemText>
                <FormattedMessage id="businessInformationSectionBlockActions.update" />
              </ListItemText>
            </MenuItem>
          </Link>
          <Link
            to={`/actions/business-information-section-block-image/update/${block._id}`}>
            <MenuItem>
              <ListItemIcon>
                <Image />
              </ListItemIcon>
              <ListItemText>
                {block.image ? (
                  <FormattedMessage id="businessInformationSectionBlockActions.updateImage" />
                ) : (
                  <FormattedMessage id="businessInformationSectionBlockActions.addImage" />
                )}
              </ListItemText>
            </MenuItem>
          </Link>
          {block.image && (
            <Dialogable
              source={({onOpen}) => (
                <MenuItem onClick={onOpen}>
                  <ListItemIcon>
                    <Delete />
                  </ListItemIcon>
                  <ListItemText>
                    <FormattedMessage id="businessInformationSectionBlockActions.deleteImage" />
                  </ListItemText>
                </MenuItem>
              )}
              dialog={({onClose: dialogOnClose, open}) => (
                <DeleteBusinessInformationSectionBlockImage
                  sectionBlock={block}
                  open={open}
                  onClose={() => {
                    onClose();
                    dialogOnClose();
                  }}
                />
              )}
            />
          )}
          {moveUpVisible && (
            <Dialogable
              source={({onOpen}) => (
                <MenuItem onClick={onOpen}>
                  <ListItemIcon>
                    <ArrowUpward />
                  </ListItemIcon>
                  <ListItemText>
                    <FormattedMessage id="businessInformationSectionBlockActions.moveUp" />
                  </ListItemText>
                </MenuItem>
              )}
              dialog={({onClose: dialogOnClose, open}) => (
                <MoveBusinessInformationSectionBlock
                  direction="up"
                  sectionBlock={block}
                  open={open}
                  onClose={() => {
                    onClose();
                    dialogOnClose();
                  }}
                />
              )}
            />
          )}
          {moveDownVisible && (
            <Dialogable
              source={({onOpen}) => (
                <MenuItem onClick={onOpen}>
                  <ListItemIcon>
                    <ArrowDownward />
                  </ListItemIcon>
                  <ListItemText>
                    <FormattedMessage id="businessInformationSectionBlockActions.moveDown" />
                  </ListItemText>
                </MenuItem>
              )}
              dialog={({onClose: dialogOnClose, open}) => (
                <MoveBusinessInformationSectionBlock
                  direction="down"
                  sectionBlock={block}
                  open={open}
                  onClose={() => {
                    dialogOnClose();
                    onClose();
                  }}
                />
              )}
            />
          )}
          <Dialogable
            source={({onOpen}) => (
              <MenuItem onClick={onOpen}>
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                <ListItemText>
                  <FormattedMessage id="businessInformationSectionBlockActions.delete" />
                </ListItemText>
              </MenuItem>
            )}
            dialog={({onClose: dialogOnClose, open}) => (
              <DeleteBusinessInformationSectionBlock
                sectionBlock={block}
                open={open}
                onClose={() => {
                  onClose();
                  dialogOnClose();
                }}
              />
            )}
          />
        </MenuList>
      </DialogContent>
    </Dialog>
  );
};

export default BusinessInformationSectionBlockActions;
