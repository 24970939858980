import {useEffect} from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate, useParams} from 'react-router-dom';

import {Stack} from '@mui/material';

import useCurrentBusinessId from '../../lib/hooks/use-current-business-id';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import {Formik} from 'formik';
import * as Yup from 'yup';

import FormikTextField from '../../components/atoms/formik/FormikTextField/FormikTextField';
import LoadingOverlay from '../../components/molecules/LoadingOverlay';
import PageAction from '../../components/organisms/PageAction';

import SaveButton from '../../components/atoms/SaveButton';
import FormikRichText from '../../components/molecules/formik/FormikRichText';
import isIntlFieldEmpty from '../../lib/helpers/is-intl-field-empty';
import {FormattedMessage, useIntl} from '../../lib/intl';
import useAppLanguage from '../../lib/intl/hooks/use-app-language';
import {
  useGetBusinessInformationSectionBlockByIdQuery,
  useUpdateBusinessInformationSectionBlockMutation,
} from '../../redux/businessInformationSectionsApi';

const validationSchema = Yup.object({
  contentIntl: Yup.object()
    .intlRequiredIf(
      form => !isIntlFieldEmpty(form.headingIntl),
      'validation.requiredField',
    )
    .test(
      'textSize',
      'updateBusinessInformationSectionBlock.message',
      (intlValue: any) => {
        const valid = Object.keys(intlValue).every(lang => {
          const value = intlValue[lang];

          if (value === undefined) {
            return true;
          }

          const byteSize = new Blob([value]).size;

          return value && byteSize <= 1024 * 1024 * 10;
        });

        return valid;
      },
    ),
});

const UpdateBusinessInformationSectionBlock = () => {
  const intl = useIntl();
  const {id} = useParams();
  const business = useCurrentBusinessId();
  const navigate = useNavigate();
  const lang = useAppLanguage();

  const {data: block, ...query} =
    useGetBusinessInformationSectionBlockByIdQuery({
      business,
      id: id as string,
    });

  const [update, mutation] = useUpdateBusinessInformationSectionBlockMutation();

  useQueryInternalError(mutation);
  useQueryInternalError(query);

  useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success(
        intl.formatMessage({
          id: 'updateBusinessInformationSectionBlock.successMessage',
        }),
      );
      navigate(-1);
    }
  }, [mutation.isSuccess, mutation.data]);

  if (query.isLoading) {
    return <LoadingOverlay />;
  }

  const label = block?.headingIntl?.[lang]
    ? `"${block.headingIntl[lang]}"`
    : intl.formatMessage({id: 'updateBusinessInformationSectionBlock.block'});

  return (
    <PageAction
      title={intl.formatMessage({
        id: 'updateBusinessInformationSectionBlock.blockTitle',
        values: {label},
      })}>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{
          headingIntl: block?.headingIntl ?? {},
          contentIntl: block?.contentIntl ?? {},
          section: (block?.section as string) ?? '',
        }}
        onSubmit={({headingIntl, contentIntl, section}) =>
          update({
            id: id as string,
            headingIntl,
            contentIntl,
            business,
            section,
          })
        }>
        {({handleSubmit, errors}) => (
          <Stack spacing={2} direction="column">
            <FormikTextField
              name="heading"
              label={intl.formatMessage({
                id: 'updateBusinessInformationSectionBlock.title',
              })}
              enableIntl
              automaticTranslation
            />
            <FormikRichText
              name="content"
              label={intl.formatMessage({
                id: 'updateBusinessInformationSectionBlock.content',
              })}
              required
              enableIntl
              automaticTranslation
            />
            <SaveButton
              sx={{
                position: 'fixed',
                bottom: theme => theme.spacing(0.5),
                left: theme => theme.spacing(0.5),
                right: theme => theme.spacing(0.5),
                margin: 'auto',
                zIndex: 9999,
              }}
              loading={mutation.isLoading}
              onClick={mutation.isLoading ? undefined : () => handleSubmit()}>
              <FormattedMessage id="common.save" />
            </SaveButton>
          </Stack>
        )}
      </Formik>
      {query.isLoading && <LoadingOverlay />}
    </PageAction>
  );
};

export default UpdateBusinessInformationSectionBlock;
