import {LoadingButton, TabContext, TabList, TabPanel} from '@mui/lab';
import {
  Box,
  Button,
  DialogActions,
  DialogTitle,
  Stack,
  Tab,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React, {useEffect, useState} from 'react';
import {NotificationManager} from 'react-notifications';
import useCurrentBusinessId from '../../lib/hooks/use-current-business-id';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';
import {FormattedMessage, useIntl} from '../../lib/intl';
import {
  useGetSiteTemplatesBusinessesQuery,
  useGetUserOwnedBusinessesQuery,
} from '../../redux/businessApi';
import {useImportBusinessSiteMutation} from '../../redux/businessInformationSectionsApi';
import BusinessOption from '../atoms/BusinessOption';
import LoadingOverlay from '../molecules/LoadingOverlay';
import SiteTemplateOption from '../molecules/SiteTemplateOption';

const BusinessesList = ({
  query,
  siteTemplateNameVisible = false,
  businessId,
  fromBusiness,
  setFromBusiness,
  emptyText,
}: {
  businessId: string;
  siteTemplateNameVisible?: boolean;
  fromBusiness?: string;
  setFromBusiness: (businessId?: string) => void;
  query: {
    data?: PopulatedBusiness[];
    isSuccess: boolean;
    isLoading: boolean;
  };
  emptyText?: string | React.ReactNode;
}) => {
  const businesses = query.data?.filter(
    business => business._id !== businessId,
  );

  const handleSelect = (businessId: string) => {
    if (fromBusiness === businessId) {
      setFromBusiness(undefined);
    } else {
      setFromBusiness(businessId);
    }
  };

  return (
    <>
      {query.isLoading && <LoadingOverlay />}
      {query.isSuccess && (
        <Stack spacing={2} minWidth={250}>
          <Stack direction="column" spacing={1}>
            {businesses
              ?.filter(business => business._id !== businessId)
              .map(business =>
                siteTemplateNameVisible ? (
                  <SiteTemplateOption
                    key={business._id}
                    selected={fromBusiness === business._id}
                    business={business}
                    onClick={() => handleSelect(business._id)}
                  />
                ) : (
                  <BusinessOption
                    key={business._id}
                    business={business}
                    selected={fromBusiness === business._id}
                    onClick={() => handleSelect(business._id)}
                    hideStatus={true}
                  />
                ),
              )}
          </Stack>
        </Stack>
      )}
      {businesses?.length === 0 && (
        <Box height="100px">
          <Typography variant="body1">{emptyText}</Typography>
        </Box>
      )}
    </>
  );
};

const BusinessSiteImportModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const intl = useIntl();
  const businessId = useCurrentBusinessId();
  const [value, setValue] = useState('TMP');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const [fromBusiness, setFromBusiness] = useState<string | undefined>();
  const ownedBusinessQuery = useGetUserOwnedBusinessesQuery();
  const siteTemplatesQuery = useGetSiteTemplatesBusinessesQuery(businessId);
  const [importBusinessSite, mutation] = useImportBusinessSiteMutation();

  useQueryInternalError(ownedBusinessQuery);
  useQueryInternalError(siteTemplatesQuery);

  useQueryInternalError(mutation);

  useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success(
        intl.formatMessage({
          id: 'businessSite.importedSuccessfully',
        }),
      );
      onClose();
    }
  }, [mutation.isSuccess]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage id="businessSite.importSiteFrom" />
      </DialogTitle>
      <DialogContent
        sx={{
          '&.MuiDialogContent-root': {
            px: 0,
          },
        }}>
        <Box>
          <TabContext value={value}>
            <TabList onChange={handleChange}>
              <Tab
                label={
                  <FormattedMessage id="businessSite.importSiteFrom.similarTemplates" />
                }
                value="TMP"
              />
              <Tab
                label={
                  <FormattedMessage id="businessSite.importSiteFrom.yourBusinesses" />
                }
                value="COM"
              />
            </TabList>
            <TabPanel value="COM">
              <BusinessesList
                businessId={businessId}
                fromBusiness={fromBusiness}
                setFromBusiness={setFromBusiness}
                query={ownedBusinessQuery}
                emptyText={
                  <FormattedMessage id="businessSite.noOtherBusinesses" />
                }
              />
            </TabPanel>
            <TabPanel value="TMP">
              <BusinessesList
                siteTemplateNameVisible
                businessId={businessId}
                fromBusiness={fromBusiness}
                setFromBusiness={setFromBusiness}
                query={siteTemplatesQuery}
                emptyText={
                  <FormattedMessage id="businessSite.noModelAvailable" />
                }
              />
            </TabPanel>
          </TabContext>
          {fromBusiness && (
            <Box px={2}>
              <Typography variant="body1" color="error">
                <FormattedMessage id="businessSite.confirmationText" />
              </Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={() => onClose()}>
          <FormattedMessage id="common.cancel" />
        </Button>
        <LoadingButton
          loading={mutation.isLoading}
          variant="contained"
          color="primary"
          disabled={!fromBusiness || mutation.isLoading}
          onClick={() =>
            importBusinessSite({
              fromBusiness: fromBusiness as string,
              toBusiness: businessId,
            })
          }>
          <FormattedMessage id="common.continue" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default BusinessSiteImportModal;
