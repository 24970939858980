export default {
  'businessInformationSectionsList.addSection':
    'Haga clic en + para agregar una sección',
  'businessInformationSectionsList.noSections': `No hay secciones disponibles`,
  'businessSiteActionsModal.importSiteFromOtherBusiness':
    'Importar un sitio de otra empresa',
  'businessSitePageName.home': 'Inicio',
  'businessSitePageName.example':
    'Ejemplo: Menú, Nuestros servicios, Directorio de habitaciones...',
  'businessSiteActions.import': 'Importar',
};
