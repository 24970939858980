import {useJsApiLoader} from '@react-google-maps/api';
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {ReactRouter6Adapter} from 'use-query-params/adapters/react-router-6';

import {CssBaseline, ThemeProvider} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';

import {AlertProvider} from './lib/alert';
import './lib/yup-extensions';

import {QueryParamProvider} from 'use-query-params';

import countries from 'i18n-iso-countries';
import store from './app/store';
import AppRoutes from './AppRoutes';
import {theme} from './appTheme';
import config from './config.json';
import AppLocaleProvider from './lib/intl/components/AppLocaleProvider';

countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
countries.registerLocale(require('i18n-iso-countries/langs/es.json'));

function App() {
  useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.googleMapsApiKey,
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AlertProvider>
            <BrowserRouter>
              <QueryParamProvider adapter={ReactRouter6Adapter}>
                <AppLocaleProvider>
                  <AppRoutes />
                </AppLocaleProvider>
              </QueryParamProvider>
            </BrowserRouter>
          </AlertProvider>
          <NotificationContainer />
        </ThemeProvider>
      </Provider>
    </LocalizationProvider>
  );
}

export default App;
