import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

const AspectRatioPicker = ({
  value,
  onChange,
  error,
}: {
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  error?: boolean;
}) => {
  return (
    <FormControl error={error}>
      <InputLabel>Choisir un format</InputLabel>
      <Select
        error={error}
        label="Choisir un format"
        value={value}
        onChange={onChange}>
        <MenuItem value="1:1">1:1 (Carré)</MenuItem>
        <MenuItem value="16:9">16:9 (Paysage horizontal)</MenuItem>
        <MenuItem value="10:4">10:4 (Carousel horizontal)</MenuItem>
        <MenuItem value="9:16">9:16 (Portrait vertical)</MenuItem>
        <MenuItem value="1.91:1">1.91:1 (Paysage horizontal)</MenuItem>
        <MenuItem value="1:1.41">
          1:1.41 (Format A4 / A3 / A5 Portrait)
        </MenuItem>
        <MenuItem value="1.41:1">1:1.41 (Format A4 / A3 / A5 Paysage)</MenuItem>
      </Select>
    </FormControl>
  );
};

export default AspectRatioPicker;
