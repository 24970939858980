import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config.json';
import authService from '../../lib/auth/authService';

export const signupConfirmCode = createAsyncThunk(
  'signup/verifyPhoneNumber',
  async (code: string, {rejectWithValue}) => {
    try {
      await authService.confirmCode(code);
    } catch (err: any) {
      console.log(err.code);
      throw rejectWithValue(err.code);
    }
  },
);
export const signIn = createAsyncThunk(
  'signin/signIn',
  async (
    {
      tel,
    }: {
      tel: string;
    },
    {rejectWithValue},
  ) => {
    try {
      await authService.signIn(tel);
    } catch (err: any) {
      throw rejectWithValue(err.code);
    }
  },
);
export const resendSignInCode = createAsyncThunk(
  'signin/resendSMSCode',
  async (_, {rejectWithValue}) => {
    try {
      await authService.resendCode();
    } catch (err: any) {
      throw rejectWithValue(err.code);
    }
  },
);
export const signinConfirmCode = createAsyncThunk(
  'signin/confirmCode',
  async (code: string, {rejectWithValue}) => {
    try {
      await authService.confirmCode(code);
    } catch (err: any) {
      throw rejectWithValue(err.code);
    }
  },
);
// export const phoneNumberExistsWithBusinessWithSections = createAsyncThunk(
//   'signup/phoneNumberExists',
//   async (tel: string, {rejectWithValue}) => {
//     try {
//       const res = await axios({
//         url: config.baseURL + '/phoneNumberExists',
//         method: 'get',
//         params: {
//           phoneNumber: tel,
//           ownsBusinessWithSections: 'true',
//         },
//       });
//
//       return res.data as boolean;
//     } catch (err: any) {
//       throw rejectWithValue(err.code);
//     }
//   },
// );
export const phoneNumberExists = createAsyncThunk(
  'signup/phoneNumberExists',
  async (tel: string, {rejectWithValue}) => {
    try {
      const res = await axios({
        url: config.baseURL + '/phoneNumberExists',
        method: 'get',
        params: {
          phoneNumber: tel,
        },
      });

      return res.data as boolean;
    } catch (err: any) {
      throw rejectWithValue(err.code);
    }
  },
);
