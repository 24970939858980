import {useEffect, useRef} from 'react';

import {MoreVert} from '@mui/icons-material';
import {Chip, IconButton} from '@mui/material';

import Dialogable from '../../components/molecules/Dialogable';
import useAppLanguage from '../../lib/intl/hooks/use-app-language';
import BusinessInformationSectionActions from '../../modals/BusinessInformationSectionActions/BusinessInformationSectionActions';

const BusinessInformationSectionChip = ({
  section,
  isFirst,
  isLast,
  selected,
  onSelect,
  previewMode,
}: {
  section: BusinessInformationSection;
  isLast?: boolean;
  isFirst?: boolean;
  selected: boolean;
  onSelect: (id: string) => void;
  previewMode?: boolean;
}) => {
  const ref = useRef<any>(null);
  const lang = useAppLanguage();

  useEffect(() => {
    if (selected) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [selected]);

  return (
    <Dialogable
      source={({onOpen}) => (
        <Chip
          ref={ref}
          size="medium"
          color={selected ? 'primary' : 'default'}
          onClick={evt => {
            onSelect(section._id);
            evt.stopPropagation();
          }}
          label={section.nameIntl[lang]}
          icon={
            previewMode ? undefined : (
              <IconButton onClick={onOpen} size="small" color="inherit">
                <MoreVert fontSize="small" />
              </IconButton>
            )
          }
        />
      )}
      dialog={({open, onClose}) => (
        <BusinessInformationSectionActions
          open={open}
          onClose={onClose}
          section={section}
          isFirst={isFirst}
          isLast={isLast}
        />
      )}
    />
  );
};

export default BusinessInformationSectionChip;
