export default {
  'formikGeoLocationPicker.yourAddress': 'Su dirección',
  'formikGeoLocationPicker.modifyAddress': 'Modificar dirección',
  'formikGeoLocationPicker.chooseAddress': 'Elegir dirección',
  'geoAddressPicker.missingCity': 'Ciudad faltante',
  'geoAddressPicker.missingCountry': 'País faltante',
  'geoAddressPicker.missingPostalCode': 'Falta código postal',
  'geoAddressPicker.missingAddress': 'Dirección faltante',
  'geoAddressPicker.currentPositionUnavailable':
    'No se puede recuperar su posición',
  'geoAddressPicker.chooseAddress': 'Elige una dirección',
  'geoAddressPicker.addressNotFound': `No se encontró ninguna dirección`,
};
