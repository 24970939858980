export default {
  'userInfo.newUser': 'Nuevo usuario',
  'userInfo.lastName': 'Nombre',
  'userInfo.firstName': 'Nombre',
  'businessSite.exit': 'Salir',
  'businessSite.addSubsection': 'Subsección',
  'businessSite.preview': 'Vista previa',
  'businessSite.help1': 'Haga clic en el botón "',
  'businessSite.help2': `subsección' en la parte inferior derecha para agregar una subsección`,
  'businessSite.importSiteFrom': 'Importar sitio desde:',
  'businessSite.importSiteFrom.similarTemplates': 'Plantillas similares',
  'businessSite.importSiteFrom.yourBusinesses': 'Tus establecimientos',
  'businessSite.importedSuccessfully': 'El sitio se importó exitosamente',
  'businessSite.noOtherBusinesses': `No tienes otras ubicaciones. Por favor cree uno para continuar.`,
  'businessSite.noModelAvailable': 'No hay modelos disponibles actualmente',
  'businessSite.confirmationText': `Esta acción importará todas las secciones del establecimiento elegido a su establecimiento actual. Se sobrescribirán las secciones existentes. ¿Quieres continuar?`,
};
