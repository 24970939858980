import {createApi} from '@reduxjs/toolkit/dist/query/react';

import invalidatesTagsUsingResult from '../lib/helpers/invalidatesTagsUsingResult';
import providesTagsArray from '../lib/helpers/providesTagsArray';
import providesTagsPagination from '../lib/helpers/providesTagsPagination';

import invalidatesTagsUsingParamId from '../lib/helpers/invalidatesTagsUsingParamId';
import type {IntlContent} from '../lib/intl/types';
import {authBaseQuery} from './apiBaseQuery';

export const businessInformationSectionsApi = createApi({
  reducerPath: 'businessInformationSectionsApi',
  baseQuery: authBaseQuery,
  tagTypes: [
    'BusinessInformationSection',
    'BusinessInformationSectionBlock',
    'BusinessSiteHomePageName',
    'Business',
  ],
  endpoints(build) {
    return {
      importBusinessSite: build.mutation<
        any,
        {
          fromBusiness: string;
          toBusiness: string;
        }
      >({
        query: ({fromBusiness, toBusiness}) => ({
          url: `/importBusinessSite`,
          method: 'post',
          data: {
            fromBusiness,
            toBusiness,
          },
        }),
        invalidatesTags: result =>
          result
            ? [
                {type: 'BusinessInformationSection', id: 'LIST'},
                {type: 'BusinessInformationSectionBlock', id: 'LIST'},
              ]
            : [],
      }),
      getBusinessInformationSections: build.query<
        BusinessInformationSection[],
        string
      >({
        query: business => ({
          url: '/getBusinessInformationSections',
          method: 'get',
          params: {
            business,
          },
        }),
        providesTags: providesTagsArray('BusinessInformationSection'),
      }),
      createBusinessInformationSection: build.mutation<
        BusinessInformationSection,
        {
          nameIntl: IntlContent;
          business: string;
        }
      >({
        query: ({nameIntl, business}) => ({
          url: '/createBusinessInformationSection',
          method: 'post',
          data: {
            nameIntl,
            business,
          },
        }),
        invalidatesTags: invalidatesTagsUsingResult(
          'BusinessInformationSection',
        ),
      }),
      updateBusinessInformationSection: build.mutation<
        BusinessInformationSection,
        {
          id: string;
          nameIntl: IntlContent;
          business: string;
        }
      >({
        query: ({id, nameIntl, business}) => ({
          url: `/updateBusinessInformationSection/${id}`,
          method: 'put',
          data: {
            nameIntl,
            business,
          },
        }),
        invalidatesTags: invalidatesTagsUsingParamId(
          'BusinessInformationSection',
          ({id}: any) => id,
        ),
      }),
      createBusinessInformationSectionBlock: build.mutation<
        BusinessInformationSection,
        {
          headingIntl: IntlContent;
          contentIntl: IntlContent;
          business: string;
          section: string;
          image?: string;
          aspectRatio?: string;
        }
      >({
        query: ({
          headingIntl,
          contentIntl,
          section,
          image,
          business,
          aspectRatio,
        }) => ({
          url: '/createBusinessInformationSectionBlock',
          method: 'post',
          data: {
            image,
            headingIntl,
            contentIntl,
            business,
            section,
            aspectRatio,
          },
        }),
        invalidatesTags: invalidatesTagsUsingResult(
          'BusinessInformationSectionBlock',
        ),
      }),
      updateBusinessInformationSectionBlock: build.mutation<
        BusinessInformationSection,
        {
          id: string;
          headingIntl: IntlContent;
          contentIntl: IntlContent;
          business: string;
          section: string;
        }
      >({
        query: ({id, headingIntl, contentIntl, section, business}) => ({
          url: `/updateBusinessInformationSectionBlock/${id}`,
          method: 'put',
          data: {
            headingIntl,
            contentIntl,
            business,
            section,
          },
        }),
        invalidatesTags: invalidatesTagsUsingParamId(
          'BusinessInformationSectionBlock',
          (args: any) => args.id,
        ),
      }),
      updateBusinessInformationSectionBlockImage: build.mutation<
        BusinessInformationSection,
        {
          id: string;
          image: string;
          aspectRatio: string;
        }
      >({
        query: ({id, image, aspectRatio}) => ({
          url: `/updateBusinessInformationSectionBlockImage/${id}`,
          method: 'patch',
          data: {
            image,
            aspectRatio,
          },
        }),
        invalidatesTags: invalidatesTagsUsingParamId(
          'BusinessInformationSectionBlock',
          (args: any) => args.id,
        ),
      }),
      getBusinessInformationSectionBlocks: build.query({
        query: ({business, section, skip, limit}) => ({
          url: '/getBusinessInformationSectionBlocks',
          method: 'get',
          params: {
            business,
            section,
            skip,
            limit,
          },
        }),
        providesTags: providesTagsPagination('BusinessInformationSectionBlock'),
      }),
      getBusinessInformationSectionBlockById: build.query<
        PopulatedBusinessInformationSectionBlock,
        {
          id: string;
          business: string;
        }
      >({
        query: ({id, business}) => ({
          url: `/getBusinessInformationSectionBlockById/${id}`,
          method: 'get',
          params: {
            business,
          },
        }),
        providesTags: result =>
          result
            ? [
                {
                  type: 'BusinessInformationSectionBlock',
                  id: result._id,
                },
              ]
            : [],
      }),
      getBusinessInformationSectionById: build.query<
        BusinessInformationSection,
        {
          id: string;
          business: string;
        }
      >({
        query: ({id, business}) => ({
          url: `/getBusinessInformationSectionById/${id}`,
          method: 'get',
          params: {
            business,
          },
        }),
        providesTags: result =>
          result
            ? [
                {
                  type: 'BusinessInformationSection',
                  id: result._id,
                },
              ]
            : [],
      }),
      deleteBusinessInformationSectionBlock: build.mutation<
        string,
        {
          id: string;
          business: string;
        }
      >({
        query: ({id, business}) => ({
          url: `/deleteBusinessInformationSectionBlock/${id}`,
          method: 'delete',
          params: {
            business,
          },
        }),
        invalidatesTags: result => [
          {
            type: 'BusinessInformationSectionBlock',
            id: 'LIST',
          },
        ],
      }),
      deleteBusinessInformationSectionBlockImage: build.mutation<
        string,
        {
          id: string;
          business: string;
        }
      >({
        query: ({id, business}) => ({
          url: `/deleteBusinessInformationSectionBlockImage/${id}`,
          method: 'delete',
          params: {
            business,
          },
        }),
        invalidatesTags: result => [
          {
            type: 'BusinessInformationSectionBlock',
            id: 'LIST',
          },
        ],
      }),
      deleteBusinessInformationSection: build.mutation<
        string,
        {
          id: string;
          business: string;
        }
      >({
        query: ({id, business}) => ({
          url: `/deleteBusinessInformationSection/${id}`,
          method: 'delete',
          params: {
            business,
          },
        }),
        invalidatesTags: result => [
          {
            type: 'BusinessInformationSection',
            id: 'LIST',
          },
          {
            type: 'BusinessInformationSectionBlock',
            id: 'LIST',
          },
        ],
      }),
      moveBusinessInformationSectionBlock: build.mutation<
        string,
        {
          id: string;
          business: string;
          direction: 'up' | 'down';
        }
      >({
        query: ({id, business, direction}) => ({
          url: `/moveBusinessInformationSectionBlock/${id}`,
          method: 'patch',
          data: {
            business,
            direction,
          },
        }),
        invalidatesTags: invalidatesTagsUsingParamId(
          'BusinessInformationSectionBlock',
          (args: any) => args.id,
        ),
      }),
      moveBusinessInformationSection: build.mutation<
        string,
        {
          id: string;
          business: string;
          direction: 'left' | 'right';
        }
      >({
        query: ({id, business, direction}) => ({
          url: `/moveBusinessInformationSection/${id}`,
          method: 'patch',
          data: {
            business,
            direction,
          },
        }),
        invalidatesTags: invalidatesTagsUsingParamId(
          'BusinessInformationSection',
          (args: any) => args.id,
        ),
      }),
      getBusinessSiteHomePageName: build.query<IntlContent, string>({
        query: id => ({
          url: `/getBusinessSiteHomePageName/${id}`,
          method: 'get',
        }),
        providesTags: (result, error, id) => [
          {type: 'BusinessSiteHomePageName', id},
        ],
      }),
      updateBusinessSiteHomePageName: build.mutation<
        string,
        {
          business: string;
          siteHomePageNameIntl: IntlContent;
        }
      >({
        query: ({business, siteHomePageNameIntl}) => ({
          url: `/updateBusinessSiteHomePageName/${business}`,
          method: 'patch',
          data: {
            siteHomePageNameIntl,
          },
        }),
        invalidatesTags: (result, error, {business}) => [
          {type: 'BusinessSiteHomePageName', id: business},
        ],
      }),
    };
  },
});

export const {
  useDeleteBusinessInformationSectionBlockMutation,
  useGetBusinessInformationSectionBlockByIdQuery,
  useGetBusinessInformationSectionBlocksQuery,
  useCreateBusinessInformationSectionBlockMutation,
  useCreateBusinessInformationSectionMutation,
  useGetBusinessInformationSectionsQuery,
  useGetBusinessInformationSectionByIdQuery,
  useDeleteBusinessInformationSectionMutation,
  useUpdateBusinessInformationSectionBlockMutation,
  useUpdateBusinessInformationSectionBlockImageMutation,
  useUpdateBusinessInformationSectionMutation,
  useMoveBusinessInformationSectionBlockMutation,
  useDeleteBusinessInformationSectionBlockImageMutation,
  useMoveBusinessInformationSectionMutation,
  useImportBusinessSiteMutation,
  useGetBusinessSiteHomePageNameQuery,
  useUpdateBusinessSiteHomePageNameMutation,
} = businessInformationSectionsApi;
