const BUSINESS_ID_KEY = '@app/business-id';

export const getBusinessId = () => {
  return localStorage.getItem(BUSINESS_ID_KEY);
};

export const setDefaultBusinessId = (businessId: string) => {
  localStorage.setItem(BUSINESS_ID_KEY, businessId);
};

export const removeBusinessId = () => {
  localStorage.removeItem(BUSINESS_ID_KEY);
};
