import React from 'react';

import {Button, MenuItem, Stack} from '@mui/material';

import useCurrentBusinessId from '../../../lib/hooks/use-current-business-id';
import useQueryInternalError from '../../../lib/hooks/useQueryInternalError';

import {useGetBusinessInformationSectionsQuery} from '../../../redux/businessInformationSectionsApi';

import {FormattedMessage} from '../../../lib/intl';
import useAppLanguage from '../../../lib/intl/hooks/use-app-language';
import Link from '../Link';
import FormikSelect from './FormikSelect';

const FormikBusinessInformationSectionsPicker = ({
  name,
  label,
  disabled = false,
}: {
  name: string;
  label: string | React.ReactNode;
  disabled?: boolean;
}) => {
  const businessId = useCurrentBusinessId();
  const query = useGetBusinessInformationSectionsQuery(businessId);
  const lang = useAppLanguage();

  const pickerOptions = React.useMemo(() => {
    if (query.data) {
      return query.data.map(o => ({
        value: o._id,
        label: o.nameIntl[lang],
      }));
    }

    return [];
  }, [query.data]);

  useQueryInternalError(query);

  if (query.isSuccess) {
    return (
      <Stack direction="column" spacing={1}>
        <FormikSelect disabled={disabled} label={label} name={name} required>
          {pickerOptions.map((option: any) => (
            <MenuItem value={option.value} key={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormikSelect>
        {query.isSuccess && query.data?.length === 0 && (
          <Link
            sx={{
              width: '100%',
            }}
            to="/actions/business-information-section/add">
            <Button fullWidth variant="text" size="small">
              <FormattedMessage id="formikBusinessInformationSectionsPicker.createNewSection" />
            </Button>
          </Link>
        )}
      </Stack>
    );
  }

  return null;
};

export default FormikBusinessInformationSectionsPicker;
