import {Box} from '@mui/material';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
// import 'react-quill/dist/quill.snow.css';
// import ReactQuill from 'react-quill';

const RichText = ({
  value,
  onChange,
  onBlur,
  error,
}: {
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  error?: boolean;
}) => (
  <Box
    sx={{
      width: '100%',
      //'& .ql-editor': {
      '& .sun-editor, & .se-container, & .se-wrapper-inner': {
        minHeight: '400px !important',
      },
      '& .sun-editor, & .se-container, & .se-toolbar': {
        // '& .ql-container, & .ql-toolbar': {
        border: theme =>
          `1px solid ${error ? theme.palette.error.main : '#ccc'}`,
      },
    }}>
    {/*
    <ReactQuill
      theme="snow"
      value={value}
      onChange={value => {
        onChange?.(value);
      }}
      onBlur={onBlur}
      modules={{
        clipboard: {
          matchVisual: false,
        },
      }}
    />
    */}

    <SunEditor
      lang="fr"
      setContents={value}
      onChange={content => {
        onChange?.(content);
      }}
      onBlur={onBlur}
      setOptions={{
        formats: ['p', 'blockquote', 'h1', 'h2', 'h3'],
        buttonList: [
          ['undo', 'redo'],
          ['font', 'fontSize', 'formatBlock'],
          ['paragraphStyle', 'blockquote'],
          ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
          ['fontColor', 'hiliteColor', 'textStyle'],
          ['removeFormat'],
          ['indent', 'outdent'],
          ['align', 'horizontalRule', 'list', 'lineHeight'],
          ['table', 'link', 'image', 'video'],
        ],
      }}
    />
  </Box>
);

export default RichText;
