import {ButtonBase, Stack, Typography} from '@mui/material';

import FirebaseStorageImage from '../../lib/firebase-image/components/FirebaseStorageImage';

import AspectRatioImage from '../../components/atoms/AspectRatioImage';
import Dialogable from '../../components/molecules/Dialogable';

import HtmlParser from '../../components/atoms/HtmlParser';
import useAppLanguage from '../../lib/intl/hooks/use-app-language';
import BusinessInformationSectionBlockActions from '../../modals/BusinessInformationSectionBlockActions/BusinessInformationSectionBlockActions';

const BusinessInformationSectionBlockCard = ({
  block,
  total,
  previewMode,
}: {
  block: PopulatedBusinessInformationSectionBlock;
  total: number;
  previewMode?: boolean;
}) => {
  const lang = useAppLanguage();

  const renderCard = () => {
    return (
      <Stack spacing={1} px={1} pt={1} pb={2} width="100%">
        {block?.image && (
          <FirebaseStorageImage
            sx={{
              maxWidth: '450px',
              width: '100%',
              margin: '0 auto',
            }}
            reference={block.image.reference}
            Component={AspectRatioImage}
            ratio={block.aspectRatio}
          />
        )}
        {block.headingIntl?.[lang] && (
          <Typography variant="h4" align="left">
            {block.headingIntl[lang]}
          </Typography>
        )}
        {block.contentIntl && (
          <Typography
            sx={{
              '& h4, & h5, & h6': {
                marginTop: theme => theme.spacing(1),
                marginBottom: theme => theme.spacing(1),
              },
              '& p': {
                marginTop: theme => theme.spacing(1),
                marginBottom: theme => theme.spacing(1),
              },
              '& ul, & ol': {
                marginTop: theme => theme.spacing(1),
                marginBottom: theme => theme.spacing(1),
              },
              '& .se-image-container img, & .se-video-container figure, & .se-video-container iframe':
                {
                  maxWidth: '100%',
                },
              '& table': {
                maxWidth: '100%',
              },
            }}
            component="div"
            variant="body1"
            align="left">
            <HtmlParser>{block.contentIntl[lang]}</HtmlParser>
          </Typography>
        )}
      </Stack>
    );
  };
  return (
    <>
      {!previewMode ? (
        <Dialogable
          source={({onOpen}) => (
            <ButtonBase onClick={onOpen}>{renderCard()}</ButtonBase>
          )}
          dialog={({open, onClose}) => (
            <BusinessInformationSectionBlockActions
              moveUpVisible={block.position > 0}
              moveDownVisible={block.position < total - 1}
              block={block}
              open={open}
              onClose={onClose}
            />
          )}
        />
      ) : (
        renderCard()
      )}
    </>
  );
};

export default BusinessInformationSectionBlockCard;
