import {LoadingButton} from '@mui/lab';
import {Box, Divider, Paper, Stack, Typography} from '@mui/material';

import {Formik} from 'formik';
import * as Yup from 'yup';

import FormikPhoneNumberInput from './FormikPhoneNumberInput';

import {BG_IMAGE_QR_CODE, LOGO_WITH_TEXT} from '../../assets/images';
import AspectRatioImage from '../../components/atoms/AspectRatioImage';
import {FormattedMessage} from '../../lib/intl';
import GoogleRecaptchaDisclaimer from './GoogleRecaptchaDisclaimer';
import HelpSection from './HelpSection';
import SocialMedia from './SocialMedia';

const validationSchema = Yup.object({
  phoneNumber: Yup.string()
    .required('validation.requiredField')
    .phoneNumberValid(),
});

const PhoneNumberForm = ({
  loading,
  onSignin,
}: {
  loading: boolean;
  onSignin: (values: {
    phoneNumber: string;
    country: string;
    ext: string;
  }) => void;
}) => {
  return (
    <Box
      sx={{
        backgroundColor: '#fafafa',
        '& a': {
          textDecoration: 'none',
          color: '#00a2ee',
        },
        maxWidth: 600,
        margin: '0 auto',
        position: 'relative',
      }}
      flex={1}>
      <Box position="relative">
        <AspectRatioImage
          sx={{
            maxWidth: 'none',
          }}
          src={BG_IMAGE_QR_CODE}
          ratio="16:9"
        />
        <Box
          sx={{
            position: 'absolute',
            inset: 0,
            backgroundColor: 'rgba(0, 0, 0, .25)',
          }}
        />
      </Box>
      <Box px={2}>
        <Formik
          validationSchema={validationSchema}
          onSubmit={onSignin}
          initialValues={{
            phoneNumber: '',
            country: 'FR',
            ext: '',
          }}>
          {({handleSubmit}) => (
            <Paper
              variant="outlined"
              elevation={0}
              sx={{
                pb: 3,
                pt: 2,
                position: 'relative',
                top: -100,
                marginBottom: '-100px',
                borderRadius: 8,
              }}>
              <Stack
                sx={{
                  paddingTop: '60px',
                }}
                position="relative"
                direction="column"
                spacing={2}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: -60,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <a target="_blank" href="https://www.fluxence.com">
                    <img
                      src={LOGO_WITH_TEXT}
                      alt="logo"
                      style={{
                        width: 120,
                        height: 120,
                        alignSelf: 'center',
                        backgroundColor: 'white',
                        borderTopRightRadius: 60,
                        borderTopLeftRadius: 60,
                      }}
                    />
                  </a>
                </Box>
                <Typography
                  sx={{
                    fontSize: '1.2rem',
                  }}
                  color="grey.700"
                  align="center"
                  variant="body1">
                  <FormattedMessage id="phoneNumberForm.digitalizeServices" />
                  <br />
                  <FormattedMessage id="phoneNumberForm.printedSupports" />
                </Typography>
              </Stack>
              <Stack px={2} mt={4} direction="column" spacing={3}>
                <Stack direction="column" spacing={2}>
                  <Typography variant="h6">
                    <FormattedMessage id="phoneNumberForm.yourMobileNumber" />
                  </Typography>
                  <FormikPhoneNumberInput
                    name="phoneNumber"
                    countryName="country"
                    extName="ext"
                  />
                </Stack>
                <LoadingButton
                  sx={{
                    alignSelf: 'center',
                    px: 8,
                    textTransform: 'uppercase',
                    borderRadius: 10,
                  }}
                  size="large"
                  loading={loading}
                  variant="contained"
                  color="primary"
                  onClick={loading ? undefined : () => handleSubmit()}>
                  <FormattedMessage id="common.continue" />
                </LoadingButton>
              </Stack>
            </Paper>
          )}
        </Formik>
        <Stack mt={4} spacing={2}>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="phoneNumberForm.cgu1" />{' '}
            <a target="_blank" href="https://www.fluxence.com/cgu">
              <FormattedMessage id="phoneNumberForm.cgu2" />
            </a>{' '}
            <FormattedMessage id="phoneNumberForm.cgu3" />{' '}
            <a
              target="_blank"
              href="https://www.fluxence.com/politique-de-confidentialit%C3%A9">
              <FormattedMessage id="phoneNumberForm.cgu4" />
            </a>
            .
          </Typography>
          <Divider />
          <SocialMedia />
          <Divider />
          <HelpSection />
        </Stack>
      </Box>
      <GoogleRecaptchaDisclaimer />
    </Box>
  );
};

export default PhoneNumberForm;
