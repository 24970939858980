export default {
  'reportIssue.title': 'Contactez nous',
  'reportIssue.subject': 'Sujet *',
  'reportIssue.message': 'Contenu du message *',
  'reportIssue.security': 'Sécurité',
  'reportIssue.payment': 'Paiement',
  'reportIssue.display': 'Affichage',
  'reportIssue.profile': 'Profil',
  'reportIssue.information': 'Informations',
  'reportIssue.other': 'Autres',
  'reportIssue.bug': 'Bug/Problème technique',
  'reportIssue.subjectPlaceholder': 'Sujet *',
  'reportIssue.successMessage':
    'Merci!. Votre message a bien été envoyé. Nous vous répondrons dans les plus brefs délais.',
};
