import {createApi} from '@reduxjs/toolkit/dist/query/react';

import {baseBaseQuery} from '../apiBaseQuery';

const signupApi = createApi({
  reducerPath: 'signupApi',
  baseQuery: baseBaseQuery,
  endpoints(build) {
    return {
      signup: build.mutation<
        string,
        {
          phoneNumber: string;
          name: string;
          email: string;
          lastName: string;
          firstName: string;
          website: string;
          descriptionIntl: string;
          category: string;
          location: string;
          address: string;
          image: string;
          radius: number;
          withSections: boolean;
          enabled: boolean;
        }
      >({
        query: data => ({
          url: '/signup',
          method: 'post',
          data: {
            ...data,
            status: 'B2B_WEB',
            sendThanksForDownloadEmail: false,
          },
        }),
        async transformResponse(response: any) {
          // const credential = authService.getCredential();
          //
          // try {
          //   await firebase
          //     .auth()
          //     .currentUser?.reauthenticateWithCredential(credential);
          // } catch (err) {
          //   console.error(err);
          // }

          return response;
        },
      }),
    };
  },
});

export const {useSignupMutation} = signupApi;

export default signupApi;
