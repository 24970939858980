import firebase from '../lib/app/firebase';
import getRealReferenceInFirebasestorage from '../lib/helpers/getRealReferenceInFirebasestorage';

import {createAsyncThunk} from '@reduxjs/toolkit';
import generate from 'shortid';

import filesApi from './filesApi';

export const removeFile = createAsyncThunk(
  'files/removeFile',
  async (reference: AppFile, {dispatch}) => {
    try {
      await dispatch(
        filesApi.endpoints.deleteFile.initiate(reference._id),
      ).unwrap();
      await firebase
        .storage()
        .ref(getRealReferenceInFirebasestorage(reference.reference))
        .delete();
    } catch (err) {
      // swallow cause it should always supposed to find the reference
    }
  },
);

export const uploadFile = createAsyncThunk(
  'files/uploadFile',
  async (file: File, {dispatch}) => {
    const fileName = generate() + '_' + file.name;

    const reference = firebase.storage().ref('public/' + fileName);

    const task = reference.put(file);

    return new Promise<AppFile>((res, rej) => {
      task.on(
        'state_changed',
        async sps => {
          try {
            switch (sps.state) {
              case firebase.storage.TaskState.SUCCESS: // or 'running'
                console.log('success uploading');

                break;
            }
          } catch (err) {
            rej(err);
          }
        },
        error => {
          console.error(error);
          rej(error);
        },
        async () => {
          const createdFile = await dispatch(
            filesApi.endpoints.createFile.initiate({
              reference: fileName,
              name: file.name,
            }),
          ).unwrap();

          res(createdFile);
        },
      );
    });
  },
);
