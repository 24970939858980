export default {
  'formikGeoLocationPicker.yourAddress': 'Your address',
  'formikGeoLocationPicker.modifyAddress': 'Update address',
  'formikGeoLocationPicker.chooseAddress': 'Choose address',
  'geoAddressPicker.missingCity': 'Missing City',
  'geoAddressPicker.missingCountry': 'Missing Country',
  'geoAddressPicker.missingPostalCode': 'Missing postal code',
  'geoAddressPicker.missingAddress': 'Missing Address',
  'geoAddressPicker.currentPositionUnavailable':
    'Unable to retrieve your position',
  'geoAddressPicker.chooseAddress': 'Choose an address',
  'geoAddressPicker.addressNotFound': `No address found`,
};
