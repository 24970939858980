import {Box} from '@mui/material';
import BusinessSite from '../../components/organisms/BusinessSite';

const BusinessPreview = ({
  businessId,
  quitPreviewVisible = true,
}: {
  businessId: string;
  quitPreviewVisible?: boolean;
}) => (
  <>
    <Box
      id="container"
      pb={4}
      sx={{
        position: 'relative',
        width: '100%',
        height: '100vh',
        maxWidth: '600px',
        mx: 'auto',
        '@media (min-width: 600px)': {
          border: '20px solid #000',
          borderRadius: '10px',
          boxSizing: 'border-box',
          overflowY: 'auto',
          px: 1,
          my: 1,
        },
      }}>
      <BusinessSite
        businessId={businessId}
        quitPreviewVisible={quitPreviewVisible}
        previewMode
      />
    </Box>
  </>
);

export default BusinessPreview;
