import {Edit} from '@mui/icons-material';
import {IconButton, Stack, Typography} from '@mui/material';
import Link from '../../components/atoms/Link';
import useCurrentBusinessId from '../../lib/hooks/use-current-business-id';
import {FormattedMessage} from '../../lib/intl';
import {useGetBusinessSiteHomePageNameQuery} from '../../redux/businessInformationSectionsApi';

const BusinessSitePageName = ({
  previewMode = false,
}: {
  previewMode?: boolean;
}) => {
  const businessId = useCurrentBusinessId();
  const {data: siteHomePageNameIntl} = useGetBusinessSiteHomePageNameQuery(
    businessId as string,
  );

  return (
    <Stack direction="column" pl={2} py={1}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="body1">
          {!previewMode && (
            <>
              <FormattedMessage id="businessSitePageName.home" />:{' '}
            </>
          )}
          <b>
            {siteHomePageNameIntl?.['FR'] || (
              <FormattedMessage id="businessSitePageName.home" />
            )}
          </b>
        </Typography>
        {!previewMode && (
          <Link to="/actions/preferences/edit">
            <IconButton size="small">
              <Edit fontSize="small" />
            </IconButton>
          </Link>
        )}
      </Stack>
      {!previewMode && (
        <Typography
          variant="body2"
          sx={{
            color: '#999',
          }}>
          <FormattedMessage id="businessSitePageName.example" />
        </Typography>
      )}
    </Stack>
  );
};

export default BusinessSitePageName;
