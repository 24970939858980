export default {
  'formikGeoLocationPicker.yourAddress': 'Votre adresse',
  'formikGeoLocationPicker.modifyAddress': "Modifier l'adresse",
  'formikGeoLocationPicker.chooseAddress': "Choisir l'adresse",
  'geoAddressPicker.missingCity': 'Ville manquante',
  'geoAddressPicker.missingCountry': 'Pays manquant',
  'geoAddressPicker.missingPostalCode': 'Code postal manquant',
  'geoAddressPicker.missingAddress': 'Adresse manquante',
  'geoAddressPicker.currentPositionUnavailable':
    'Impossible de récupérer votre position',
  'geoAddressPicker.chooseAddress': 'Choisir une adresse',
  'geoAddressPicker.addressNotFound': `Aucune adresse n'a été trouvée`,
};
