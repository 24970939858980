import {getBusinessId} from '../storage/business-id';

const useCurrentBusinessId = () => {
  const businessId = getBusinessId();

  if (!businessId) {
    throw new Error('No business is selected.');
  }

  return businessId;
};

export default useCurrentBusinessId;
