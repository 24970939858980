import omit from 'lodash/omit';
import React, {useMemo, useState} from 'react';
import {NotificationManager} from 'react-notifications';

import {
  Box,
  Button,
  DialogActions,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Cropper from 'react-easy-crop';
import {FormattedMessage} from '../../lib/intl';
import AspectRatioImage from './AspectRatioImage';

const CROP_DEFAULT = {x: 50, y: 50, width: 50, height: 50};

const getAspectRatio = (aspectRatio?: AspectRatio) => {
  switch (aspectRatio) {
    case '1:1':
      return 1;
    case '16:9':
      return 16 / 9;
    case '10:4':
      return 10 / 4;
    case '9:16':
      return 9 / 16;
    case '1.91:1':
      return 1.91;
    case '1:1.41':
      return 1 / 1.41;
    case '1.41:1':
      return 1.41;
    default:
      return 1;
  }
};

const FileInput = ({
  label = 'Télécharger une image ...',
  maxSize,
  value,
  crop,
  aspectRatio,
  onFileSelect,
  onFileRemove,
  error,
}: {
  label?: string;
  crop?: boolean;
  aspectRatio?: AspectRatio;
  value?: File;
  onFileSelect: (file: File) => void;
  onFileRemove: () => void;
  maxSize?: number;
  error?: boolean;
}) => {
  const [imageRef, setImageRef] = React.useState<any>(null);
  const theme = useTheme();
  const [cropArea, setCropArea] = useState(CROP_DEFAULT);
  const [cropPoint, setCropPoint] = useState(
    omit(CROP_DEFAULT, ['width', 'height']),
  );
  const [zoom, setZoom] = useState(1);

  const [image, setImage] = React.useState<string | null>(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];

    if (file) {
      if (maxSize && file.size > maxSize * 1024 * 1024) {
        NotificationManager.error(
          `La taille du fichier ne doit pas dépasser ${maxSize} megabytes`,
        );
        return;
      }

      if (crop) {
        setImage(URL.createObjectURL(file));
      } else {
        onFileSelect(file);
      }
    }

    event.target.value = '';
  };

  const handleClose = () => {
    setImage(null);
    setCropArea(CROP_DEFAULT);
  };

  const objectURL = useMemo(() => {
    if (value) {
      return URL.createObjectURL(value);
    }

    return null;
  }, [value]);

  return (
    <Stack spacing={1}>
      <Dialog fullScreen open={!!image} onClose={handleClose}>
        <DialogContent
          sx={{
            position: 'relative',
            maxHeight: 'calc(100vh - 215px)',
          }}>
          <Cropper
            setImageRef={setImageRef}
            image={image as string}
            crop={cropPoint}
            zoom={zoom}
            aspect={getAspectRatio(aspectRatio)}
            onCropChange={p => {
              setCropPoint(p);
            }}
            onCropComplete={area => {
              setCropArea(area);
            }}
            onZoomChange={setZoom}
          />
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={1} sx={{justifyContent: 'flex-end'}}>
            <Button
              variant="contained"
              color="primary"
              disabled={!image}
              onClick={() => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                if (ctx && imageRef.current) {
                  const scaleX =
                    imageRef.current.naturalWidth / imageRef.current.width;
                  const scaleY =
                    imageRef.current.naturalHeight / imageRef.current.height;

                  const targetX =
                    Math.floor((imageRef.current.width * cropArea.x) / 100) *
                    scaleX;
                  const targetY =
                    Math.floor((imageRef.current.height * cropArea.y) / 100) *
                    scaleY;
                  const targetWidth =
                    Math.floor(
                      (imageRef.current.width * cropArea.width) / 100,
                    ) * scaleX;
                  const targetHeight =
                    Math.floor(
                      (imageRef.current.height * cropArea.height) / 100,
                    ) * scaleY;

                  canvas.width = targetWidth;
                  canvas.height = targetHeight;

                  ctx.drawImage(
                    imageRef.current,
                    targetX,
                    targetY,
                    targetWidth,
                    targetHeight,
                    0,
                    0,
                    targetWidth,
                    targetHeight,
                  );

                  canvas.toBlob(blob => {
                    if (blob) {
                      onFileSelect(
                        new File(
                          [blob],
                          `${Date.now()}-cropped.${blob.type.split('/')[1]}`,
                          {
                            type: blob.type,
                          },
                        ),
                      );
                      handleClose();
                    }
                  });
                }
              }}>
              <FormattedMessage id="common.finish" />
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                onFileRemove();
                handleClose();
              }}>
              <FormattedMessage id="common.cancel" />
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <Stack sx={{alignItems: 'center', width: '100%', position: 'relative'}}>
        <input
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            opacity: 0,
            cursor: 'pointer',
          }}
          type="file"
          onChange={handleChange}
        />
        {!value && (
          <Box
            p={2}
            sx={{
              border: `1px solid ${error ? theme.palette.error.main : '#aaa'}`,
              width: '100%',
            }}>
            <Typography
              align="center"
              color={error ? theme.palette.error.main : 'inherit'}>
              {label}
            </Typography>
          </Box>
        )}
        {value && (
          <AspectRatioImage
            ratio={aspectRatio as AspectRatio}
            src={objectURL as string}
            sx={{
              maxWidth: '200px',
            }}
          />
        )}
      </Stack>
      {value && (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => onFileRemove()}>
          <FormattedMessage id="common.delete" />
        </Button>
      )}
    </Stack>
  );
};

export default FileInput;
