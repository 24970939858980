import React from 'react';
import {LinkProps, NavLink as RouterLink} from 'react-router-dom';

import {Link as MuiLink, LinkProps as MuiLinkProps} from '@mui/material';

const Link = React.forwardRef<HTMLAnchorElement, LinkProps & MuiLinkProps>(
  (props: MuiLinkProps & LinkProps, ref) => (
    <MuiLink
      ref={ref}
      sx={{
        color: 'inherit',
        '&, &:active': {
          textDecoration: 'none',
        },
      }}
      component={RouterLink}
      {...props}
    />
  ),
);

export default Link;
