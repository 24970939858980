import {Paper} from '@mui/material';

import {useParams} from 'react-router-dom';
import CheckoutForm, {StripeWrapper} from '../../components/atoms/CheckoutForm';
import PageAction from '../../components/organisms/PageAction';
import {useIntl} from '../../lib/intl';

const Checkout = () => {
  const intl = useIntl();
  const {token} = useParams();
  return (
    <PageAction title={intl.formatMessage({id: 'payments.title'})}>
      <Paper square>
        <StripeWrapper clientSecret={token}>
          <CheckoutForm clientSecret={token} />
        </StripeWrapper>
      </Paper>
    </PageAction>
  );
};

export default Checkout;
