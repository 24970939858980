export default {
  'businessInformationSectionsList.addSection':
    'Cliquez sur + pour ajouter une rubrique',
  'businessInformationSectionsList.noSections': `Aucune rubrique n'est disponible`,
  'businessSiteActionsModal.importSiteFromOtherBusiness':
    'Importer un site depuis un autre établissement',
  'businessSitePageName.home': 'Accueil',
  'businessSitePageName.example':
    'Exemple: Menu, Nos services, Room directory ...',
  'businessSitePageName.pageName': 'Nom de la page: ',
  'businessSiteActions.import': 'Importer',
};
