import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate} from 'react-router-dom';

import {
  Button,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';

import FormikGeoLocationPicker from '../../lib/googlemaps/components/GeoLocationPicker/FormikGeoLocationPicker';
import {internalError} from '../../lib/helpers/internalError';
import useUploadFile from '../../lib/hooks/useUploadFile';

import {Formik} from 'formik';
import * as yup from 'yup';

import FormikFileInput from '../../components/atoms/formik/FormikFileInput';
import FormikPhoneNumberInput from '../../components/atoms/formik/FormikPhoneNumberInput';
import FormikTextField from '../../components/atoms/formik/FormikTextField/FormikTextField';
import FormikBusinessCategoryPicker from '../../components/molecules/formik/FormikBusinessCategoryPicker';
import PageAction from '../../components/organisms/PageAction';

import {loggedIn} from '../../redux/auth/authSlice';
import {useSignupMutation} from '../../redux/signup/signupApi';

import {BUSINESS_DESCRIPTION_MAX_LENGTH} from '../../app/constants';
import {useAppDispatch} from '../../app/hooks';
import FormikSingleChoice from '../../components/atoms/formik/FormikSingleChoice';
import SaveButton from '../../components/atoms/SaveButton';
import {FormattedMessage, useIntl} from '../../lib/intl';

const validationSchema = yup.object({
  image: yup.string().required('validation.fieldRequired'),
  name: yup.string().required('validation.fieldRequired'),
  firstName: yup.string().required('validation.fieldRequired'),
  lastName: yup.string().required('validation.fieldRequired'),
  email: yup
    .string()
    .required('validation.fieldRequired')
    .email('validation.inValidEmail'),
  phoneNumber: yup.string().phoneNumberValid(),
  category: yup.string().required('validation.fieldRequired'),
  address: yup.string().required('validation.fieldRequired'),
  location: yup.object().locationRequired('validation.tryAnotherAddress'),
  website: yup.string().url('validation.inValidUrl'),
  enabled: yup.boolean(),
});

const CompleteInformation = ({
  phoneNumber,
  country,
  ext,
  onClose,
}: {
  phoneNumber: string;
  country: string;
  ext: string;
  onClose: () => void;
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const uploadFile = useUploadFile();
  const [signUp, mutation] = useSignupMutation();
  const [loading, setLoading] = React.useState(false);

  const handleSignup = async ({
    email,
    image,
    name,
    phoneNumber,
    descriptionIntl,
    address,
    location,
    category,
    firstName,
    lastName,
    website,
    enabled,
  }: any) => {
    try {
      setLoading(true);
      const coverImage = await uploadFile(image);
      await signUp({
        email,
        phoneNumber,
        image: coverImage._id,
        name,
        category,
        address,
        descriptionIntl,
        location,
        radius: 10,
        firstName,
        lastName,
        website: website?.toLowerCase(),
        withSections: true,
        enabled,
      }).unwrap();
      NotificationManager.success(
        intl.formatMessage({id: 'completeInformation.successMessage'}),
      );
      dispatch(loggedIn());
      navigate('/');
    } catch (e: any) {
      if (e?.data?.error?.code === 'EMAIL_ALREADY_TAKEN') {
        NotificationManager.error(
          intl.formatMessage({id: 'validation.emailUsed'}),
        );
      } else if (e?.data?.error?.code === 'PHONE_NUMBER_ALREADY_TAKEN') {
        NotificationManager.error(
          intl.formatMessage({id: 'validation.phoneUsed'}),
        );
      } else {
        internalError(mutation.error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageAction title={intl.formatMessage({id: 'completeInformation.title'})}>
      <Formik
        validationSchema={validationSchema}
        onSubmit={({
          email,
          image,
          name,
          phoneNumber,
          descriptionIntl,
          address,
          location,
          category,
          firstName,
          lastName,
          website,
          enabled,
        }: any) =>
          handleSignup({
            email,
            image,
            name,
            phoneNumber,
            descriptionIntl,
            address,
            location,
            category,
            firstName,
            lastName,
            website,
            enabled,
          })
        }
        initialValues={{
          image: '',
          name: '',
          email: '',
          descriptionIntl: {},
          category: '',
          address: '',
          location: '',
          phoneNumber,
          country,
          ext,
          lastName: '',
          firstName: '',
          enabled: true,
        }}>
        {({handleSubmit}) => (
          <Stack p={2} direction="column" spacing={3}>
            <Stack spacing={2} direction="column">
              <Typography variant="h6">
                <FormattedMessage id="completeInformation.accountInformation" />
              </Typography>
              <FormikPhoneNumberInput
                disabled
                name="phoneNumber"
                countryName="country"
                extName="ext"
                label={intl.formatMessage({
                  id: 'completeInformation.phoneNumber',
                })}
              />
              <Button size="small" variant="text" onClick={onClose}>
                <FormattedMessage id="completeInformation.changePhoneNumber" />
              </Button>
              <FormikTextField
                name="email"
                label={intl.formatMessage({id: 'completeInformation.email'})}
              />
              <Stack direction="row" spacing={1}>
                <FormikTextField
                  sx={{flex: 1}}
                  name="lastName"
                  label={intl.formatMessage({
                    id: 'completeInformation.lastName',
                  })}
                />
                <FormikTextField
                  sx={{flex: 1}}
                  name="firstName"
                  label={intl.formatMessage({
                    id: 'completeInformation.firstName',
                  })}
                />
              </Stack>
            </Stack>
            <Divider />
            <Stack direction="column" spacing={2}>
              <Typography variant="h6">
                <FormattedMessage id="completeInformation.generalInformation" />
              </Typography>
              <FormikFileInput
                crop
                aspectRatio="1:1"
                name="image"
                maxSize={5}
                label={intl.formatMessage({id: 'editBusinessImage.uploadLogo'})}
              />
              <FormikTextField
                name="name"
                label={intl.formatMessage({
                  id: 'completeInformation.businessName',
                })}
              />
              <FormikTextField
                name="description"
                label={intl.formatMessage({
                  id: 'completeInformation.description',
                })}
                multiline
                rows={4}
                enableIntl
                automaticTranslation
                InputProps={{
                  inputProps: {
                    maxlength: BUSINESS_DESCRIPTION_MAX_LENGTH,
                  },
                }}
              />
              <FormikBusinessCategoryPicker
                label={intl.formatMessage({
                  id: 'completeInformation.activitySector',
                })}
                name="category"
              />
              <FormikGeoLocationPicker
                locationName="location"
                addressName="address"
              />
              <FormikTextField
                name="website"
                label={intl.formatMessage({id: 'completeInformation.website'})}
                placeholder="https://www.fluxence.com"
              />
              <FormControlLabel
                control={<FormikSingleChoice name="enabled" />}
                label={intl.formatMessage({id: 'createBusiness.status'})}
              />
            </Stack>
            <SaveButton
              onClick={loading ? undefined : () => handleSubmit()}
              loading={loading}
              variant="contained"
              color="primary">
              <FormattedMessage id="common.finish" />
            </SaveButton>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default CompleteInformation;
