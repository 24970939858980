import * as yup from 'yup';
import type {IntlKeys} from '../intl';

yup.addMethod(yup.object, 'locationRequired', function (message: string) {
  return this.test({
    name: 'locationRequired',
    message: '${path} is required',
    test: function (value: any) {
      const {path, createError} = this;

      return value?.longitude && value?.latitude
        ? true
        : createError({path, message});
    },
  });
});

declare module 'yup' {
  // @ts-ignore
  interface ObjectSchema {
    locationRequired(message: IntlKeys): any;
  }
}
