import {createApi} from '@reduxjs/toolkit/dist/query/react';

import providesTagsArray from '../lib/helpers/providesTagsArray';

import FileDownload from 'js-file-download';
import invalidatesTagsUsingParamId from '../lib/helpers/invalidatesTagsUsingParamId';
import providesTagsFromOneResult from '../lib/helpers/providesTagsFromOneResult';
import {IntlContent} from '../lib/intl/types';
import {authBaseQuery} from './apiBaseQuery';

export const businessApi = createApi({
  reducerPath: 'businessApi',
  baseQuery: authBaseQuery,
  tagTypes: ['Business', 'SiteTemplateBusiness'],
  endpoints(build) {
    return {
      updateBusinessExternalSettings: build.mutation<
        '',
        {
          business: string;
          extraSettings: {
            [key: string]: any;
          };
        }
      >({
        query: ({business, extraSettings}) => ({
          url: `/updateBusinessExternalSettings`,
          method: 'patch',
          data: {
            extraSettings,
            business,
          },
        }),
        invalidatesTags: invalidatesTagsUsingParamId(
          'Business',
          ({business}: any) => business,
        ),
      }),
      getUserOwnedBusinesses: build.query<PopulatedBusiness[], void>({
        query: () => ({
          url: '/getWebUserOwnedBusinesses',
          method: 'get',
          params: {
            withSections: 'true',
          },
        }),
        providesTags: providesTagsArray('Business'),
      }),
      getSiteTemplatesBusinesses: build.query<PopulatedBusiness[], string>({
        query: (id: string) => ({
          url: `/getSiteTemplatesBusinesses/${id}`,
          method: 'get',
        }),
        providesTags: providesTagsArray('Business'),
      }),
      saveBusinessAsSiteTemplate: build.mutation<
        PopulatedBusiness,
        {id: string}
      >({
        query: ({id}) => ({
          url: `/saveAsSiteTemplatesBusiness/${id}`,
          method: 'patch',
        }),
        invalidatesTags: invalidatesTagsUsingParamId(
          'Business',
          ({id}: any) => id,
        ),
      }),
      removeBusinessAsSiteTemplate: build.mutation<PopulatedBusiness, string>({
        query: id => ({
          url: `/removeBusinessAsSiteTemplate/${id}`,
          method: 'delete',
        }),
        invalidatesTags: invalidatesTagsUsingParamId(
          'Business',
          (id: any) => id,
        ),
      }),
      createBusiness: build.mutation<
        Business,
        {
          name: string;
          category: string;
          image: string;
          address: string;
          location: {
            longitude: number;
            latitude: number;
          };
          descriptionIntl: IntlContent;
          website: string;
          enabled: boolean;
        }
      >({
        query: payload => ({
          url: '/createBusiness',
          method: 'post',
          data: {
            name: payload.name,
            category: payload.category,
            image: payload.image,
            address: payload.address,
            location: payload.location,
            descriptionIntl: payload.descriptionIntl,
            website: payload.website,
            withSections: true,
            enabled: payload.enabled,
          },
        }),
        invalidatesTags: result =>
          result ? [{type: 'Business', id: 'LIST'}] : [],
      }),
      getAllBusinessCategories: build.query<BusinessCategory[], void>({
        query: () => ({
          url: '/getBusinessCategories',
          method: 'get',
          keepUnusedDataFor: 100000,
        }),
      }),
      getBusinessQRCodePDF: build.query<
        any,
        {id: string; format: string; admin?: string; template?: string}
      >({
        query: ({id, format = 'A6', template = ''}) => ({
          url: `/getTmpBusinessQRCodePDF/${id}`,
          method: 'get',
          params: {
            format,
            template,
          },
          responseType: 'blob',
        }),
        transformResponse(response: any): any {
          // FileDownload(response, `business-qr-code-${Date.now()}.pdf`);
          FileDownload(response, `business-qr-code-${Date.now()}.png`);
          return {data: ''};
        },
        providesTags: [],
      }),
      getBusinessById: build.query<PopulatedBusiness, string>({
        query: id => ({
          url: `/getBusinessById/${id}`,
          method: 'get',
        }),
        providesTags: providesTagsFromOneResult('Business'),
      }),
      updateBusiness: build.mutation<
        Business,
        {
          id: string;
          data: Partial<
            | Pick<
                Business,
                | 'name'
                | 'category'
                | 'address'
                | 'descriptionIntl'
                | 'website'
                | 'enabled'
              > & {
                location: {
                  longitude: number;
                  latitude: number;
                };
              }
          > & {
            image?: string;
          };
        }
      >({
        query: payload => ({
          url: `/updateBusiness/${payload.id}`,
          method: 'patch',
          data: {
            name: payload.data.name,
            category: payload.data.category,
            image: payload.data.image,
            address: payload.data.address,
            location: payload.data.location,
            descriptionIntl: payload.data.descriptionIntl,
            website: payload.data.website,
            enabled: payload.data.enabled,
          },
        }),
        invalidatesTags: invalidatesTagsUsingParamId(
          'Business',
          ({id}: any) => id,
        ),
      }),
      updateBusinessImage: build.mutation<
        Business,
        {
          id: string;
          image: string;
        }
      >({
        query: ({id, ...data}) => ({
          url: `/updateBusinessImage/${id}`,
          method: 'patch',
          data,
        }),
        invalidatesTags: invalidatesTagsUsingParamId(
          'Business',
          ({id}: any) => id,
        ),
      }),
      updateBusinessAddress: build.mutation<
        Business,
        {
          id: string;
          location: {
            longitude: number;
            latitude: number;
          };
          address: string;
        }
      >({
        query: payload => ({
          url: `/updateBusinessAddress/${payload.id}`,
          method: 'patch',
          data: {
            address: payload.address,
            location: payload.location,
          },
        }),
        invalidatesTags: invalidatesTagsUsingParamId(
          'Business',
          ({id}: any) => id,
        ),
      }),
    };
  },
});

export const {
  useUpdateBusinessMutation,
  useGetUserOwnedBusinessesQuery,
  useGetAllBusinessCategoriesQuery,
  useUpdateBusinessAddressMutation,
  useUpdateBusinessImageMutation,
  useLazyGetBusinessQRCodePDFQuery,
  useGetBusinessByIdQuery,
  useCreateBusinessMutation,
  useUpdateBusinessExternalSettingsMutation,
  useGetSiteTemplatesBusinessesQuery,
  useSaveBusinessAsSiteTemplateMutation,
  useRemoveBusinessAsSiteTemplateMutation,
} = businessApi;
