import firebase from '../../lib/app/firebase';

import axios from 'axios';

import config from '../../config.json';

const authUserExists = async () => {
  try {
    const idToken = await firebase.auth().currentUser?.getIdToken();

    await axios.get(config.baseURL + '/getAuthUser', {
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    });

    return true;
  } catch (err) {
    return false;
  }
};

export const listenForAuthUser = (callback: (user: any | null) => void) => {
  return firebase.auth().onAuthStateChanged(async user => {
    if (user && !user.isAnonymous) {
      const userExists = await authUserExists();

      if (userExists) {
        callback(user);
        return;
      }
    }

    callback(null);
  });
};
