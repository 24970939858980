import {configureStore} from '@reduxjs/toolkit';

import {authReducer} from '../redux/auth/authSlice';
import authApi from '../redux/authApi';
import {businessApi} from '../redux/businessApi';
import {businessInformationSectionsApi} from '../redux/businessInformationSectionsApi';
import commonApi from '../redux/commonApi';
import filesApi from '../redux/filesApi';
import {autocompleteApi, geocodingApi} from '../redux/geocodingApi';
import {loaderReducer} from '../redux/loaderSlice';
import paymentApi from '../redux/paymentApi';
import reportIssuesApi from '../redux/reportIssueApi';
import signupApi from '../redux/signup/signupApi';

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

const store = configureStore({
  reducer: {
    auth: authReducer,
    loader: loaderReducer,
    [authApi.reducerPath]: authApi.reducer,
    [commonApi.reducerPath]: commonApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
    [businessInformationSectionsApi.reducerPath]:
      businessInformationSectionsApi.reducer,
    [businessApi.reducerPath]: businessApi.reducer,
    [geocodingApi.reducerPath]: geocodingApi.reducer,
    [signupApi.reducerPath]: signupApi.reducer,
    [autocompleteApi.reducerPath]: autocompleteApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [reportIssuesApi.reducerPath]: reportIssuesApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      authApi.middleware,
      commonApi.middleware,
      filesApi.middleware,
      businessInformationSectionsApi.middleware,
      businessApi.middleware,
      geocodingApi.middleware,
      signupApi.middleware,
      autocompleteApi.middleware,
      paymentApi.middleware,
      reportIssuesApi.middleware,
    ]),
});

export default store;
