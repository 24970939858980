import DE from './de';
import EN from './en';
import ES from './es';
import FR from './fr';

const intlMessages = {
  FR,
  EN,
  ES,
  DE,
};

export type IntlMessageLanguages = keyof typeof intlMessages;

export default intlMessages;
