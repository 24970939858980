import React from 'react';
import {Outlet, useNavigate} from 'react-router-dom';

import {getBusinessId} from '../storage/business-id';

const BusinessRoute = () => {
  const [hasBusiness, setHasBusiness] = React.useState<boolean>();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (getBusinessId()) {
      setHasBusiness(true);
    } else {
      navigate('/my-businesses');
    }
  }, []);

  if (hasBusiness) {
    return <Outlet />;
  }

  return null;
};

export default BusinessRoute;
