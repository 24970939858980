import {Box, Typography} from '@mui/material';
import {FormattedMessage} from '../../lib/intl';

const GoogleRecaptchaDisclaimer = () => (
  <>
    <Box
      sx={{
        height: '90px',
      }}
    />
    <Box
      px={1}
      py={2}
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#fafafa',
      }}>
      <Typography variant="body2" align="justify">
        <FormattedMessage id="googleRecaptchaDisclaimer.message" />
      </Typography>
    </Box>
  </>
);

export default GoogleRecaptchaDisclaimer;
