export default {
  'reportIssue.title': 'Kontaktieren Sie uns',
  'reportIssue.subject': 'Betreff *',
  'reportIssue.message': 'Nachrichteninhalt *',
  'reportIssue.security': 'Sicherheit',
  'reportIssue.payment': 'Zahlung',
  'reportIssue.display': 'Anzeige',
  'reportIssue.profile': 'Profil',
  'reportIssue.information': 'Informationen',
  'reportIssue.other': 'Andere',
  'reportIssue.bug': 'Bug/technisches Problem',
  'reportIssue.subjectPlaceholder': 'Betreff *',
  'reportIssue.successMessage':
    'Vielen Dank!. Ihre Nachricht wurde erfolgreich gesendet. Wir werden Ihnen so schnell wie möglich antworten.',
};
