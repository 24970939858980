export default {
  'authPhoneNumber.invalidPhoneNumber':
    'The phone number you entered is invalid (Ex: +33611222333, +12481237654, +34600000000)',
  'authPhoneNumber.tooManyRequests':
    'You have tried to log in several times. Try again later',
  'completeInformation.title': 'Create your business',
  'completeInformation.accountInformation': 'Account Information',
  'completeInformation.phoneNumber': 'Phone number',
  'completeInformation.changePhoneNumber': 'Change phone number',
  'completeInformation.generalInformation': 'General information',
  'completeInformation.businessName': `Business name`,
  'completeInformation.uploadLogo': 'Upload a logo',
  'completeInformation.email': 'Email',
  'completeInformation.lastName': 'Name',
  'completeInformation.firstName': 'First Name',
  'completeInformation.description': 'Description',
  'completeInformation.activitySector': `Activity sector`,
  'completeInformation.website': 'Website',
  'completeInformation.receivedCode': 'The validation code received by SMS',
  'completeInformation.confirmationCode': 'Confirmation code',
  'completeInformation.successMessage': 'Your registration is complete',
  'formikPhoneNumberInput.countryNotFound': 'No country matches your search',
  'googleRecaptchaDisclaimer.message': `This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.`,
  'helpSection.bullet1': `Create your sections and subsections: Insert the description of your services with your best photos`,
  'helpSection.bullet2': `Download and display your QR code: your customers access all your services on a single QR code`,
  'helpSection.bullet3': `Create Personalized Professional QR Codes: Design unique QR codes for your restaurant menus, hotel directories or service presentations, with advanced customization options.`,
  'helpSection.bullet4': `Manage multiple businesses: You can create as many businesses as you want. Each business has its QR code`,
  'helpSection.bullet5': `Enjoy Responsive Customer Support: Our dedicated team is here to help you every step of the way, whether it's resolving a technical issue or advising you on best practices for professional QR codes .`,
  'helpSection.bullet6': `Manage your QR codes with ease: manage and update your QR codes in real time, with multiple functions.`,
  'helpSection.forMoreInfo': 'For more information about',
  'phoneNumberForm.digitalizeServices': 'Digitalize your services',
  'phoneNumberForm.printedSupports':
    'Bring your printed media to life with QR codes',
  'phoneNumberForm.yourMobileNumber': 'Your mobile number:',
  'phoneNumberForm.cgu1': `By continuing, you authorize FluXence Pro to store your number
            telephone number, and you accept our`,
  'phoneNumberForm.cgu2': `conditions of use`,
  'phoneNumberForm.cgu3': `and`,
  'phoneNumberForm.cgu4': `our privacy policy`,
  'resendCodeButton.didNotReceiveCode': `I did not receive a code!`,
  'signinConfirmationCode.invalidCode': 'The code you entered is invalid',
  'signinConfirmationCode.tooManyRequests':
    'You have tried to sign in several times. Try again later',
  'signinConfirmationCode.smsSending': 'SMS sending in progress. Please wait',
  'signupConfirmationCode.invalidCode': 'The code you entered is invalid',
  'signupConfirmationCode.tooManyRequests':
    'You have tried to sign in several times. Try again later',
  'signupConfirmationCode.smsSending': 'SMS sending in progress. Please wait',
  'helpSection.whyChooseUs': 'Why choose us?',
  'helpSection.forWho': 'For who?',
  'helpSection.forWho1': `Restaurants: your menu on QR code`,
  'helpSection.forWho2': `Hotels, AirBnb: your welcome booklet on QR code`,
  'helpSection.forWho3': `Professionals: your services on QR code`,
};
