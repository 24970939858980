import React from 'react';
import {useNavigate} from 'react-router-dom';

import {logout} from '../../redux/auth/logout';

import {useAppDispatch} from '../../app/hooks';

const Logout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(logout());
    navigate('/signin');
  }, []);

  return null;
};

export default Logout;
