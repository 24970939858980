import {useEffect} from 'react';
import {NotificationManager} from 'react-notifications';

import useCurrentBusinessId from '../../lib/hooks/use-current-business-id';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import Confirmation from '../../components/organisms/Confirmation';

import {FormattedMessage, useIntl} from '../../lib/intl';
import useAppLanguage from '../../lib/intl/hooks/use-app-language';
import {useMoveBusinessInformationSectionMutation} from '../../redux/businessInformationSectionsApi';

const MoveBusinessInformationSection = ({
  direction,
  open,
  section,
  onClose,
}: {
  direction: 'left' | 'right';
  open: boolean;
  section: BusinessInformationSection;
  onClose: () => void;
}) => {
  const businessId = useCurrentBusinessId();
  const [move, mutation] = useMoveBusinessInformationSectionMutation();
  const intl = useIntl();
  const lang = useAppLanguage();

  const directionLabel =
    direction === 'left'
      ? intl.formatMessage({
          id: 'moveBusinessInformationSection.left',
        })
      : intl.formatMessage({
          id: 'moveBusinessInformationSection.right',
        });

  useQueryInternalError(mutation);

  const label = `"${section.nameIntl[lang]}"`;

  useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success(
        intl.formatMessage({
          id: 'moveBusinessInformationSection.success',
          values: {
            label,
          },
        }),
      );
      onClose();
    }
  }, [mutation.isSuccess]);

  return (
    <Confirmation
      open={open}
      isLoading={mutation.isLoading}
      onClose={onClose}
      description={
        <FormattedMessage
          id="moveBusinessInformationSection.confirmation"
          values={{
            label,
            directionLabel,
          }}
        />
      }
      onConfirm={() =>
        move({
          id: section._id,
          business: businessId,
          direction,
        })
      }
    />
  );
};

export default MoveBusinessInformationSection;
