import {useEffect} from 'react';
import {NotificationManager} from 'react-notifications';

import useCurrentBusinessId from '../../lib/hooks/use-current-business-id';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import Confirmation from '../../components/organisms/Confirmation';

import {FormattedMessage, useIntl} from '../../lib/intl';
import useAppLanguage from '../../lib/intl/hooks/use-app-language';
import {useDeleteBusinessInformationSectionBlockMutation} from '../../redux/businessInformationSectionsApi';

const DeleteBusinessInformationSectionBlock = ({
  open,
  sectionBlock,
  onClose,
}: {
  open: boolean;
  sectionBlock: BusinessInformationSectionBlock;
  onClose: () => void;
}) => {
  const intl = useIntl();
  const lang = useAppLanguage();

  const businessId = useCurrentBusinessId();
  const [remove, mutation] = useDeleteBusinessInformationSectionBlockMutation();

  useQueryInternalError(mutation);

  useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success(
        intl.formatMessage({
          id: 'deleteBusinessInformationSectionBlock.messageSuccess',
        }),
      );
      onClose();
    }
  }, [mutation.isSuccess]);

  const label = sectionBlock.headingIntl?.[lang]
    ? `"${sectionBlock.headingIntl[lang]}"`
    : intl.formatMessage({
        id: 'deleteBusinessInformationSectionBlock.block',
      });

  return (
    <Confirmation
      open={open}
      isLoading={mutation.isLoading}
      onClose={onClose}
      description={
        <FormattedMessage
          id="deleteBusinessInformationSectionBlock.title"
          values={{name: label}}
        />
      }
      onConfirm={() =>
        remove({
          id: sectionBlock._id,
          business: businessId,
        })
      }
    />
  );
};

export default DeleteBusinessInformationSectionBlock;
