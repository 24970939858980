import {Outlet} from 'react-router-dom';

import {useAppSelector} from '../../app/hooks';
import LoadingOverlay from '../../components/molecules/LoadingOverlay';

const AuthRoute = () => {
  const authenticated = useAppSelector(state => state.auth.authenticated);

  if (authenticated === true) {
    return <Outlet />;
  }

  if (authenticated === undefined) {
    return <LoadingOverlay />;
  }

  return null;
};

export default AuthRoute;
