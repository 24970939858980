import {Helmet} from 'react-helmet';

import {
  Business,
  ChangeCircle,
  Delete,
  Image,
  Info,
  Logout,
  More,
  Payment,
  QrCode,
  Report,
} from '@mui/icons-material';
import {Breadcrumbs, Divider, Stack, Typography} from '@mui/material';

import Dialogable from '../../components/molecules/Dialogable';
import UserPage from '../../components/organisms/UserPage';

import {clearRTKApiCache} from '../../redux/auth/clearRTKApiCache';

import DownloadBusinessQRCode from '../../modals/DownloadBusinessQRCode/DownloadBusinessQRCode';

import {FormattedMessage, useIntl} from '../../lib/intl';
import DeleteAccount from '../../modals/DeleteAccount/DeleteAccount';
import useGetCurrentBusinessQuery from '../../redux/get-current-business';
import SettingsButton from './SettingsButton';

const Settings = () => {
  const query = useGetCurrentBusinessQuery();

  const business = query.data;
  const intl = useIntl();

  return (
    <UserPage
      breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">
          <Typography variant="h6" color="text.primary">
            <FormattedMessage id="settings.breadcrumbs" />
          </Typography>
        </Breadcrumbs>
      }>
      <Helmet>
        <title>{intl.formatMessage({id: 'settings.title'})}</title>
      </Helmet>
      <Stack spacing={1}>
        <SettingsButton
          to="/actions/profile/edit"
          startIcon={<Info />}
          variant="text">
          <FormattedMessage id="settings.accountInformation" />
        </SettingsButton>
        <Divider />
        <SettingsButton
          to="/actions/business/edit"
          startIcon={<Business />}
          variant="text">
          <FormattedMessage id="settings.updateBusinessInformation" />
        </SettingsButton>
        <Divider />
        {business && (
          <>
            <Dialogable
              dialog={({open, onClose}) => (
                <DownloadBusinessQRCode
                  business={business}
                  open={open}
                  onClose={onClose}
                />
              )}
              source={({onOpen}) => (
                <SettingsButton startIcon={<QrCode />} onClick={onOpen}>
                  <FormattedMessage id="settings.downloadQRCode" />
                </SettingsButton>
              )}
            />
            <Divider />
          </>
        )}
        <SettingsButton
          to="/actions/business/image/edit"
          startIcon={<Image />}
          variant="text">
          <FormattedMessage id="settings.updateBusinessLogo" />
        </SettingsButton>
        <Divider />
        <SettingsButton
          to="/extra-settings"
          startIcon={<More />}
          variant="text">
          <FormattedMessage id="settings.extraSettings" />
        </SettingsButton>
        <Divider />
        <SettingsButton
          onClick={() => clearRTKApiCache()}
          to="/my-businesses"
          startIcon={<ChangeCircle />}
          variant="text">
          <FormattedMessage id="settings.changeBusiness" />
        </SettingsButton>
        <Divider />
        <SettingsButton
          to="/settings/payments"
          startIcon={<Payment />}
          variant="text">
          <FormattedMessage id="settings.paymentAndInvoices" />
        </SettingsButton>
        <Divider />
        <SettingsButton
          to="/actions/report-issues"
          startIcon={<Report />}
          variant="text">
          <FormattedMessage id="settings.contactUs" />
        </SettingsButton>
        <Divider />
        <Dialogable
          source={({onOpen}) => (
            <SettingsButton
              onClick={onOpen}
              startIcon={<Delete />}
              variant="text">
              <FormattedMessage id="settings.deleteAccount" />
            </SettingsButton>
          )}
          dialog={({open, onClose}) => (
            <DeleteAccount open={open} onClose={onClose} />
          )}
        />
        <Divider />
        <SettingsButton to="/logout" startIcon={<Logout />} variant="text">
          <FormattedMessage id="settings.logout" />
        </SettingsButton>
      </Stack>
    </UserPage>
  );
};

export default Settings;
