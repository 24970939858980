import {ArrowBack} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {Button, IconButton, Stack, Typography} from '@mui/material';

import {Formik} from 'formik';

import FormikOTPInput from '../../components/atoms/formik/FormikOTPInput';
import VisitorPageContainer from '../../components/organisms/VisitorPageContainer';

import {FormattedMessage, useIntl} from '../../lib/intl';
import ResendCodeButton from './ResendCodeButton';

const ConfirmationCode = ({
  onBack,
  onSubmit,
  loading,
  onResend,
}: {
  loading: boolean;
  onBack: () => void;
  onSubmit: (values: {confirmationCode: string}) => void;
  onResend: () => void;
}) => {
  const intl = useIntl();
  return (
    <VisitorPageContainer>
      <IconButton
        onClick={onBack}
        color="primary"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}>
        <ArrowBack />
      </IconButton>
      <Formik
        initialValues={{
          confirmationCode: '',
        }}
        onSubmit={onSubmit}>
        {({handleSubmit, setFieldValue}) => (
          <Stack
            flex={1}
            justifyContent="center"
            direction="column"
            spacing={1}>
            <Stack spacing={2}>
              <Typography variant="h5" align="center">
                <FormattedMessage id="completeInformation.receivedCode" />
              </Typography>
              <FormikOTPInput
                disabled={loading}
                name="confirmationCode"
                label={intl.formatMessage({
                  id: 'completeInformation.confirmationCode',
                })}
              />
              <LoadingButton
                size="large"
                loading={loading}
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}>
                <FormattedMessage id="common.continue" />
              </LoadingButton>
            </Stack>
            <Stack spacing={1}>
              <ResendCodeButton
                onClick={() => {
                  setFieldValue('confirmationCode', '');
                  onResend();
                }}
              />
              <Button
                size="medium"
                variant="text"
                onClick={() => setFieldValue('confirmationCode', '')}>
                <FormattedMessage id="common.clear" />
              </Button>
            </Stack>
          </Stack>
        )}
      </Formik>
    </VisitorPageContainer>
  );
};

export default ConfirmationCode;
