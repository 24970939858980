import React from 'react';
import {LinkProps, NavLink as RouterLink} from 'react-router-dom';

import {Link as MuiLink, LinkProps as MuiLinkProps} from '@mui/material';
import {grey} from '@mui/material/colors';

const NavLink = React.forwardRef<HTMLAnchorElement, LinkProps & MuiLinkProps>(
  (props: MuiLinkProps & LinkProps, ref) => (
    <MuiLink
      ref={ref}
      sx={{
        color: grey['500'],
        '&, &:active': {
          textDecoration: 'none',
        },
        '&.active': {
          color: theme => `inherit!important`,
        },
      }}
      end
      component={RouterLink}
      {...props}
    />
  ),
);

export default NavLink;
