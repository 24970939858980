import {useState} from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate, useParams} from 'react-router-dom';

import {LoadingButton} from '@mui/lab';
import {Stack} from '@mui/material';

import {internalError} from '../../lib/helpers/internalError';
import useCurrentBusinessId from '../../lib/hooks/use-current-business-id';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';
import useUploadFile from '../../lib/hooks/useUploadFile';

import {Formik} from 'formik';
import * as Yup from 'yup';

import PageAction from '../../components/organisms/PageAction';

import FormikUpdateFileInputAspectRatio from '../../components/atoms/formik/FormikUpdateFileInputAspectioRatio';
import LoadingOverlay from '../../components/molecules/LoadingOverlay';
import {FormattedMessage, useIntl} from '../../lib/intl';
import useAppLanguage from '../../lib/intl/hooks/use-app-language';
import {
  useGetBusinessInformationSectionBlockByIdQuery,
  useUpdateBusinessInformationSectionBlockImageMutation,
} from '../../redux/businessInformationSectionsApi';

const validationSchema = Yup.object({
  image: Yup.string().required('validation.requiredField'),
});

const UpdateBusinessInformationSectionBlock = () => {
  const intl = useIntl();
  const {id} = useParams();
  const business = useCurrentBusinessId();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const lang = useAppLanguage();

  const {data: block, ...query} =
    useGetBusinessInformationSectionBlockByIdQuery({
      business,
      id: id as string,
    });
  const uploadFile = useUploadFile();

  const [update] = useUpdateBusinessInformationSectionBlockImageMutation();

  const handleUpdateImage = async ({
    image,
    aspectRatio,
  }: {
    image: any;
    aspectRatio: string;
  }) => {
    try {
      setLoading(true);
      const file = await uploadFile(image);
      await update({
        id: id as string,
        image: file._id,
        aspectRatio,
      });
      NotificationManager.success(
        intl.formatMessage({
          id: 'updateBusinessInformationSectionBlockImage.successMessage',
        }),
      );
      navigate(-1);
    } catch (err) {
      internalError(err);
    } finally {
      setLoading(false);
    }
  };

  useQueryInternalError(query);

  if (query.isLoading) {
    return <LoadingOverlay />;
  }

  const label = block?.headingIntl?.[lang]
    ? `"${block.headingIntl[lang]}"`
    : intl.formatMessage({
        id: 'updateBusinessInformationSectionBlockImage.block',
      });

  return (
    <PageAction
      title={intl.formatMessage({
        id: 'updateBusinessInformationSectionBlockImage.title',
        values: {label},
      })}>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={({image, aspectRatio}: any) =>
          handleUpdateImage({
            image,
            aspectRatio,
          })
        }
        initialValues={{
          image: '',
          aspectRatio: '16:9',
        }}>
        {({handleSubmit, values}) => (
          <Stack direction="column" spacing={2}>
            <FormikUpdateFileInputAspectRatio
              name="image"
              aspectRatioName="aspectRatio"
              maxSize={5}
              defaultValue={block?.image?.reference}
              defaultAspectRatio={block?.aspectRatio}
            />
            <Stack direction="column" spacing={1}>
              <LoadingButton
                onClick={loading ? undefined : () => handleSubmit()}
                loading={loading || query.isLoading}
                variant="contained"
                color="primary">
                <FormattedMessage id="common.finish" />
              </LoadingButton>
            </Stack>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default UpdateBusinessInformationSectionBlock;
