import React from 'react';
import {useNavigate} from 'react-router-dom';

import {ArrowBack} from '@mui/icons-material';
import {Box, Stack, Typography} from '@mui/material';

import RoundIconButton from '../atoms/RoundIconButton';

export const PageActionContext = React.createContext<{
  setActionTitle: (title: string) => void;
  actionTitle: string;
}>({
  setActionTitle: () => {},
  actionTitle: '',
});

const PageActionLayout = ({children}: {children: React.ReactNode}) => {
  const navigate = useNavigate();
  const [actionTitle, setActionTitle] = React.useState<string>('');

  return (
    <PageActionContext.Provider
      value={{
        setActionTitle,
        actionTitle,
      }}>
      <Box
        p={2}
        sx={{
          backgroundColor: 'white',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
        position="relative">
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
          sx={{
            position: 'absolute',
            top: theme => theme.spacing(2),
            left: theme => theme.spacing(1),
          }}>
          <RoundIconButton onClick={() => navigate(-1)} color="secondary">
            <ArrowBack fontSize="medium" />
          </RoundIconButton>
          <Typography variant="h5">{actionTitle}</Typography>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
          pt={8}>
          {children}
        </Box>
      </Box>
    </PageActionContext.Provider>
  );
};

export default PageActionLayout;
