export default {
  'common.actions': 'Actions',
  'common.finish': 'Terminer',
  'common.cancel': 'Annuler',
  'common.save': 'Sauvegarder',
  'common.clear': 'Effacer',
  'common.attention': 'Attention',
  'common.preview': 'Prévisualiser',
  'common.end': 'Fin',
  'common.validate': 'Valider',
  'common.continue': 'Continuer',
  'common.delete': 'Supprimer',
};
