export default {
  'formikGeoLocationPicker.yourAddress': 'Ihre Adresse',
  'formikGeoLocationPicker.modifyAddress': `Adresse ändern`,
  'formikGeoLocationPicker.chooseAddress': `Adresse auswählen`,
  'geoAddressPicker.missingCity': 'Fehlende Stadt',
  'geoAddressPicker.missingCountry': 'Fehlendes Land',
  'geoAddressPicker.missingPostalCode': 'Postleitzahl fehlt',
  'geoAddressPicker.missingAddress': 'Fehlende Adresse',
  'geoAddressPicker.currentPositionUnavailable':
    'Ihre Position konnte nicht abgerufen werden',
  'geoAddressPicker.chooseAddress': 'Wählen Sie eine Adresse',
  'geoAddressPicker.addressNotFound': `Keine Adresse gefunden`,
};
