import {useEffect} from 'react';
import {NotificationManager} from 'react-notifications';

import useCurrentBusinessId from '../../lib/hooks/use-current-business-id';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import Confirmation from '../../components/organisms/Confirmation';

import {FormattedMessage, useIntl} from '../../lib/intl';
import useAppLanguage from '../../lib/intl/hooks/use-app-language';
import {useDeleteBusinessInformationSectionMutation} from '../../redux/businessInformationSectionsApi';

const DeleteBusinessInformationSection = ({
  open,
  section,
  onClose,
}: {
  open: boolean;
  section: BusinessInformationSection;
  onClose: () => void;
}) => {
  const lang = useAppLanguage();

  const intl = useIntl();
  const businessId = useCurrentBusinessId();
  const [remove, mutation] = useDeleteBusinessInformationSectionMutation();

  useQueryInternalError(mutation);

  useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success(
        intl.formatMessage({
          id: 'deleteBusinessInformationSection.messageSuccess',
        }),
      );
      onClose();
    }
  }, [mutation.isSuccess]);

  return (
    <Confirmation
      open={open}
      isLoading={mutation.isLoading}
      onClose={onClose}
      description={
        <FormattedMessage
          id="deleteBusinessInformationSection.messageSuccess"
          values={{
            title: section.nameIntl[lang],
          }}
        />
      }
      onConfirm={() =>
        remove({
          id: section._id,
          business: businessId,
        })
      }
    />
  );
};

export default DeleteBusinessInformationSection;
