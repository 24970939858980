import React from 'react';

import {Box, MenuItem} from '@mui/material';

import useQueryInternalError from '../../../lib/hooks/useQueryInternalError';

import {useGetAllBusinessCategoriesQuery} from '../../../redux/businessApi';

import {useIntl} from '../../../lib/intl';
import useAppLanguage from '../../../lib/intl/hooks/use-app-language';
import FormikSelect from '../../atoms/formik/FormikSelect';
import LoadingOverlay from '../LoadingOverlay';

const FormikBusinessCategoryPicker = ({
  name,
  label,
}: {
  name: string;
  label?: React.ReactNode | string;
}) => {
  const lang = useAppLanguage();
  const intl = useIntl();

  label =
    label || intl.formatMessage({id: 'formikBusinessCategoryPicker.label'});
  const businessCategoriesQuery = useGetAllBusinessCategoriesQuery();

  const pickerOptions = React.useMemo(() => {
    if (businessCategoriesQuery.data) {
      return businessCategoriesQuery.data.map(o => ({
        value: o._id,
        label: o.nameIntl[lang],
      }));
    }

    return [];
  }, [businessCategoriesQuery.data]);

  useQueryInternalError(businessCategoriesQuery);

  return (
    <Box position="relative">
      <FormikSelect label={label} name={name}>
        {pickerOptions.map((option: any) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
        {businessCategoriesQuery.isLoading && <LoadingOverlay />}
      </FormikSelect>
    </Box>
  );
};

export default FormikBusinessCategoryPicker;
