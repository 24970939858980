import {createApi} from '@reduxjs/toolkit/query/react';
import {IntlContent} from '../lib/intl/types';
import {authBaseQuery} from './apiBaseQuery';

const commonApi = createApi({
  reducerPath: 'commonApi',
  baseQuery: authBaseQuery,
  tagTypes: ['IntlContent'],
  endpoints(build) {
    return {
      translateIntlContent: build.mutation<
        IntlContent,
        {
          text: string;
          baseLang: string;
          format: string;
        }
      >({
        query: ({text, baseLang, format}) => ({
          url: `/translateIntlContent`,
          method: 'post',
          data: {
            content: text,
            baseLang,
            format,
          },
        }),
        invalidatesTags: (result, error, {text, baseLang}) =>
          result && !error
            ? [
                {
                  type: 'IntlContent',
                  id: `${baseLang}:${text}`,
                },
              ]
            : [],
      }),
    };
  },
});

export const {useTranslateIntlContentMutation} = commonApi;

export default commonApi;
