import {Stack} from '@mui/material';
import {Formik} from 'formik';
import {useEffect} from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate} from 'react-router-dom';
import FormikTextField from '../../components/atoms/formik/FormikTextField/FormikTextField';
import SaveButton from '../../components/atoms/SaveButton';
import PageAction from '../../components/organisms/PageAction';
import useCurrentBusinessId from '../../lib/hooks/use-current-business-id';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';
import {FormattedMessage, useIntl} from '../../lib/intl';
import {
  useGetBusinessSiteHomePageNameQuery,
  useUpdateBusinessSiteHomePageNameMutation,
} from '../../redux/businessInformationSectionsApi';

const BusinessPreferences = () => {
  const businessId = useCurrentBusinessId();
  const navigate = useNavigate();
  const intl = useIntl();

  const {data: siteHomePageNameIntl, ...query} =
    useGetBusinessSiteHomePageNameQuery(businessId as string);
  const [updateBusinessSiteHomePageName, mutation] =
    useUpdateBusinessSiteHomePageNameMutation();

  useQueryInternalError(query);
  useQueryInternalError(mutation);

  useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success(`Le nom de la page a été bien modifié`);
      navigate(-1);
    }
  }, [mutation.isSuccess]);

  return (
    <PageAction title={intl.formatMessage({id: 'businessPreferences.title'})}>
      <Formik
        enableReinitialize
        initialValues={{
          siteHomePageNameIntl: siteHomePageNameIntl || {},
        }}
        onSubmit={({siteHomePageNameIntl}) =>
          updateBusinessSiteHomePageName({
            business: businessId as string,
            siteHomePageNameIntl,
          })
        }>
        {({handleSubmit}) => (
          <Stack spacing={1}>
            <FormikTextField
              name="siteHomePageName"
              label={intl.formatMessage({
                id: 'businessPreferences.siteHomePageName',
              })}
              enableIntl
            />
            <SaveButton
              loading={mutation.isLoading}
              onClick={mutation.isLoading ? undefined : () => handleSubmit()}>
              <FormattedMessage id="common.save" />
            </SaveButton>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default BusinessPreferences;
