import {
  Box,
  Button,
  ButtonBase,
  Divider,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

import FirebaseStorageImage from '../../lib/firebase-image/components/FirebaseStorageImage';

import getLineBreakHTMLProp from '../../lib/helpers/getLineBreakHTMLProp';
import {FormattedMessage} from '../../lib/intl';
import useAppLanguage from '../../lib/intl/hooks/use-app-language';
import AspectRatioImage from '../atoms/AspectRatioImage';
import Link from '../atoms/Link';

const SiteTemplateOption = ({
  business,
  selected,
  onClick,
}: {
  business: PopulatedBusiness;
  selected?: boolean;
  onClick?: () => void;
}) => {
  const lang = useAppLanguage();

  return (
    <ButtonBase onClick={onClick}>
      <Paper
        key={business._id}
        sx={{
          width: '100%',
          backgroundColor: selected ? '#eaeaea' : 'background.paper',
        }}
        variant="outlined"
        square>
        <Stack spacing={2} py={2} pl={2} pr={2}>
          <Stack spacing={2} direction="row">
            <FirebaseStorageImage
              reference={business.image.reference}
              Component={AspectRatioImage}
              ratio="1:1"
              sx={{
                width: 50,
                height: 50,
                borderRadius: 25,
              }}
            />
            <Stack direction="column" spacing={2}>
              <Stack direction="column" spacing={1}>
                <Typography align="left" variant="body1">
                  {business.name}
                </Typography>
                <Typography align="left" variant="body2">
                  {business.category.nameIntl[lang]}
                </Typography>
                <Box
                  sx={{
                    width: '100%',
                  }}>
                  {business.descriptionIntl?.[lang] && (
                    <Typography
                      align="left"
                      variant="body2"
                      {...getLineBreakHTMLProp(
                        business.descriptionIntl[lang].substring(0, 150) +
                          `${
                            business.descriptionIntl[lang].length > 150
                              ? '...'
                              : ''
                          }`,
                      )}
                    />
                  )}
                </Box>
              </Stack>
            </Stack>
          </Stack>
          <Divider />
          <Link
            sx={{
              alignSelf: 'flex-end',
            }}
            to={`/site-preview/${business._id}`}
            target="_blank"
            onClick={evt => {
              evt.stopPropagation();
            }}>
            <Button variant="contained" color="secondary" size="small">
              <FormattedMessage id="sideTemplateOption.preview" />
            </Button>
          </Link>
        </Stack>
      </Paper>
    </ButtonBase>
  );
};

export default SiteTemplateOption;
