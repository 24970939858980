export default {
  'createBusiness.title': 'Créer un nouvel établissement',
  'createBusiness.donwloadImage': 'Télécharger une image',
  'createBusiness.name': 'Nom du établissement',
  'createBusiness.description': 'Description',
  'createBusiness.category': `Domaine d'activité`,
  'createBusiness.website': 'Site web',
  'createBusiness.successMessage': `L'établissement a été créé avec succès`,
  'createBusiness.status': 'Actif',
  'createBusiness.disableMessage':
    "Attention: la désactivation de cet établissement entrainera la suspension de l'abonnement et l'invalidation du lien du site client.",
};
