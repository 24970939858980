import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {Button, Stack, Typography} from '@mui/material';

import {
  removeBusinessId,
  setDefaultBusinessId,
} from '../../lib/storage/business-id';

import Link from '../../components/atoms/Link';
import LoadingOverlay from '../../components/molecules/LoadingOverlay';
import VisitorPageContainer from '../../components/organisms/VisitorPageContainer';

import {LOGO_WITH_TEXT} from '../../assets/images';

import {useGetUserOwnedBusinessesQuery} from '../../redux/businessApi';

import {Add} from '@mui/icons-material';
import BusinessOption from '../../components/atoms/BusinessOption';
import {FormattedMessage} from '../../lib/intl';

const UserBusinesses = () => {
  const query = useGetUserOwnedBusinessesQuery();
  const navigate = useNavigate();

  const handleSubmit = (businessId: string) => {
    setDefaultBusinessId(businessId);
    navigate('/');
  };

  useEffect(() => {
    removeBusinessId();
  }, []);

  useEffect(() => {
    if (query.data?.length === 0) {
      navigate('/actions/business/create');
    }
  }, [query.data?.length]);

  if (query.isLoading) {
    return <LoadingOverlay />;
  }

  if (query.data?.length === 0) {
    return null;
  }

  return (
    <VisitorPageContainer>
      <Stack
        direction="column"
        spacing={2}
        flex={1}
        py={4}
        alignItems="stretch">
        <img
          src={LOGO_WITH_TEXT}
          style={{
            width: '200px',
            height: '200px',
            alignSelf: 'center',
          }}
        />
        <Typography variant="h5" align="center">
          <FormattedMessage id="userBusinesses.selectBusiness" />
        </Typography>
        {query.data?.map(business => (
          <BusinessOption
            key={business._id}
            business={business}
            onClick={() => handleSubmit(business._id)}
          />
        ))}
        {query.data?.length === 0 && (
          <Typography variant="body1" align="center">
            <FormattedMessage id="userBusiness.noBusiness" />
          </Typography>
        )}
        <Link to="/logout">
          <Button fullWidth variant="text" color="primary">
            <FormattedMessage id="userBusinesses.logout" />
          </Button>
        </Link>
      </Stack>
      {query.isLoading && <LoadingOverlay />}
      <Link to="/actions/business/create">
        <Button
          variant="contained"
          color="primary"
          size="medium"
          startIcon={<Add />}
          sx={{
            position: 'fixed',
            bottom: theme => theme.spacing(2),
            right: theme => theme.spacing(2),
            textTransform: 'capitalize',
          }}>
          <FormattedMessage id="userBusiness.business" />
        </Button>
      </Link>
    </VisitorPageContainer>
  );
};

export default UserBusinesses;
