import {useEffect} from 'react';
import {useQueryParam} from 'use-query-params';
import BusinessSiteComponent from '../../components/organisms/BusinessSite';
import BusinessSiteImportModal from '../../components/organisms/BusinessSiteImportModal';
import useCurrentBusinessId from '../../lib/hooks/use-current-business-id';
import {useGetBusinessInformationSectionsQuery} from '../../redux/businessInformationSectionsApi';

const BusinessSite = () => {
  const businessId = useCurrentBusinessId();
  const [show_import_modal, setShowImportModal] = useQueryParam<
    boolean | undefined
  >('show_import_modal');

  const query = useGetBusinessInformationSectionsQuery(businessId);

  useEffect(() => {
    if (query.isSuccess && query.data.length === 0) {
      setShowImportModal(true);
    }
  }, [query.isSuccess, query.data?.length, setShowImportModal]);

  return (
    <>
      <BusinessSiteComponent businessId={businessId} />
      <BusinessSiteImportModal
        open={!!show_import_modal}
        onClose={() => setShowImportModal(undefined)}
      />
    </>
  );
};

export default BusinessSite;
