export default {
  'userInfo.newUser': 'Neuer Benutzer',
  'userInfo.lastName': 'Name',
  'userInfo.firstName': 'Vorname',
  'businessSite.exit': 'Beenden',
  'businessSite.addSubsection': 'Unterabschnitt',
  'businessSite.preview': 'Vorschau',
  'businessSite.help1': 'Klicken Sie auf die Schaltfläche "',
  'businessSite.help2': `Unterabschnitt unten rechts, um einen Unterabschnitt hinzuzufügen`,
  'businessSite.importSiteFrom': 'Site importieren von:',
  'businessSite.importSiteFrom.similarTemplates': 'Ähnliche Vorlagen',
  'businessSite.importSiteFrom.yourBusinesses': 'Ihre Betriebe',
  'businessSite.importedSuccessfully': 'Die Site wurde erfolgreich importiert',
  'businessSite.noOtherBusinesses': `Sie haben keine anderen Standorte. Bitte erstellen Sie eine, um fortzufahren.`,
  'businessSite.noModelAvailable': 'Derzeit sind keine Modelle verfügbar',
  'businessSite.confirmationText': `Diese Aktion importiert alle Bereiche der ausgewählten Einrichtung in Ihre aktuelle Einrichtung. Vorhandene Abschnitte werden überschrieben. Möchten Sie fortfahren?`,
};
