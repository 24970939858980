export default {
  'createBusiness.title': 'Crear un nuevo negocio',
  'createBusiness.donwloadImage': 'Subir una imagen',
  'createBusiness.name': 'Nombre del establecimiento',
  'createBusiness.description': 'Descripción',
  'createBusiness.category': `Área de negocio`,
  'createBusiness.website': 'Sitio web',
  'createBusiness.successMessage': `La empresa se creó correctamente`,
  'createBusiness.status': 'Activo',
  'createBusiness.disableMessage':
    'Atención: La desactivación de este establecimiento dará lugar a la suspensión de la suscripción y a la invalidación del enlace del sitio web del cliente.',
};
