import {removeBusinessId} from '../../lib/storage/business-id';

import {createAsyncThunk} from '@reduxjs/toolkit';

import authService from '../../lib/auth/authService';
import {loggedOut} from './authSlice';
import {clearRTKApiCache} from './clearRTKApiCache';

export const logout = createAsyncThunk('logout', async (_, {dispatch}) => {
  removeBusinessId();
  clearRTKApiCache();
  authService.logout();
  dispatch(loggedOut());
});
