export default {
  'common.actions': 'Acciones',
  'common.finish': 'Finalizar',
  'common.cancel': 'Cancelar',
  'common.save': 'Guardar',
  'common.clear': 'Borrar',
  'common.attention': 'Atención',
  'common.preview': 'Vista previa',
  'common.end': 'Fin',
  'common.validate': 'Validar',
  'common.continue': 'Continuar',
  'common.delete': 'Eliminar',
  'validation.fieldRequired': 'Campo requerido',
  'validation.inValidEmail': 'Correo electrónico no válido',
  'validation.tryAnotherAddress': 'Ingrese o intente otra dirección',
  'validation.inValidUrl':
    'La URL no es válida. Ingrese la URL con http(s)://, por ejemplo: https://www.fluxence.com',
  'validation.requiredField': 'Campo requerido',
  'validation.emailUsed': 'Correo electrónico ya utilizado',
  'validation.phoneUsed': 'Número de teléfono ya utilizado',
  'validation.invalidPhoneNumber':
    'Número de teléfono no válido (Ej: +33611222333, +12481237654, +34600000000)',
};
