export default {
  'settings.breadcrumbs': 'Einstellungen',
  'settings.title': 'Einstellungen',
  'settings.accountInformation': 'Kontoinformationen',
  'settings.updateBusinessInformation': 'Meine Betriebsinformationen ändern',
  'settings.downloadQRCode': 'QR-Code herunterladen',
  'settings.updateBusinessLogo': 'Mein Firmenlogo ändern',
  'settings.extraSettings': 'Zusätzliche Einstellungen',
  'settings.changeBusiness': `Einrichtung ändern`,
  'settings.contactUs': 'Kontaktieren Sie uns',
  'settings.paymentAndInvoices': 'Zahlungen und Rechnungen',
  'settings.deleteAccount': 'Mein Konto löschen',
  'settings.logout': 'Abmelden',
};
