import {useEffect} from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate} from 'react-router-dom';

import {Stack, Typography} from '@mui/material';

import useCurrentBusinessId from '../../lib/hooks/use-current-business-id';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import {Formik} from 'formik';
import * as Yup from 'yup';

import FormikTextField from '../../components/atoms/formik/FormikTextField/FormikTextField';
import PageAction from '../../components/organisms/PageAction';

import SaveButton from '../../components/atoms/SaveButton';
import {FormattedMessage, useIntl} from '../../lib/intl';
import useAppLanguage from '../../lib/intl/hooks/use-app-language';
import {useCreateBusinessInformationSectionMutation} from '../../redux/businessInformationSectionsApi';

const validationSchema = Yup.object({
  nameIntl: Yup.object().intlRequired('validation.requiredField'),
});

const CreateBusinessInformationSection = () => {
  const business = useCurrentBusinessId();
  const navigate = useNavigate();
  const intl = useIntl();
  const lang = useAppLanguage();

  const [create, mutation] = useCreateBusinessInformationSectionMutation();

  useQueryInternalError(mutation);

  useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success(
        intl.formatMessage({
          id: 'createBusinessInformationSection.messageSuccess',
          values: {name: mutation.data.nameIntl[lang]},
        }),
      );
      navigate('/?section=' + mutation.data._id);
    }
  }, [mutation.isSuccess, mutation.data]);

  return (
    <PageAction
      title={intl.formatMessage({
        id: 'createBusinessInformationSection.title',
      })}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          nameIntl: {},
        }}
        onSubmit={({nameIntl}) =>
          create({
            nameIntl,
            business,
          })
        }>
        {({handleSubmit}) => (
          <Stack spacing={1} direction="column">
            <FormikTextField
              label={
                <FormattedMessage id="createBusinessInformationSection.name" />
              }
              name="name"
              enableIntl
              automaticTranslation
            />
            <Typography variant="body2" color="grey.600">
              <ul>
                <li>
                  <FormattedMessage id="createBusinessInformationSection.example1" />
                </li>
                <li>
                  <FormattedMessage id="createBusinessInformationSection.example2" />
                </li>
              </ul>
            </Typography>
            <SaveButton
              sx={{
                position: 'fixed',
                bottom: theme => theme.spacing(0.5),
                left: theme => theme.spacing(0.5),
                right: theme => theme.spacing(0.5),
                margin: 'auto',
              }}
              loading={mutation.isLoading}
              onClick={mutation.isLoading ? undefined : () => handleSubmit()}>
              <FormattedMessage id="common.save" />
            </SaveButton>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default CreateBusinessInformationSection;
