import React, {useRef} from 'react';

import {createSlice} from '@reduxjs/toolkit';
import {generate} from 'shortid';

import {useAppDispatch} from '../app/hooks';

const initialState: {
  loaders: {
    [key: string]: boolean;
  };
} = {
  loaders: {},
};

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    loadingChanged(state, val) {
      state.loaders = {
        ...state.loaders,
        ...val.payload,
      };
    },
  },
});

export const {loadingChanged} = loaderSlice.actions;

export const loaderReducer = loaderSlice.reducer;

export const useLoadingEffect = (val: boolean) => {
  const dispatch = useAppDispatch();
  const refId = useRef(generate());

  React.useEffect(() => {
    const id = refId.current;

    dispatch(
      loadingChanged({
        [id]: val,
      }),
    );

    return () => {
      dispatch(
        loadingChanged({
          [id]: false,
        }),
      );
    };
  }, [val]);
};

export default loaderSlice;
