import {useEffect} from 'react';
import {useAppDispatch} from '../../app/hooks';
import Confirmation from '../../components/organisms/Confirmation';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';
import {FormattedMessage} from '../../lib/intl';
import {logout} from '../../redux/auth/logout';
import {useDeleteAccountMutation} from '../../redux/authApi';

const DeleteAccount = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [deleteAccount, mutation] = useDeleteAccountMutation();

  useQueryInternalError(mutation);

  useEffect(() => {
    if (mutation.isSuccess) {
      onClose();
      dispatch(logout());
    }
  }, [mutation.isSuccess]);

  return (
    <Confirmation
      open={open}
      isLoading={mutation.isLoading}
      onClose={onClose}
      onConfirm={() => deleteAccount()}
      description={<FormattedMessage id="deleteAccount.description" />}
      yesProps={{
        children: <FormattedMessage id="common.delete" />,
        color: 'error',
      }}
      noProps={{
        children: <FormattedMessage id="common.cancel" />,
        color: 'primary',
        variant: 'contained',
      }}
    />
  );
};

export default DeleteAccount;
