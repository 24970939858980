import * as yup from 'yup';
import {IntlKeys} from '../intl';

yup.addMethod(yup.number, 'radiusValid', function (message: string) {
  return this.required('validation.fieldRequired')
    .min(1, 'validation.invalidRadiusMin')
    .max(100, 'validation.invalidRadiusMax');
});

declare module 'yup' {
  interface NumberSchema {
    radiusValid(): NumberSchema;

    min(value: number, message: IntlKeys): NumberSchema;

    max(value: number, message: IntlKeys): NumberSchema;
  }
}
