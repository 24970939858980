import {useCallback} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';

import {useAppDispatch} from '../../app/hooks';
import Confirmation from '../../components/organisms/Confirmation';
import {logout} from '../../redux/auth/logout';
import {useGetAuthUserQuery} from '../../redux/authApi';
import {useGetUserOwnedBusinessesQuery} from '../../redux/businessApi';
import {
  useCreateSubscriptionMutation,
  useGetSubscriptionByUserQuery,
} from '../../redux/paymentApi';
import {FormattedMessage} from '../intl';

const PaymentRoute = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userQuery = useGetAuthUserQuery();
  const businessesQuery = useGetUserOwnedBusinessesQuery();
  const enabledBusinesses = businessesQuery.data?.filter(b => b.enabled !== false) || [];
  const subscriptionQuery = useGetSubscriptionByUserQuery(
    {userId: userQuery.data?._id},
    {
      skip: !userQuery.data?._id,
      refetchOnFocus: true,
    },
  );
  const [create, mutation] = useCreateSubscriptionMutation();

  const createSubscription = useCallback(() => {
    if (businessesQuery.data) {
      create({
        userId: userQuery.data?._id,
        trialDays: businessesQuery.data[0].category.b2bTrialDays,
        plan: businessesQuery.data[0].category.b2bPriceIdentifier,
      });
    }
  }, [businessesQuery.data, create, userQuery.data?._id]);

  const leaveApp = useCallback(() => {
    dispatch(logout());
    navigate('/signin');
  }, [dispatch, navigate]);

  if (
    userQuery.data?.billableAccount &&
    subscriptionQuery.data?.info.clientSecret &&
    subscriptionQuery.data?.info.paymentIntentStatus !== 'succeeded'
  ) {
    navigate(`/checkouts/${subscriptionQuery.data?.info.clientSecret}`);
  }

  if (
    userQuery.data?.billableAccount &&
    subscriptionQuery.isSuccess &&
    !subscriptionQuery.data &&
    enabledBusinesses?.length
  ) {
    return (
      <Confirmation
        open={true}
        isLoading={mutation.isLoading}
        onClose={leaveApp}
        onConfirm={createSubscription}
        description={<FormattedMessage id="payments.message" />}
        yesProps={{
          children: <FormattedMessage id="common.continue" />,
          color: 'primary',
          variant: 'contained',
        }}
        noProps={{
          children: <FormattedMessage id="common.cancel" />,
          color: 'primary',
        }}
      />
    );
  }

  return <Outlet />;
};

export default PaymentRoute;
