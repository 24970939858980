import {useParams} from 'react-router-dom';
import BusinessPreview from '../../components/organisms/BusinessPreview';

const BusinessSitePreview = () => {
  const {id: businessId} = useParams<{id: string}>();

  return (
    <BusinessPreview
      businessId={businessId as string}
      quitPreviewVisible={false}
    />
  );
};

export default BusinessSitePreview;
