import React from 'react';

import {Stack} from '@mui/material';

const VisitorPageContainer = ({
  children,
}: {
  children: React.ReactNode[] | React.ReactNode;
}) => (
  <Stack position="relative" px={2} flex={1} alignItems="stretch">
    {children}
  </Stack>
);

export default VisitorPageContainer;
