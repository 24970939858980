import {Breadcrumbs, Stack, Typography} from '@mui/material';
import UserPage from '../../components/organisms/UserPage';
import {FormattedMessage, useIntl} from '../../lib/intl';
import useGetCurrentBusinessQuery from '../../redux/get-current-business';
import SettingLine from './Settingline';

const ExtraSettings = () => {
  const intl = useIntl();
  const {data: business} = useGetCurrentBusinessQuery();

  return (
    <UserPage
      breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">
          <Typography variant="h6" color="text.primary">
            <FormattedMessage id="extraSettings.title" />
          </Typography>
        </Breadcrumbs>
      }>
      <Stack spacing={2} pl={1}>
        <SettingLine
          editable={true}
          to="/actions/generated-qr-code-stats/edit"
          title={intl.formatMessage({id: 'extraSettings.qrCodePrinted'})}
          value={intl.formatMessage({
            id: 'extraSettings.qrCodePrintedValue',
            values: {qrCodeQuantity: business?.qrCodeQuantity || 0},
          })}
          helperText={intl.formatMessage({
            id: 'extraSettings.qrCodePrintedHelper',
          })}
        />
      </Stack>
    </UserPage>
  );
};

export default ExtraSettings;
