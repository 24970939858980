export default {
  'validation.fieldRequired': 'Champs requis',
  'validation.inValidEmail': 'Email invalide',
  'validation.tryAnotherAddress': 'Entrez ou réssayer une autre adresse',
  'validation.inValidUrl':
    "L'URL est invalide. Saisir l'URL avec http(s)://, par exemple: https://www.fluxence.com",
  'validation.requiredField': 'Champs obligatoire',
  'validation.emailUsed': 'Email déjà utilisé',
  'validation.phoneUsed': 'Numéro de téléphone déjà utilisé',
  'validation.invalidPhoneNumber':
    'Numéro téléphone invalide (Ex: +33611222333, +12481237654, +34600000000)',
  'validation.invalidRadiusMin': 'Val minimum est 1',
  'validation.invalidRadiusMax': 'Val maximum est 100',
};
