import {ContentCopyRounded, Download, OpenInNew} from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import config from '../../config.json';
import useGetAuthUserId from '../../lib/hooks/useGetAuthUserId';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';
import {FormattedMessage} from '../../lib/intl';
import {useLazyGetBusinessQRCodePDFQuery} from '../../redux/businessApi';

// import T0 from '../../assets/images/pdf-template-0.png';
// import T1 from '../../assets/images/pdf-template-1.png';
// import T2 from '../../assets/images/pdf-template-2.png';

const DownloadBusinessQRCode = ({
  business,
  open,
  onClose,
}: {
  business: Business;
  open: boolean;
  onClose: () => void;
}) => {
  const [getBusinessQRCodePDF, result] = useLazyGetBusinessQRCodePDFQuery();
  const authUserId = useGetAuthUserId();
  // const [template, setTemplate] = React.useState('basic');

  useQueryInternalError(result);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent sx={{position: 'relative'}}>
        {result.isFetching && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              position: 'absolute',
              inset: 0,
              backgroundColor: 'rgba(0, 0, 0, .45)',
            }}>
            <CircularProgress />
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            flex: 1,
            width: '18vw',
          }}>
          {/* <img
            src={T0}
            alt="template"
            onClick={() => setTemplate('basic')}
            width="20%"
            style={{
              cursor: 'pointer',
              borderRadius: 3,
              border: template === 'basic' ? '3px solid #4CAF50' : 0,
            }}
          /> */}
          {/*<img*/}
          {/*  src={T1}*/}
          {/*  alt="template"*/}
          {/*  onClick={() => setTemplate('')}*/}
          {/*  width="20%"*/}
          {/*  style={{*/}
          {/*    cursor: 'pointer',*/}
          {/*    borderRadius: 3,*/}
          {/*    border: template === '' ? '3px solid #4CAF50' : 0,*/}
          {/*  }}*/}
          {/*/>*/}
          {/*<img*/}
          {/*  src={T2}*/}
          {/*  alt="template"*/}
          {/*  onClick={() => setTemplate('t2')}*/}
          {/*  width="20%"*/}
          {/*  style={{*/}
          {/*    cursor: 'pointer',*/}
          {/*    borderRadius: 3,*/}
          {/*    border: template === 't2' ? '3px solid #4CAF50' : 0,*/}
          {/*  }}*/}
          {/*/>*/}
        </Box>

        <MenuList>
          <MenuItem
            onClick={() =>
              getBusinessQRCodePDF({
                id: business._id,
                format: 'A4',
                admin: authUserId || '',
                template: 'basic',
              })
            }>
            <ListItemIcon>
              <Download />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id="downloadBusinessQRCode.downloadQRCode" />
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() =>
              window.open(
                business.enabled !== false
                  ? `${config.b2cURL}/business-space/${business._id}/site`
                  : 'https://www.fluxence.com',
                '_blank',
              )
            }>
            <ListItemIcon>
              <OpenInNew />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id="downloadBusinessQRCode.goToWebsite" />
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() =>
              navigator.clipboard.writeText(
                business.enabled !== false
                  ? `${config.b2cURL}/business-space/${business._id}/site`
                  : 'https://www.fluxence.com',
              )
            }>
            <ListItemIcon>
              <ContentCopyRounded />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id="downloadBusinessQRCode.copyLink" />
            </ListItemText>
          </MenuItem>
          {/* <MenuItem
            onClick={() =>
              getBusinessQRCodePDF({
                id: business._id,
                format: 'A4',
                admin: authUserId || '',
                template,
              })
            }>
            <ListItemIcon><Download /></ListItemIcon>
            <ListItemText>Format A4 210mm × 297mm</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() =>
              getBusinessQRCodePDF({
                id: business._id,
                format: 'A5',
                admin: authUserId || '',
                template,
              })
            }>
            <ListItemIcon><Download /></ListItemIcon>
            <ListItemText>Format A5 148mm × 210mm</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() =>
              getBusinessQRCodePDF({
                id: business._id,
                format: 'A6',
                admin: authUserId || '',
                template,
              })
            }>
            <ListItemIcon><Download /></ListItemIcon>
            <ListItemText>Format A6 105mm × 148mm</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() =>
              getBusinessQRCodePDF({
                id: business._id,
                format: 'A7',
                admin: authUserId || '',
                template,
              })
            }>
            <ListItemIcon><Download /></ListItemIcon>
            <ListItemText>Format A7: 74mm × 105mm</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() =>
              getBusinessQRCodePDF({
                id: business._id,
                format: 'A8',
                admin: authUserId || '',
                template,
              })
            }>
            <ListItemIcon><Download /></ListItemIcon>
            <ListItemText>Format A8: 52mm × 74mm</ListItemText>
          </MenuItem> */}
        </MenuList>
      </DialogContent>
    </Dialog>
  );
};

export default DownloadBusinessQRCode;
