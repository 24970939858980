import React, {useCallback, useState} from 'react';

import {Stack, TextField, TextFieldProps} from '@mui/material';

import {useField} from 'formik';
import {
  LanguageCode,
  supportedLanguagesCodes,
  useIntl,
} from '../../../../lib/intl';
import IntlContentTranslationButton from '../../../../lib/intl/components/IntlContentTranslationButton';
import {IntlContent} from '../../../../lib/intl/types';
import LanguagesTabs from '../../LanguagesTabs';

const FormikTextField = ({
  name,
  nameIntl = name + 'Intl',
  enableIntl = false,
  label,
  automaticTranslation = false,
  ...props
}: {
  name: string;
  nameIntl?: string;
  enableIntl?: boolean;
  automaticTranslation?: boolean;
  label: string | React.ReactNode;
} & TextFieldProps) => {
  const intl = useIntl();
  const [shouldTranslate, setShouldTranslate] = useState<boolean>(false);
  const [field, meta, helpers] = useField(name);
  const [fieldIntl, metaIntl, helpersIntl] = useField(nameIntl);
  const hasError = enableIntl
    ? !!(metaIntl.error && metaIntl.touched)
    : !!(meta.error && meta.touched);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    supportedLanguagesCodes[0],
  );

  const handleTranslateComplete = useCallback(
    (translatedContent: IntlContent) => {
      setShouldTranslate(false);
      helpersIntl.setValue({
        ...translatedContent,
        [selectedLanguage]: fieldIntl.value?.[selectedLanguage],
      });
    },
    [fieldIntl.value, selectedLanguage],
  );

  if (!enableIntl) {
    return (
      <TextField
        label={label}
        value={field.value}
        onChange={e => {
          helpers.setValue(e.target.value);
        }}
        onBlur={() => {
          helpers.setTouched(true);
        }}
        error={hasError}
        helperText={
          meta.touched && meta.error
            ? intl.formatMessage({id: meta.error as any})
            : undefined
        }
        {...props}
      />
    );
  }

  return (
    <Stack direction="column" spacing={1}>
      <LanguagesTabs
        onLangChange={setSelectedLanguage}
        selectedLang={selectedLanguage}
      />
      <TextField
        label={label}
        fullWidth
        value={'' + (fieldIntl.value?.[selectedLanguage] ?? '')}
        onChange={event => {
          const {value} = event.target;

          helpersIntl.setValue({
            ...fieldIntl.value,
            [selectedLanguage]: value,
          });

          setShouldTranslate(true);
        }}
        onBlur={() => {
          helpersIntl.setTouched(true);
        }}
        error={!!(metaIntl.touched && metaIntl.error)}
        helperText={
          metaIntl.touched && metaIntl.error
            ? (metaIntl.error as string)
            : undefined
        }
        {...props}
      />
      {automaticTranslation && (
        <IntlContentTranslationButton
          baseLang={selectedLanguage as LanguageCode}
          sx={{
            justifyContent: 'flex-end',
          }}
          text={fieldIntl.value?.[selectedLanguage]}
          onTranslateComplete={handleTranslateComplete}
          shouldTranslate={shouldTranslate}
        />
      )}
    </Stack>
  );
};

export default FormikTextField;
