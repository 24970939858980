import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate} from 'react-router-dom';

import {Stack} from '@mui/material';

import FirebaseStorageImage from '../../lib/firebase-image/components/FirebaseStorageImage';
import {internalError} from '../../lib/helpers/internalError';
import useUploadFile from '../../lib/hooks/useUploadFile';

import {Formik} from 'formik';
import * as yup from 'yup';

import AspectRatioImage from '../../components/atoms/AspectRatioImage';
import FormikFileInput from '../../components/atoms/formik/FormikFileInput';
import PageAction from '../../components/organisms/PageAction';

import SaveButton from '../../components/atoms/SaveButton';
import useCurrentBusinessId from '../../lib/hooks/use-current-business-id';
import {FormattedMessage, useIntl} from '../../lib/intl';
import {useUpdateBusinessImageMutation} from '../../redux/businessApi';
import useGetCurrentBusinessQuery from '../../redux/get-current-business';

const validationSchema = yup.object({
  image: yup.string().required('validation.fieldRequired'),
});

const EditBusinessImage = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const businessId = useCurrentBusinessId();
  const [updateBusiness] = useUpdateBusinessImageMutation();
  const businessQuery = useGetCurrentBusinessQuery();
  const uploadFile = useUploadFile();
  const [loading, setLoading] = React.useState(false);

  const business = businessQuery.data;

  const handleUpdateImage = async ({image, id}: {id: string; image: any}) => {
    try {
      setLoading(true);
      const file = await uploadFile(image);
      await updateBusiness({
        id: businessId,
        image: file._id,
      });
      NotificationManager.success(
        intl.formatMessage({id: 'editBusinessImage.successMessage'}),
      );
      navigate(-1);
    } catch (err) {
      internalError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageAction title={intl.formatMessage({id: 'editBusinessImage.title'})}>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={({image}: any) =>
          handleUpdateImage({
            id: business?._id || '',
            image,
          })
        }
        initialValues={{
          image: '',
        }}>
        {({handleSubmit}) => (
          <Stack direction="column" spacing={2}>
            {business?.image?.reference && (
              <FirebaseStorageImage
                Component={AspectRatioImage}
                reference={business?.image?.reference as string}
                ratio="1:1"
              />
            )}
            <FormikFileInput
              name="image"
              maxSize={5}
              label={intl.formatMessage({id: 'editBusinessImage.uploadLogo'})}
            />
            <SaveButton
              onClick={loading ? undefined : () => handleSubmit()}
              loading={loading || businessQuery.isLoading}>
              <FormattedMessage id="common.finish" />
            </SaveButton>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default EditBusinessImage;
