export default {
  'userInfo.newUser': 'Nouveau utilisateur',
  'userInfo.lastName': 'Nom',
  'userInfo.firstName': 'Prénom',
  'businessSite.exit': 'Sortir',
  'businessSite.addSubsection': 'Sous-rubrique',
  'businessSite.preview': 'Prévisualiser',
  'businessSite.help1': 'Cliquez sur le bouton "',
  'businessSite.help2':
    ' sous-rubrique" en bas à droite pour ajouter une sous-rubrique',
  'businessSite.importSiteFrom': 'Importer le site à partir de :',
  'businessSite.importSiteFrom.similarTemplates': 'Modèles similaires',
  'businessSite.importSiteFrom.yourBusinesses': 'Vos établissements',
  'businessSite.importedSuccessfully': 'Le site a été importé avec succès',
  'businessSite.noOtherBusinesses': `Vous n'avez aucun autre établissement. Veuillez en créer un pour continuer.`,
  'businessSite.noModelAvailable': 'Aucun modèle disponible actuellement',
  'businessSite.confirmationText': `Cette action importera toutes les rubriques de l'établissement choisi vers votre établissement actuel. Les rubriques existantes seront écrasées. Voulez-vous continuer ?`,
};
