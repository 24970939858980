import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate} from 'react-router-dom';

import {FormControlLabel, Stack, Typography} from '@mui/material';

import FormikGeoLocationPicker from '../../lib/googlemaps/components/GeoLocationPicker/FormikGeoLocationPicker';
import {internalError} from '../../lib/helpers/internalError';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import {Formik} from 'formik';
import * as yup from 'yup';

import FormikTextField from '../../components/atoms/formik/FormikTextField/FormikTextField';
import FormikBusinessCategoryPicker from '../../components/molecules/formik/FormikBusinessCategoryPicker';
import LoadingOverlay from '../../components/molecules/LoadingOverlay';
import PageAction from '../../components/organisms/PageAction';

import {
  useUpdateBusinessAddressMutation,
  useUpdateBusinessMutation,
} from '../../redux/businessApi';

import {BUSINESS_DESCRIPTION_MAX_LENGTH} from '../../app/constants';
import {useAppDispatch} from '../../app/hooks';
import FormikSingleChoice from '../../components/atoms/formik/FormikSingleChoice';
import SaveButton from '../../components/atoms/SaveButton';
import {useIntl} from '../../lib/intl';
import useGetCurrentBusinessQuery from '../../redux/get-current-business';

const validationSchema = yup.object({
  name: yup.string().required('validation.requiredField'),
  category: yup.string().required('validation.requiredField'),
  descriptionIntl: yup.object(),
  address: yup.string().required('validation.fieldRequired'),
  location: yup.object().required('validation.fieldRequired'),
  website: yup.string().url('validation.inValidUrl'),
});

const BusinessProfileEdit = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = React.useState(false);
  const businessQuery = useGetCurrentBusinessQuery();
  const business = businessQuery.data;
  const [saveBusinessProfile, mutationResult] = useUpdateBusinessMutation();
  const [updateBusinessAddress, mutation2Result] =
    useUpdateBusinessAddressMutation();
  const loading =
    mutationResult.isLoading || mutation2Result.isLoading || isLoading;

  useQueryInternalError(businessQuery);
  useQueryInternalError(mutation2Result);

  const onSubmit = React.useCallback(
    async ({
      name,
      category,
      descriptionIntl,
      address,
      location,
      website,
      enabled,
    }: any) => {
      try {
        await saveBusinessProfile({
          id: business?._id as string,
          data: {
            name,
            category,
            address,
            location,
            descriptionIntl,
            website: website?.toLowerCase(),
            enabled,
          },
        }).unwrap();

        NotificationManager.success(
          intl.formatMessage({id: 'editBusinessInformation.successMessage'}),
        );
        navigate(-1);
      } catch (err) {
        internalError(err);
      } finally {
        setLoading(false);
      }
    },
    [business?._id, dispatch, navigate, saveBusinessProfile],
  );

  if (businessQuery.isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        image: business?.image?.reference || '',
        name: business?.name || '',
        category: business?.category?._id || '',
        address: business?.address || '',
        descriptionIntl: business?.descriptionIntl || {},
        location: business?.location?.coordinates
          ? {
              longitude: business?.location.coordinates[0],
              latitude: business?.location.coordinates[1],
            }
          : null,
        website: business?.website || '',
        enabled: business?.enabled === undefined ? true : business.enabled,
      }}
      onSubmit={onSubmit}>
      {({handleSubmit, values}) => (
        <PageAction
          title={intl.formatMessage({id: 'editBusinessInformation.title'})}>
          <Stack direction="column" spacing={2}>
            <FormikTextField
              label={intl.formatMessage({
                id: 'editBusinessInformation.businessName',
              })}
              name="name"
            />
            <FormikTextField
              label={intl.formatMessage({
                id: 'editBusinessInformation.website',
              })}
              name="website"
              placeholder="https://www.fluxence.com"
            />
            <FormikTextField
              label={intl.formatMessage({
                id: 'editBusinessInformation.description',
              })}
              name="description"
              multiline
              minRows={4}
              enableIntl
              automaticTranslation
              InputProps={{
                inputProps: {
                  maxlength: BUSINESS_DESCRIPTION_MAX_LENGTH,
                },
              }}
            />
            <FormikBusinessCategoryPicker
              label={intl.formatMessage({
                id: 'editBusinessInformation.activitySector',
              })}
              name="category"
            />
            <FormikGeoLocationPicker
              locationName="location"
              addressName="address"
              onSave={({address, location}) =>
                updateBusinessAddress({
                  id: business?._id as string,
                  address,
                  location,
                })
              }
            />
            <FormControlLabel
              control={<FormikSingleChoice name="enabled" />}
              label={intl.formatMessage({id: 'createBusiness.status'})}
            />

            {values.enabled === false && (
              <Typography variant="body2" paddingLeft={2} color={'red'}>
                {intl.formatMessage({id: 'createBusiness.disableMessage'})}
              </Typography>
            )}
          </Stack>
          <SaveButton
            onClick={loading ? undefined : () => handleSubmit()}
            loading={loading}
          />
        </PageAction>
      )}
    </Formik>
  );
};

export default BusinessProfileEdit;
