import useQueryInternalError from '../lib/hooks/useQueryInternalError';
import {getBusinessId} from '../lib/storage/business-id';
import {useGetBusinessByIdQuery} from './businessApi';

const useGetCurrentBusinessQuery = () => {
  const businessId = getBusinessId();

  const query = useGetBusinessByIdQuery(businessId as string, {
    skip: !businessId,
  });

  useQueryInternalError(query);

  return query;
};

export const useCurrentBusiness = () => {
  const query = useGetCurrentBusinessQuery();

  return query.data;
};

export default useGetCurrentBusinessQuery;
