import {NotificationManager} from 'react-notifications';

import {logout} from '../../redux/auth/logout';

import store from '../../app/store';

export const internalError = (error: any, warning: boolean = false) => {
  console.error(error);

  if (
    error?.status === 401 ||
    (typeof error?.data === 'string' &&
      (error?.data?.indexOf('auth/user-token-expired') > -1 ||
        error?.data?.indexOf('auth/user-not-found') > -1))
  ) {
    store.dispatch(logout());
  } else {
    if (warning) {
      NotificationManager.warning('Une erreur est survenue');
    } else {
      NotificationManager.error('Une erreur est survenue');
    }
  }
};
