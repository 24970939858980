export default {
  'settings.breadcrumbs': 'Paramètres',
  'settings.title': 'Paramètres',
  'settings.accountInformation': 'Informations du compte',
  'settings.updateBusinessInformation':
    'Modifier les infos de mon établissement',
  'settings.extraSettings': 'Paramètres supplémentaires',
  'settings.downloadQRCode': 'Télécharger QR Code',
  'settings.updateBusinessLogo': 'Modifier le logo de mon établissement',
  'settings.changeBusiness': `Changer d'établissement`,
  'settings.contactUs': 'Contactez nous',
  'settings.paymentAndInvoices': 'Paiements et factures',
  'settings.deleteAccount': 'Supprimer mon compte',
  'settings.logout': 'Déconnexion',
};
