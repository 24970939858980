import {ArrowBack, ArrowForward, Delete, Edit} from '@mui/icons-material';
import {
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Link from '../../components/atoms/Link';
import Dialogable from '../../components/molecules/Dialogable';
import {FormattedMessage} from '../../lib/intl';
import useAppLanguage from '../../lib/intl/hooks/use-app-language';
import DeleteBusinessInformationSection from '../DeleteBusinessInformationSection/DeleteBusinessInformationSection';
import MoveBusinessInformationSection from '../MoveBusinessInformationSection/MoveBusinessInformationSection';

const BusinessInformationSectionActions = ({
  previewMode,
  section,
  open,
  onClose: dialogClose,
  isFirst,
  isLast,
}: {
  previewMode?: boolean;
  section: BusinessInformationSection;
  open: boolean;
  onClose: () => void;
  isFirst?: boolean;
  isLast?: boolean;
}) => {
  const lang = useAppLanguage();

  return (
    <Dialog open={open} onClose={dialogClose}>
      <DialogTitle>
        <Typography align="center" variant="h5">
          {section.nameIntl[lang]}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <MenuList>
          {previewMode ? undefined : (
            <Link
              onClick={evt => evt.stopPropagation()}
              to={`/actions/business-information-section/update/${section._id}`}>
              <MenuItem>
                <ListItemIcon>
                  <Edit fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <FormattedMessage id="businessInformationSectionActions.update" />
                </ListItemText>
              </MenuItem>
            </Link>
          )}
          {isFirst ? null : (
            <Dialogable
              source={({onOpen}) => (
                <MenuItem onClick={onOpen}>
                  <ListItemIcon>
                    <ArrowBack fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <FormattedMessage id="businessInformationSectionActions.moveLeft" />
                  </ListItemText>
                </MenuItem>
              )}
              dialog={({open, onClose}) => (
                <MoveBusinessInformationSection
                  direction="left"
                  open={open}
                  section={section}
                  onClose={() => {
                    onClose();
                    dialogClose();
                  }}
                />
              )}
            />
          )}
          {isLast ? null : (
            <Dialogable
              source={({onOpen}) => (
                <MenuItem onClick={onOpen}>
                  <ListItemIcon>
                    <ArrowForward fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <FormattedMessage id="businessInformationSectionActions.moveRight" />
                  </ListItemText>
                </MenuItem>
              )}
              dialog={({open, onClose}) => (
                <MoveBusinessInformationSection
                  direction="right"
                  open={open}
                  section={section}
                  onClose={() => {
                    onClose();
                    dialogClose();
                  }}
                />
              )}
            />
          )}
          <Dialogable
            source={({onOpen}) => (
              <MenuItem
                onClick={evt => {
                  evt.stopPropagation();
                  onOpen();
                }}>
                <ListItemIcon>
                  <Delete fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <FormattedMessage id="businessInformationSectionActions.delete" />
                </ListItemText>
              </MenuItem>
            )}
            dialog={({onClose, open}) => (
              <DeleteBusinessInformationSection
                open={open}
                section={section}
                onClose={() => {
                  onClose();
                  dialogClose();
                }}
              />
            )}
          />
        </MenuList>
      </DialogContent>
    </Dialog>
  );
};
export default BusinessInformationSectionActions;
