import {Outlet} from 'react-router-dom';
import PageActionLayout from '../../components/organisms/PageActionLayout';

const ActionOutlet = () => (
  <PageActionLayout>
    <Outlet />
  </PageActionLayout>
);

export default ActionOutlet;
