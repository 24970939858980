import {createSlice} from '@reduxjs/toolkit';

const initialState: {
  authenticated?: boolean;
} = {
  authenticated: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loggedOut(state) {
      state.authenticated = false;
    },
    loggedIn(state) {
      state.authenticated = true;
    },
  },
});

export const {loggedOut, loggedIn} = authSlice.actions;

export const authReducer = authSlice.reducer;

export default authSlice;
