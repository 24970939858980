import {ImportExport} from '@mui/icons-material';
import {Stack} from '@mui/material';
import RoundTextIconButton from '../../components/atoms/RoundButton';
import Dialogable from '../../components/molecules/Dialogable';
import BusinessSiteImportModal from '../../components/organisms/BusinessSiteImportModal';
import {FormattedMessage} from '../../lib/intl';
import {useCurrentBusiness} from '../../redux/get-current-business';

const BusinessSiteActions = () => {
  const business = useCurrentBusiness();

  return (
    <Stack direction="row" justifyContent="flex-end" px={1} py={2}>
      <Dialogable
        source={({onOpen}) => (
          <RoundTextIconButton
            color="secondary"
            text={<FormattedMessage id="businessSiteActions.import" />}
            size="small"
            icon={<ImportExport />}
            onClick={onOpen}
          />
        )}
        dialog={props => <BusinessSiteImportModal {...props} />}
      />
    </Stack>
  );
};

export default BusinessSiteActions;
