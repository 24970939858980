import firebase from '../../app/firebase';

const getFbImageDownloadUrl = async (
  ref: string,
  tries: number,
): Promise<string> => {
  try {
    return await firebase.storage().ref(`public/${ref}`).getDownloadURL();
  } catch (err) {
    if (tries - 1) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(getFbImageDownloadUrl(ref, tries - 1));
        }, 3000);
      });
    } else {
      throw {};
    }
  }
};

export default getFbImageDownloadUrl;
