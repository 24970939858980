import AspectRatioImage from '../../components/atoms/AspectRatioImage';
import FirebaseStorageImage from '../../lib/firebase-image/components/FirebaseStorageImage';

import BLANK_IMAGE_SQUARE from '../../assets/images/blankimage_square.png';

const roundStyle = {
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  padding: '2px',
};

const BusinessRoundImage = ({
  business,
}: {
  business: WithImage<Business, 'image'>;
}) => {
  return (
    <>
      {business.image ? (
        <FirebaseStorageImage
          Component={AspectRatioImage}
          reference={business.image.reference}
          ratio="1:1"
          style={roundStyle}
        />
      ) : (
        <AspectRatioImage
          ratio="1:1"
          src={BLANK_IMAGE_SQUARE}
          style={roundStyle}
        />
      )}
    </>
  );
};

export default BusinessRoundImage;
