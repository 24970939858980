import store from '../../app/store';
import authApi from '../authApi';
import {businessApi} from '../businessApi';
import {businessInformationSectionsApi} from '../businessInformationSectionsApi';
import filesApi from '../filesApi';
import {autocompleteApi, geocodingApi} from '../geocodingApi';
import signupApi from '../signup/signupApi';

export const clearRTKApiCache = () => {
  store.dispatch(authApi.util.resetApiState());
  store.dispatch(filesApi.util.resetApiState());
  store.dispatch(businessInformationSectionsApi.util.resetApiState());
  store.dispatch(businessApi.util.resetApiState());
  store.dispatch(signupApi.util.resetApiState());
  store.dispatch(autocompleteApi.util.resetApiState());
  store.dispatch(geocodingApi.util.resetApiState());
};
