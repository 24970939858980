export default {
  'userInfo.newUser': 'New user',
  'userInfo.lastName': 'Name',
  'userInfo.firstName': 'First Name',
  'businessSite.exit': 'Exit',
  'businessSite.addSubsection': 'Subsection',
  'businessSite.preview': 'Preview',
  'businessSite.help1': 'Click the "',
  'businessSite.help2': 'subsection at the bottom right to add a subsection',
  'businessSite.importSiteFrom': 'Import site from:',
  'businessSite.importSiteFrom.similarTemplates': 'Similar Templates',
  'businessSite.importSiteFrom.yourBusinesses': 'Your businesses',
  'businessSite.importedSuccessfully': 'The site was imported successfully',
  'businessSite.noOtherBusinesses': `You have no other locations. Please create one to continue.`,
  'businessSite.noModelAvailable': 'No models currently available',
  'businessSite.confirmationText': `This action will import all sections of the chosen business to your current business. Existing sections will be overwritten. Do you want to continue?`,
};
