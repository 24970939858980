import {
  Alert,
  Backdrop,
  Breadcrumbs,
  CircularProgress,
  Typography,
} from '@mui/material';

import {Helmet} from 'react-helmet';
import UserPage from '../../components/organisms/UserPage';
import useGetAuthUserId from '../../lib/hooks/useGetAuthUserId';
import {FormattedMessage, useIntl} from '../../lib/intl';
import useAppLanguage from '../../lib/intl/hooks/use-app-language';
import {useGetUserBillingPortalQuery} from '../../redux/paymentApi';

const Payments = () => {
  const locale = useAppLanguage();
  const user = useGetAuthUserId();
  const intl = useIntl();
  const {
    data: billingPortal,
    isLoading,
    isSuccess,
  } = useGetUserBillingPortalQuery({user, locale}, {skip: !user});

  if (billingPortal?.url) {
    window.location.href = billingPortal?.url;
    return (
      <Typography align="center" variant="body1">
        <FormattedMessage id="payments.loading" />
      </Typography>
    );
  }

  return (
    <UserPage
      breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">
          <Typography variant="h6" color="text.primary">
            <FormattedMessage id="payments.title" />
          </Typography>
        </Breadcrumbs>
      }>
      <Helmet>
        <title>{intl.formatMessage({id: 'payments.title'})}</title>
      </Helmet>
      {isSuccess && !billingPortal && (
        <Alert severity="info">
          <FormattedMessage id="payments.noInvoice" />
        </Alert>
      )}
      <Backdrop
        sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}}
        open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </UserPage>
  );
};

export default Payments;
