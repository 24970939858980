import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate} from 'react-router-dom';

import {internalError} from '../../lib/helpers/internalError';
import useModalState from '../../lib/hooks/useModalState';

import {useAppDispatch} from '../../app/hooks';
import {useIntl} from '../../lib/intl';
import {phoneNumberExists, signIn} from '../../redux/signin/thunks';
import PhoneNumberForm from './PhoneNumberForm';
import SigninConfirmationCode from './SigninConfirmationCode';
import SignupConfirmationCode from './SignupConfirmationCode';

const AuthPhoneNumber = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [tel, setTel] = React.useState({
    phoneNumber: '',
    country: '',
    ext: '',
  });
  const [signinCCOpen, openSigninCC, closeSigninCC] = useModalState();

  const [signupCCOpen, openSignupCC, closeSignupCC] = useModalState();

  const handleSignin = React.useCallback(
    async ({
      phoneNumber,
      country,
      ext,
    }: {
      phoneNumber: string;
      country: string;
      ext: string;
    }) => {
      try {
        setLoading(true);
        const exists = await dispatch(phoneNumberExists(phoneNumber)).unwrap();
        await dispatch(
          signIn({
            tel: phoneNumber,
          }),
        ).unwrap();
        if (exists) {
          openSigninCC();
        } else {
          setTel({
            phoneNumber,
            country,
            ext,
          });

          openSignupCC();
        }
      } catch (err) {
        if (err === 'auth/invalid-phone-number') {
          return NotificationManager.error(
            intl.formatMessage({id: 'authPhoneNumber.invalidPhoneNumber'}),
          );
        } else if (err === 'auth/too-many-requests') {
          NotificationManager.error(
            intl.formatMessage({id: 'authPhoneNumber.tooManyRequests'}),
          );
        } else {
          internalError(err);
        }
      } finally {
        setLoading(false);
      }
    },
    [dispatch, navigate],
  );

  return (
    <>
      {!signinCCOpen && !signupCCOpen && (
        <PhoneNumberForm loading={loading} onSignin={handleSignin} />
      )}
      {signinCCOpen && <SigninConfirmationCode onBack={closeSigninCC} />}
      {signupCCOpen && (
        <SignupConfirmationCode onBack={closeSignupCC} {...tel} />
      )}
    </>
  );
};

export default AuthPhoneNumber;
