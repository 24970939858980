import React from 'react';
import {NotificationManager} from 'react-notifications';

import firebase from '../../lib/app/firebase';
import {internalError} from '../../lib/helpers/internalError';
import useModalState from '../../lib/hooks/useModalState';

import {useAppDispatch} from '../../app/hooks';
import {useIntl} from '../../lib/intl';
import {resendSignInCode, signupConfirmCode} from '../../redux/signin/thunks';
import CompleteInformation from './CompleteInformation';
import ConfirmationCode from './ConfirmationCode';

const SignupConfirmationCode = ({
  phoneNumber,
  country,
  ext,
  onBack,
}: {
  phoneNumber: string;
  country: string;
  ext: string;
  onBack: () => void;
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const [csOpen, openCS, closeCS] = useModalState();

  const handleConfirm = React.useCallback(
    async ({confirmationCode}: any) => {
      try {
        setLoading(true);
        await dispatch(signupConfirmCode(confirmationCode)).unwrap();
      } catch (err) {
        if (err === 'auth/invalid-verification-code') {
          NotificationManager.error(
            intl.formatMessage({id: 'signupConfirmationCode.invalidCode'}),
          );
        } else if (err === 'auth/too-many-requests') {
          NotificationManager.error(
            intl.formatMessage({id: 'signupConfirmationCode.tooManyRequests'}),
          );
        } else if (err === 'signInRequired') {
          NotificationManager.warning(
            intl.formatMessage({id: 'signupConfirmationCode.smsSending'}),
          );
        } else {
          internalError(err);
        }
      } finally {
        setLoading(false);
      }
    },
    [dispatch],
  );

  React.useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        openCS();
      }
    });

    return unsubscribe;
  }, [dispatch]);

  return (
    <>
      {!csOpen && (
        <ConfirmationCode
          loading={loading}
          onBack={onBack}
          onSubmit={handleConfirm}
          onResend={() => dispatch(resendSignInCode())}
        />
      )}
      {csOpen && (
        <CompleteInformation
          phoneNumber={phoneNumber}
          country={country}
          ext={ext}
          onClose={onBack}
        />
      )}
    </>
  );
};

export default SignupConfirmationCode;
