import {Box, CircularProgress} from '@mui/material';

const LoadingOverlay = ({transparent = false}: {transparent?: boolean}) => (
  <Box
    sx={{
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      inset: 0,
      backgroundColor: transparent ? 'rgba(0, 0, 0, .15)' : 'white',
      zIndex: 1000,
    }}>
    <CircularProgress />
  </Box>
);

export default LoadingOverlay;
