import * as yup from 'yup';
import {IntlKeys} from '../intl';

const phoneNumberRegExp = /^(\+?(\d{1,5}))?(\d{3})(\d{3})(\d{4})$/gm;
yup.addMethod(yup.string, 'phoneNumberValid', function () {
  return this.test({
    name: 'phoneNumberValid',
    message: '${path} is invalid',
    test: function (value: any) {
      if (typeof value === 'string' && value.match(phoneNumberRegExp)) {
        return true;
      } else {
        // @ts-ignore
        return this.createError({
          message: 'validation.invalidPhoneNumber' as IntlKeys,
          // @ts-ignore
          path: this.path,
        });
      }
    },
  });
});

declare module 'yup' {
  interface StringSchema {
    phoneNumberValid(): StringSchema;
  }
}
