export default {
  'businessInformationSectionsList.addSection': 'Click + to add a section',
  'businessInformationSectionsList.noSections': `No sections are available`,
  'businessSiteActionsModal.importSiteFromOtherBusiness':
    'Import a site from another business',
  'businessSitePageName.home': 'Home',
  'businessSitePageName.example':
    'Example: Menu, Our services, Room directory...',
  'businessSitePageName.pageName': 'Page name: ',
  'businessSiteActions.import': 'Import',
};
