import {createApi} from '@reduxjs/toolkit/dist/query/react';

import providesTagsFromOneResult from '../lib/helpers/providesTagsFromOneResult';
import {authBaseQuery} from './apiBaseQuery';

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: authBaseQuery,
  tagTypes: ['AuthUser'],
  endpoints(build) {
    return {
      getAuthUser: build.query<SuperUser, void>({
        query: () => ({
          url: '/getAuthUser',
          method: 'get',
        }),
        providesTags: providesTagsFromOneResult('AuthUser'),
      }),
      updateProfile: build.mutation<
        SuperUser,
        Pick<SuperUser, 'firstName' | 'lastName'>
      >({
        query: ({firstName, lastName}) => ({
          url: '/updateAuthUser',
          method: 'patch',
          data: {
            firstName,
            lastName,
          },
        }),
        invalidatesTags: providesTagsFromOneResult('AuthUser'),
      }),
      deleteAccount: build.mutation<void, void>({
        query: () => ({
          url: '/deleteUser',
          method: 'delete',
          data: {
            status: 'B2B_WEB',
          },
        }),
        invalidatesTags: providesTagsFromOneResult('AuthUser'),
      }),
      updatePassword: build.mutation<SuperUser, {password: string}>({
        query: ({password}) => ({
          url: '/updateAuthUserPassword',
          method: 'patch',
          data: {
            password,
          },
        }),
      }),
    };
  },
});

export const {
  useGetAuthUserQuery,
  useDeleteAccountMutation,
  useUpdateProfileMutation,
} = authApi;

export default authApi;
