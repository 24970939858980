export default {
  'editGenerateCodeStats.title': 'Informations supplémentaires',
  'editGenerateCodeStats.qrCodeQuantity': 'Nombre de QR code imprimés',
  'editGenerateCodeStats.qrCodeQuantityHelperText':
    'Nombre de QR Code que vous avez généré, cette information est pour des fins statistiques',
  'editGenerateCodeStats.successMessage':
    'Informations supplémentaires modifiées avec succès',
  'editGenerateCodeStats.qrCodeNotNegative':
    'Le nombre de QR code ne peut être négatif',
};
