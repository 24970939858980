import {RadioButtonChecked, RadioButtonUnchecked} from '@mui/icons-material';
import {
  Button,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Modal,
  Paper,
} from '@mui/material';
import {useContext} from 'react';
import Dialogable from '../../../components/molecules/Dialogable';
import {
  FormattedMessage,
  LanguageCode,
  languageFlags,
  supportedLanguages,
} from '../../intl';
import {AppLocaleContext} from '../../intl/components/AppLocaleProvider';

const LanguagePicker = () => {
  const {locale, setLocale} = useContext(AppLocaleContext);

  return (
    <Dialogable
      source={({onOpen}) => (
        <Button
          size="small"
          startIcon={
            <img
              src={languageFlags[locale as LanguageCode] as any}
              alt={locale}
              style={{
                width: '24px',
                height: '24px',
              }}
            />
          }
          variant="text"
          onClick={onOpen}>
          ({locale})
        </Button>
      )}
      dialog={({open, onClose}) => (
        <Modal
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          open={open}
          onClose={onClose}>
          <Paper
            sx={{
              width: '250px',
            }}>
            <MenuList>
              {supportedLanguages.map(({code, name}) => (
                <MenuItem
                  key={code}
                  onClick={() => {
                    setLocale(code as LanguageCode);
                    onClose();
                  }}>
                  <ListItemIcon>
                    {code === locale ? (
                      <RadioButtonChecked color="success" />
                    ) : (
                      <RadioButtonUnchecked color="primary" />
                    )}
                  </ListItemIcon>

                  <ListItemText>
                    <FormattedMessage
                      id={`languagePicker.${code as LanguageCode}`}
                    />
                  </ListItemText>
                  <ListItemIcon>
                    <img
                      src={languageFlags[code as LanguageCode] as any}
                      alt={name}
                      style={{
                        width: '24px',
                        height: '24px',
                      }}
                    />
                  </ListItemIcon>
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </Modal>
      )}
    />
  );
};

export default LanguagePicker;
