export default {
  'common.actions': 'Actions',
  'common.finish': 'Finish',
  'common.cancel': 'Cancel',
  'common.save': 'Save',
  'common.clear': 'Clear',
  'common.attention': 'Attention',
  'common.preview': 'Preview',
  'common.end': 'End',
  'common.validate': 'Validate',
  'common.continue': 'Continue',
  'common.delete': 'Delete',
  'validation.fieldRequired': 'Field required',
  'validation.inValidEmail': 'Invalid email',
  'validation.tryAnotherAddress': 'Enter or try another address',
  'validation.inValidUrl':
    'The URL is invalid. Enter the URL with http(s)://, for example: https://www.fluxence.com',
  'validation.requiredField': 'Required field',
  'validation.emailUsed': 'Email already used',
  'validation.phoneUsed': 'Phone number already used',
  'validation.invalidPhoneNumber':
    'Invalid phone number (Ex: +33611222333, +12481237654, +34600000000)',
};
