import React, {ComponentProps} from 'react';

import {Stack} from '@mui/material';

const UserPage: React.FC<
  {
    breadcrumbs?: JSX.Element;
    children?: React.ReactNode;
  } & ComponentProps<typeof Stack>
> = ({breadcrumbs, children, ...props}) => (
  <Stack flex={1} px={1} pt={1} {...props} position="relative" spacing={2}>
    {breadcrumbs}
    <Stack flex={1} direction="column" position="relative">
      {children}
    </Stack>
  </Stack>
);

export default UserPage;
