export default {
  'createBusiness.title': 'Create a new business',
  'createBusiness.donwloadImage': 'Upload an image',
  'createBusiness.name': 'Business name',
  'createBusiness.description': 'Description',
  'createBusiness.category': `Business area`,
  'createBusiness.website': 'Website',
  'createBusiness.successMessage': `The business was created successfully`,
  'createBusiness.status': 'Active',
  'createBusiness.disableMessage':
    'Please note: disabling this business will cause in the suspension of the subscription and the invalidation of the client site link.',
};
