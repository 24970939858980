export default {
  'validation.fieldRequired': 'Field required',
  'validation.inValidEmail': 'Invalid email',
  'validation.tryAnotherAddress': 'Enter or try another address',
  'validation.inValidUrl':
    'The URL is invalid. Enter the URL with http(s)://, for example: https://www.fluxence.com',
  'validation.requiredField': 'Required field',
  'validation.emailUsed': 'Email already used',
  'validation.phoneUsed': 'Phone number already used',
  'validation.invalidPhoneNumber':
    'Invalid phone number (Ex: +33611222333, +12481237654, +34600000000)',
};
