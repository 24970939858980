export default {
  'updateBusinessInformationSectionBlock.message':
    'El contenido excede el tamaño máximo permitido de 15 MB.',
  'updateBusinessInformationSectionBlock.successMessage':
    'La subsección se ha modificado correctamente',
  'updateBusinessInformationSectionBlock.block': 'la subsección',
  'updateBusinessInformationSectionBlock.title': 'Título',
  'updateBusinessInformationSectionBlock.content': 'Contenido',
  'updateBusinessInformationSectionBlock.blockTitle': 'Modificación de {label}',
};
