import {Stack, TextField} from '@mui/material';
import {Formik} from 'formik';
import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';

import FormikAutoComplete from '../../components/atoms/formik/FormikAutoComplete';
import FormikTextField from '../../components/atoms/formik/FormikTextField/FormikTextField';
import SaveButton from '../../components/atoms/SaveButton';
import PageAction from '../../components/organisms/PageAction';
import {internalError} from '../../lib/helpers/internalError';
import useCurrentBusinessId from '../../lib/hooks/use-current-business-id';
import {FormattedMessage, useIntl} from '../../lib/intl';
import {useSendIssueMutation} from '../../redux/reportIssueApi';

const validationSchema = yup.object({
  subject: yup.string().required('validation.requiredField'),
  message: yup.string().required('validation.requiredField'),
});

const FormIssue = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const businessId = useCurrentBusinessId();
  const [sendIssue, mutationResult] = useSendIssueMutation();

  React.useEffect(() => {
    if (mutationResult.isError) {
      internalError(mutationResult.error);
    }

    if (mutationResult.isSuccess) {
      NotificationManager.success(
        intl.formatMessage({id: 'reportIssue.successMessage'}),
      );
      navigate('/settings');
    }
  }, [
    mutationResult.error,
    mutationResult.isError,
    mutationResult.isSuccess,
    navigate,
  ]);

  const onSubmit = React.useCallback(
    async ({subject, message}: any, {resetForm}: any) => {
      try {
        await sendIssue({businessId, subject, message});
        resetForm();
      } catch (err) {
        internalError(err);
      }
    },
    [businessId, sendIssue],
  );

  return (
    <PageAction title={intl.formatMessage({id: 'reportIssue.title'})}>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={{
          subject: '',
          message: '',
        }}
        onSubmit={onSubmit}>
        {({handleSubmit}) => (
          <Stack direction="column" spacing={1}>
            <FormikAutoComplete
              placeholder={intl.formatMessage({
                id: 'reportIssue.subjectPlaceholder',
              })}
              name="subject"
              options={[
                {
                  label: intl.formatMessage({id: 'reportIssue.security'}),
                  id: 'Sécurité',
                },
                {
                  label: intl.formatMessage({id: 'reportIssue.payment'}),
                  id: 'Paiement',
                },
                {
                  label: intl.formatMessage({id: 'reportIssue.display'}),
                  id: 'Affichage',
                },
                {
                  label: intl.formatMessage({id: 'reportIssue.profile'}),
                  id: 'Profil',
                },
                {
                  label: intl.formatMessage({id: 'reportIssue.information'}),
                  id: 'Informations',
                },
                {
                  label: intl.formatMessage({id: 'reportIssue.other'}),
                  id: 'Autres',
                },
                {
                  label: intl.formatMessage({id: 'reportIssue.bug'}),
                  id: 'Bug/Problème technique',
                },
              ]}
              renderInput={params => (
                <TextField
                  {...params}
                  label={intl.formatMessage({id: 'reportIssue.subject'})}
                />
              )}
            />
            <FormikTextField
              label={intl.formatMessage({id: 'reportIssue.message'})}
              name="message"
              multiline
              rows={10}
              maxRows={15}
            />
            <SaveButton
              loading={mutationResult.isLoading}
              onClick={
                mutationResult.isLoading ? undefined : () => handleSubmit()
              }
              disabled={mutationResult.isLoading}>
              <FormattedMessage id="common.save" />
            </SaveButton>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default FormIssue;
