import {FormGroup, FormHelperText, FormLabel, Stack} from '@mui/material';
import {useField} from 'formik';
import React, {useCallback, useState} from 'react';
import {
  FormattedMessage,
  LanguageCode,
  supportedLanguagesCodes,
} from '../../../lib/intl';
import IntlContentTranslationButton from '../../../lib/intl/components/IntlContentTranslationButton';
import {IntlContent} from '../../../lib/intl/types';
import LanguagesTabs from '../../atoms/LanguagesTabs';
import RichText from '../../atoms/RichText';

const FormikRichText = ({
  name,
  nameIntl = name + 'Intl',
  label,
  required = false,
  enableIntl = false,
  automaticTranslation = false,
}: {
  name: string;
  nameIntl?: string;
  label: string | React.ReactNode;
  required: boolean;
  enableIntl?: boolean;
  automaticTranslation?: boolean;
}) => {
  const [shouldTranslate, setShouldTranslate] = useState<boolean>(false);
  const [field, meta, helpers] = useField(name);
  const [fieldIntl, metaIntl, helpersIntl] = useField(nameIntl);
  const hasError = enableIntl
    ? !!(metaIntl.error && metaIntl.touched)
    : !!(meta.error && meta.touched);
  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>(
    supportedLanguagesCodes[0],
  );

  const handleTranslateComplete = useCallback(
    (translatedContent: IntlContent) => {
      if (selectedLanguage) {
        setShouldTranslate(false);
        helpersIntl.setValue({
          ...translatedContent,
          [selectedLanguage]: fieldIntl.value?.[selectedLanguage],
        });
      }
    },
    [
      selectedLanguage ? fieldIntl.value?.[selectedLanguage] : undefined,
      selectedLanguage,
    ],
  );

  if (!enableIntl) {
    return (
      <FormGroup>
        <FormLabel>
          {label}
          {required && ' *'}
        </FormLabel>
        <RichText
          value={field.value}
          onChange={value => {
            helpers.setValue(value);
          }}
          onBlur={() => helpers.setTouched(true)}
          error={meta.touched && !!meta.error}
        />
        {hasError && (
          <FormHelperText error>
            <FormattedMessage id={meta.error as any} />
          </FormHelperText>
        )}
      </FormGroup>
    );
  }

  return (
    <Stack spacing={1}>
      <LanguagesTabs
        onLangChange={lang => {
          setSelectedLanguage(undefined);
          setTimeout(() => {
            setSelectedLanguage(lang);
          }, 100);
        }}
        selectedLang={selectedLanguage || 'FR'}
      />
      <FormGroup>
        <FormLabel>
          {label}
          {required && ' *'}
        </FormLabel>
        {selectedLanguage && (
          <RichText
            value={fieldIntl.value[selectedLanguage] || ''}
            onChange={value => {
              if (selectedLanguage) {
                helpersIntl.setValue({
                  ...fieldIntl.value,
                  [selectedLanguage]: value,
                });
                setShouldTranslate(true);
              }
            }}
            onBlur={() => helpersIntl.setTouched(true)}
            error={metaIntl.touched && !!metaIntl.error}
          />
        )}
        {hasError && (
          <FormHelperText error>
            <FormattedMessage id={metaIntl.error as any} />
          </FormHelperText>
        )}
      </FormGroup>
      {automaticTranslation && selectedLanguage && (
        <IntlContentTranslationButton
          format="html"
          baseLang={selectedLanguage as LanguageCode}
          sx={{
            justifyContent: 'flex-end',
          }}
          text={fieldIntl.value?.[selectedLanguage]}
          onTranslateComplete={handleTranslateComplete}
          shouldTranslate
        />
      )}
    </Stack>
  );
};

export default FormikRichText;
