export default {
  'businessInformationSectionsList.addSection':
    'Klicken Sie auf +, um einen Abschnitt hinzuzufügen',
  'businessInformationSectionsList.noSections': `Es sind keine Abschnitte verfügbar`,
  'businessSiteActionsModal.importSiteFromOtherBusiness':
    'Eine Website von einem anderen Unternehmen importieren',
  'businessSitePageName.home': 'Home',
  'businessSitePageName.example':
    'Beispiel: Menü, Unsere Dienstleistungen, Zimmerverzeichnis...',
  'businessSiteActions.import': 'Importieren',
};
