import React from 'react';
import {Helmet} from 'react-helmet';

import {Box, Stack} from '@mui/material';

import {PageActionContext} from './PageActionLayout';

const PageAction = ({
  title = '',
  children,
}: {
  title?: string;
  children: React.ReactNode;
}) => {
  const {setActionTitle} = React.useContext(PageActionContext);

  React.useEffect(() => {
    setActionTitle(title);

    return () => {
      setActionTitle('');
    };
  }, [title]);

  return (
    <Stack flex={1} spacing={4}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}>
        {children}
      </Box>
    </Stack>
  );
};

export default PageAction;
