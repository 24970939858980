import {ComponentProps} from 'react';

import {Stack, Typography} from '@mui/material';

import {useField} from 'formik';
import {FormattedMessage} from '../../../lib/intl';
import FileInput from '../FileInput';

const FormikFileInput = ({
  name,
  aspectRatio = '16:9',
  ...props
}: {
  name: string;
  aspectRatio?: AspectRatio;
} & Omit<
  ComponentProps<typeof FileInput>,
  'onFileSelect' | 'onFileRemove'
>) => {
  const [field, meta, helpers] = useField(name);

  return (
    <Stack spacing={2}>
      <FileInput
        {...props}
        onFileSelect={file => helpers.setValue(file)}
        value={field.value}
        onFileRemove={() => helpers.setValue('')}
        error={meta.touched && !!meta.error}
        aspectRatio={aspectRatio}
      />
      {meta.touched && meta.error ? (
        <Typography variant="body2" color="error">
          <FormattedMessage id={meta.error as any} />
        </Typography>
      ) : null}
    </Stack>
  );
};

export default FormikFileInput;
