import {useEffect} from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate, useParams} from 'react-router-dom';

import {Stack} from '@mui/material';

import useCurrentBusinessId from '../../lib/hooks/use-current-business-id';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import {Formik} from 'formik';
import * as Yup from 'yup';

import FormikTextField from '../../components/atoms/formik/FormikTextField/FormikTextField';
import LoadingOverlay from '../../components/molecules/LoadingOverlay';
import PageAction from '../../components/organisms/PageAction';

import SaveButton from '../../components/atoms/SaveButton';
import {FormattedMessage, useIntl} from '../../lib/intl';
import useAppLanguage from '../../lib/intl/hooks/use-app-language';
import {
  useGetBusinessInformationSectionByIdQuery,
  useUpdateBusinessInformationSectionMutation,
} from '../../redux/businessInformationSectionsApi';

const validationSchema = Yup.object({
  nameIntl: Yup.object().intlRequired('validation.requiredField'),
});

const UpdateBusinessInformationSection = () => {
  const intl = useIntl();
  const {id} = useParams<{id: string}>();
  const business = useCurrentBusinessId();
  const navigate = useNavigate();
  const lang = useAppLanguage();

  const {data: section, ...query} = useGetBusinessInformationSectionByIdQuery({
    business,
    id: id as string,
  });

  const [update, mutation] = useUpdateBusinessInformationSectionMutation();

  useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success(
        intl.formatMessage({
          id: 'updateBusinessInformationSection.successMessage',
        }),
      );
      navigate(-1);
    }
  }, [mutation.isSuccess, mutation.data]);

  useQueryInternalError(mutation);
  useQueryInternalError(query);

  return (
    <PageAction
      title={intl.formatMessage({
        id: 'updateBusinessInformationSection.title',
        values: {name: section?.nameIntl[lang] || ''},
      })}>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{
          nameIntl: section?.nameIntl ?? {},
        }}
        onSubmit={({nameIntl}) =>
          update({
            id: id as string,
            nameIntl,
            business,
          })
        }>
        {({handleSubmit}) => (
          <Stack spacing={1} direction="column">
            <FormikTextField
              name="name"
              label={intl.formatMessage({
                id: 'updateBusinessInformationSection.name',
              })}
              enableIntl
              automaticTranslation
            />
            <SaveButton
              sx={{
                position: 'fixed',
                bottom: theme => theme.spacing(0.5),
                left: theme => theme.spacing(0.5),
                right: theme => theme.spacing(0.5),
                margin: 'auto',
              }}
              loading={mutation.isLoading}
              onClick={mutation.isLoading ? undefined : () => handleSubmit()}>
              <FormattedMessage id="common.save" />
            </SaveButton>
          </Stack>
        )}
      </Formik>
      {query.isLoading && <LoadingOverlay />}
    </PageAction>
  );
};

export default UpdateBusinessInformationSection;
