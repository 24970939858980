export default {
  'updateBusinessInformationSectionBlock.message':
    'Der Inhalt überschreitet die maximal zulässige Größe von 15 MB.',
  'updateBusinessInformationSectionBlock.successMessage':
    'Der Unterabschnitt wurde erfolgreich geändert',
  'updateBusinessInformationSectionBlock.block': 'der Unterabschnitt',
  'updateBusinessInformationSectionBlock.title': 'Titel',
  'updateBusinessInformationSectionBlock.content': 'Inhalt',
  'updateBusinessInformationSectionBlock.blockTitle':
    'Aktualisierung von {label}',
};
