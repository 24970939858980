import {Block, Check} from '@mui/icons-material';
import {ButtonBase, Chip, Paper, Stack, Typography} from '@mui/material';

import FirebaseStorageImage from '../../lib/firebase-image/components/FirebaseStorageImage';
import getLineBreakHTMLProp from '../../lib/helpers/getLineBreakHTMLProp';
import {useIntl} from '../../lib/intl';
import useAppLanguage from '../../lib/intl/hooks/use-app-language';
import AspectRatioImage from './AspectRatioImage';

const BusinessOption = ({
  selected,
  business,
  onClick,
  hideStatus = false,
}: {
  business: PopulatedBusiness;
  selected?: boolean;
  onClick?: () => void;
  hideStatus?: boolean;
}) => {
  const lang = useAppLanguage();
  const intl = useIntl();
  return (
    <ButtonBase key={business._id} onClick={onClick}>
      <Paper
        sx={{
          width: '100%',
          backgroundColor: selected ? '#eaeaea' : 'background.paper',
        }}
        variant="outlined"
        square>
        <Stack spacing={2} direction="row" p={2} alignItems="center">
          <FirebaseStorageImage
            reference={business.image.reference}
            Component={AspectRatioImage}
            ratio="1:1"
            sx={{
              width: 50,
              height: 50,
              borderRadius: 25,
            }}
          />
          <Stack direction="column" spacing={1}>
            <Typography align="left" variant="body1">
              {business.name}
            </Typography>
            {business.descriptionIntl?.[lang] && (
              <Typography
                align="left"
                variant="body2"
                {...getLineBreakHTMLProp(
                  business.descriptionIntl[lang].substring(0, 150) +
                    `${
                      business.descriptionIntl[lang].length > 150 ? '...' : ''
                    }`,
                )}
              />
            )}
            {business.address && (
              <Typography align="left" variant="body2">
                {business.address}
              </Typography>
            )}
          </Stack>
          {!hideStatus && (
            <Chip
              icon={
                business.enabled || business.enabled === undefined ? (
                  <Check />
                ) : (
                  <Block />
                )
              }
              color={
                business.enabled || business.enabled === undefined
                  ? 'success'
                  : 'error'
              }
              label={
                business.enabled || business.enabled === undefined
                  ? intl.formatMessage({id: 'userBusiness.enable'})
                  : intl.formatMessage({id: 'userBusiness.disable'})
              }
              variant="outlined"
              sx={{marginLeft: 'auto !important'}}
            />
          )}
        </Stack>
      </Paper>
    </ButtonBase>
  );
};

export default BusinessOption;
