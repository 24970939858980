import React from 'react';
import {Outlet, useLocation} from 'react-router-dom';

import {AccountTree, PresentToAll, Settings} from '@mui/icons-material';
import {Box, IconButton, Toolbar, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import AppBar from '../../components/atoms/AppBar';
import NavLink from '../../components/atoms/NavLink';
import BusinessDetailsAppBar from '../../components/molecules/BusinessAppBar';
import Dialogable from '../../components/molecules/Dialogable';
import DownloadBusinessQRCode from '../../modals/DownloadBusinessQRCode/DownloadBusinessQRCode';
import useGetCurrentBusinessQuery from '../../redux/get-current-business';
import {FormattedMessage} from '../intl';

const MenuIcon: React.FC<{
  Icon: any;
  text: string | React.ReactNode;
  onClick?: () => void;
  sx?: any;
}> = ({Icon, text, sx, onClick}) => (
  <IconButton
    color="inherit"
    sx={{flexDirection: 'column', ...sx}}
    onClick={onClick}>
    <Icon fontSize="medium" />
    <Typography variant="body2">{text}</Typography>
  </IconButton>
);

const UserPageOutlet = () => {
  // const query = useGetAuthUserQuery();
  //
  // const isNotSales = query.isSuccess && query.data?.role !== "SALES";
  const {data: business} = useGetCurrentBusinessQuery();
  const location = useLocation();
  const pathIsPreview = location.pathname.includes('/preview');

  return (
    <>
      <Box
        id="container"
        pb={pathIsPreview ? 4 : 12}
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          paddingTop: '56px',
          maxWidth: pathIsPreview ? '600px' : 'none',
          mx: 'auto',
          '@media (min-width: 600px)': {
            border: pathIsPreview ? '20px solid #000' : 'none',
            borderRadius: pathIsPreview ? '10px' : 'none',
            boxSizing: 'border-box',
            overflowY: 'auto',
            px: 1,
            my: 1,
          },
        }}>
        <Outlet />
      </Box>
      {business && <BusinessDetailsAppBar business={business} />}
      {!pathIsPreview && (
        <AppBar>
          <Toolbar sx={{justifyContent: 'space-around', flex: 1}}>
            <NavLink to="">
              <MenuIcon
                Icon={AccountTree}
                text={<FormattedMessage id="userPageOutlet.sections" />}
              />
            </NavLink>
            {business && (
              <>
                <Dialogable
                  dialog={({open, onClose}) => (
                    <DownloadBusinessQRCode
                      business={business}
                      open={open}
                      onClose={onClose}
                    />
                  )}
                  source={({onOpen, open}) => (
                    <MenuIcon
                      sx={{
                        color: open ? 'inherit' : grey['500'],
                      }}
                      Icon={PresentToAll}
                      text={<FormattedMessage id="userPageOutlet.website" />}
                      onClick={onOpen}
                    />
                  )}
                />
              </>
            )}
            <NavLink to="/settings">
              <MenuIcon
                Icon={Settings}
                text={<FormattedMessage id="userPageOutlet.settings" />}
              />
            </NavLink>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default UserPageOutlet;
