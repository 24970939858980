import Atoms from '../../components/atoms/intl/fr';
import Molecules from '../../components/molecules/intl/fr';
import Organisms from '../../components/organisms/intl/fr';
import AppOutlets from '../../lib/app-outlets/intl/fr';
import GeoLocationPicker from '../../lib/googlemaps/components/GeoLocationPicker/intl/fr';
import Common from '../../lib/intl-common/intl/fr';
import Validation from '../../lib/intl-validation/intl/fr';
import LanguagePicker from '../../lib/language-picker/components/intl/fr';
import BusinessInformationSectionActions from '../../modals/BusinessInformationSectionActions/intl/fr';
import BusinessInformationSectionBlockActions from '../../modals/BusinessInformationSectionBlockActions/intl/fr';
import CreateBusinessInformationSection from '../../modals/CreateBusinessInformationSection/intl/fr';
import CreateBusinessInformationSectionBlock from '../../modals/CreateBusinessInformationSectionBlock/intl/fr';
import DeleteAccount from '../../modals/DeleteAccount/intl/fr';
import DeleteBusinessInformationSection from '../../modals/DeleteBusinessInformationSection/intl/fr';
import DeleteBusinessInformationSectionBlock from '../../modals/DeleteBusinessInformationSectionBlock/intl/fr';
import DeleteBusinessInformationSectionBlockImage from '../../modals/DeleteBusinessInformationSectionBlockImage/intl/fr';
import DownloadBusinessQRCode from '../../modals/DownloadBusinessQRCode/intl/fr';
import MoveBusinessInformationSection from '../../modals/MoveBusinessInformationSection/intl/fr';
import MoveBusinessInformationSectionBlock from '../../modals/MoveBusinessInformationSectionBlock/intl/fr';
import RemoveAsTemplateModal from '../../modals/RemoveAsTemplateModal/intl/fr';
import UpdateBusinessInformationSection from '../../modals/UpdateBusinessInformationSection/intl/fr';
import UpdateBusinessInformationSectionBlock from '../../modals/UpdateBusinessInformationSectionBlock/intl/fr';
import UpdateBusinessInformationSectionBlockImage from '../../modals/UpdateBusinessInformationSectionBlockImage/intl/fr';
import BusinessPreferences from '../../pages/BusinessPreferences/intl/fr';
import BusinessSite from '../../pages/BusinessSite/intl/fr';
import CreateBusiness from '../../pages/CreateBusiness/intl/fr';
import EditGeneratedQRCodeStats from '../../pages/EditGeneratedQRCodeStats/intl/fr';
import EditProfile from '../../pages/EditProfile/intl/fr';
import ExtraSettings from '../../pages/ExtraSettings/intl/fr';
import Payment from '../../pages/Payment/intl/fr';
import ReportIssue from '../../pages/ReportIssue/intl/fr';
import Settings from '../../pages/Settings/intl/fr';
import Signin from '../../pages/Signin/intl/fr';
import EditBusinessImage from '../../pages/UpdateBusinessImage/intl/fr';
import UpdateBusinessInformation from '../../pages/UpdateBusinessInformation/intl/fr';
import UserBusinesses from '../../pages/UserBusinesses/intl/fr';

const FR = {
  ...Common,
  ...LanguagePicker,
  ...BusinessPreferences,
  ...BusinessSite,
  ...CreateBusiness,
  ...UserBusinesses,
  ...EditGeneratedQRCodeStats,
  ...EditProfile,
  ...ExtraSettings,
  ...Payment,
  ...ReportIssue,
  ...Settings,
  ...Signin,
  ...UpdateBusinessInformationSectionBlockImage,
  ...UpdateBusinessInformationSectionBlock,
  ...UpdateBusinessInformationSection,
  ...RemoveAsTemplateModal,
  ...EditBusinessImage,
  ...UpdateBusinessInformation,
  ...GeoLocationPicker,
  ...BusinessInformationSectionActions,
  ...BusinessInformationSectionBlockActions,
  ...CreateBusinessInformationSection,
  ...CreateBusinessInformationSectionBlock,
  ...DeleteAccount,
  ...DeleteBusinessInformationSection,
  ...DeleteBusinessInformationSectionBlock,
  ...DeleteBusinessInformationSectionBlockImage,
  ...DownloadBusinessQRCode,
  ...MoveBusinessInformationSection,
  ...MoveBusinessInformationSectionBlock,
  ...Molecules,
  ...Organisms,
  ...Atoms,
  ...AppOutlets,
  ...Validation,
} as const;

export type FrIntlKeys = keyof typeof FR;

export default FR;
