export default {
  'createBusiness.title': 'Neues Unternehmen erstellen',
  'createBusiness.donwloadImage': 'Ein Bild hochladen',
  'createBusiness.name': 'Name der Einrichtung',
  'createBusiness.description': 'Beschreibung',
  'createBusiness.category': `Geschäftsbereich`,
  'createBusiness.website': 'Website',
  'createBusiness.successMessage': `Das Unternehmen wurde erfolgreich erstellt`,
  'createBusiness.status': 'Actief',
  'createBusiness.disableMessage':
    'Let op: Het deactiveren van dit bedrijf resulteert in de opschorting van het abonnement en de invalidatie van de link naar de website van de klant.',
};
