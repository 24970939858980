import {createApi} from '@reduxjs/toolkit/query/react';

import {apiBaseQuery} from './apiBaseQuery';

const reportIssuesApi = createApi({
  reducerPath: 'reportIssuesApi',
  baseQuery: apiBaseQuery,
  tagTypes: ['Issue'],
  endpoints(build) {
    return {
      sendIssue: build.mutation({
        query: data => ({url: '/report-issues', method: 'post', data}),
      }),
    };
  },
});

export const {useSendIssueMutation} = reportIssuesApi;

export default reportIssuesApi;
