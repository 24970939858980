import {Button, CircularProgress, DialogActions, Stack} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React, {ComponentProps} from 'react';

const Confirmation = ({
  open,
  isLoading,
  description = 'Êtes-vous sûr vouloir confirmer cette action?',
  onConfirm,
  onClose,
  yesProps = {
    children: 'Oui',
  },
  noProps = {
    children: 'Non',
    variant: 'contained',
  },
}: {
  description?: string | React.ReactNode;
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onConfirm: () => void;
  yesProps?: ComponentProps<typeof Button>;
  noProps?: ComponentProps<typeof Button>;
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogContent>{description}</DialogContent>
    {!isLoading ? (
      <DialogActions>
        <Button {...yesProps} onClick={() => onConfirm()} />
        <Button {...noProps} onClick={() => onClose()} />
      </DialogActions>
    ) : (
      <Stack alignItems="center" p={2}>
        <CircularProgress size={24} />
      </Stack>
    )}
  </Dialog>
);

export default Confirmation;
