import {ComponentProps} from 'react';

import {Stack, Typography} from '@mui/material';

import {useField} from 'formik';

import {FormattedMessage} from '../../../lib/intl';
import AspectRatioPicker from '../AspectRatioPicker';
import FileInput from '../FileInput';

const FormikFileInputAspectRatio = ({
  name,
  aspectRatioName,
  ...props
}: {
  name: string;
  aspectRatioName: string;
} & Omit<
  ComponentProps<typeof FileInput>,
  'onFileSelect' | 'onFileRemove' | 'crop'
>) => {
  const [field, meta, helpers] = useField(name);
  const [aspectRatioField, , aspectRatioHelpers] = useField(aspectRatioName);

  return (
    <Stack spacing={2}>
      <FileInput
        {...props}
        onFileSelect={file => helpers.setValue(file)}
        value={field.value}
        onFileRemove={() => helpers.setValue('')}
        error={meta.touched && !!meta.error}
        crop
        aspectRatio={aspectRatioField.value}
      />
      <AspectRatioPicker
        error={!!(meta.touched && meta.error)}
        value={aspectRatioField.value}
        onChange={e => {
          if (e.target.value !== aspectRatioField.value) {
            helpers.setValue('');
            aspectRatioHelpers.setValue(e.target.value as AspectRatio);
          }
        }}
      />
      {meta.touched && meta.error ? (
        <Typography variant="body2" color="error">
          <FormattedMessage id={meta.error as any} />
        </Typography>
      ) : null}
    </Stack>
  );
};

export default FormikFileInputAspectRatio;
