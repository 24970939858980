import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate} from 'react-router-dom';

import {Stack} from '@mui/material';

import {internalError} from '../../lib/helpers/internalError';

import {Formik} from 'formik';
import * as yup from 'yup';

import FormikTextField from '../../components/atoms/formik/FormikTextField/FormikTextField';
import SaveButton from '../../components/atoms/SaveButton';
import PageAction from '../../components/organisms/PageAction';

import {FormattedMessage, useIntl} from '../../lib/intl';
import {
  useGetAuthUserQuery,
  useUpdateProfileMutation,
} from '../../redux/authApi';

const validationSchema = yup.object({
  firstName: yup.string().required('validation.fieldRequired'),
  lastName: yup.string().required('validation.fieldRequired'),
  email: yup.string().required('validation.fieldRequired'),
});

const EditProfile = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const query = useGetAuthUserQuery();
  const [updateProfile, mutation] = useUpdateProfileMutation();

  React.useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success(
        intl.formatMessage({id: 'editProfile.successMessage'}),
      );
      navigate('/settings');
    }

    if (mutation.isError && mutation.error) {
      internalError(mutation.error);
    }
  }, [mutation.isSuccess, navigate]);

  return (
    <PageAction title={intl.formatMessage({id: 'editProfile.title'})}>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{
          firstName: query.data?.firstName || '',
          lastName: query.data?.lastName || '',
          email: query.data?.email || '',
          phoneNumber: query.data?.phoneNumber || '',
        }}
        onSubmit={form =>
          updateProfile({
            firstName: form.firstName,
            lastName: form.lastName,
          })
        }>
        {({handleSubmit}) => (
          <Stack spacing={2}>
            <Stack spacing={1}>
              <FormikTextField
                name="firstName"
                label={intl.formatMessage({id: 'editProfile.firstName'})}
              />
              <FormikTextField
                name="lastName"
                label={intl.formatMessage({id: 'editProfile.lastName'})}
              />
              <FormikTextField
                name="email"
                label={intl.formatMessage({id: 'editProfile.email'})}
                disabled
              />
              <FormikTextField
                name="phoneNumber"
                label={intl.formatMessage({id: 'editProfile.phoneNumber'})}
                disabled
              />
            </Stack>
            <SaveButton
              loading={query.isLoading || mutation.isLoading}
              onClick={() => handleSubmit()}>
              <FormattedMessage id="common.save" />
            </SaveButton>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default EditProfile;
