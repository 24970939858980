import {ExpandMore} from '@mui/icons-material';
import {createTheme} from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#fff',
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: ['Roboto', 'Helvetica', 'sans-serif'].join(','),
    htmlFontSize: 18,
    fontSize: 18,
    button: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 400,
    },
    body1: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
    },
    body2: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 400,
      fontSize: '0.825rem',
    },
    h6: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      fontSize: '1rem',
    },
    h5: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      fontSize: '1.25rem',
    },
    h4: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      fontSize: '1.5rem',
    },
    h3: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      fontSize: '1.75rem',
    },
    h2: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      fontSize: '2rem',
    },
    h1: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      fontSize: '2.25rem',
    },
  },
  components: {
    MuiPaper: {
      defaultProps: {
        variant: 'elevation',
        elevation: 1,
      },
    },
    MuiToolbar: {
      defaultProps: {
        sx: {
          px: 1,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 'bold',
          fontSize: '0.825rem',
        },
      },
    },
    MuiTypography: {},
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          borderRadius: 4,
          '&.MuiInputBase-adornedStart input': {
            marginLeft: '8px',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
        },
      },
      defaultProps: {
        elevation: 0,
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <ExpandMore />,
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          '& li': {
            marginBottom: '0.75rem',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        sx: {
          textTransform: 'none',
          '&.MuiButton-sizeLarge': {
            fontSize: '1rem',
          },
        },
      },
    },
    MuiBreadcrumbs: {
      defaultProps: {
        sx: {
          pt: 2,
          pl: 1,
        },
      },
    },
  },
});
