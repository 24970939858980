import React from 'react';

import {LoadingButton} from '@mui/lab';
import {useTheme} from '@mui/material';

const RoundTextIconButton: React.FC<
  | {
      text: string | React.ReactNode;
      icon: React.ReactNode;
      variant?: 'text' | 'contained' | 'outlined';
      color?: 'primary' | 'secondary';
    } & Pick<
      React.ComponentProps<typeof LoadingButton>,
      'sx' | 'onClick' | 'size' | 'loading'
    >
> = ({
  color,
  icon,
  text = 'primary',
  variant = 'contained',
  sx,
  loading,
  ...props
}) => {
  const theme = useTheme();

  return (
    <LoadingButton
      variant={variant}
      color={color}
      startIcon={icon}
      loading={loading}
      sx={{
        ...sx,
        borderRadius: theme => Number(theme.shape.borderRadius) * 2,
        textTransform: 'none',
      }}
      {...props}>
      {text}
    </LoadingButton>
  );
};

export default RoundTextIconButton;
