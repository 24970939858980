import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate, useParams} from 'react-router-dom';

import {FormGroup, FormLabel, Stack} from '@mui/material';

import {internalError} from '../../lib/helpers/internalError';
import useCurrentBusinessId from '../../lib/hooks/use-current-business-id';
import useUploadFile from '../../lib/hooks/useUploadFile';

import {Formik} from 'formik';
import * as Yup from 'yup';

import FormikBusinessInformationSectionsPicker from '../../components/atoms/formik/FormikBusinessInformationSectionsPicker';
import FormikTextField from '../../components/atoms/formik/FormikTextField/FormikTextField';
import PageAction from '../../components/organisms/PageAction';

import FormikFileInputAspectRatio from '../../components/atoms/formik/FormikFileInputAspectRatio';
import SaveButton from '../../components/atoms/SaveButton';
import FormikRichText from '../../components/molecules/formik/FormikRichText';
import LoadingOverlay from '../../components/molecules/LoadingOverlay';
import isIntlFieldEmpty from '../../lib/helpers/is-intl-field-empty';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';
import {FormattedMessage, useIntl} from '../../lib/intl';
import useAppLanguage from '../../lib/intl/hooks/use-app-language';
import {
  useCreateBusinessInformationSectionBlockMutation,
  useGetBusinessInformationSectionByIdQuery,
} from '../../redux/businessInformationSectionsApi';

const validationSchema = Yup.object({
  image: Yup.string().requiredIf(
    form => isIntlFieldEmpty(form.contentIntl),
    'validation.requiredField',
  ),
  contentIntl: Yup.object()
    .intlRequiredIf(
      form => !isIntlFieldEmpty(form.headingIntl),
      'validation.requiredField',
    )
    .test(
      'textSize',
      'Le contenu dépasse la taille maximale autorisée de 15 Mo.',
      (intlValue: any) => {
        const valid = Object.keys(intlValue).every(lang => {
          const value = intlValue[lang];

          if (value === undefined) {
            return true;
          }

          const byteSize = new Blob([value]).size;

          return value && byteSize <= 1024 * 1024 * 10;
        });

        return valid;
      },
    ),
  section: Yup.string().required('validation.requiredField'),
});

const CreateBusinessInformationSectionBlock = () => {
  const business = useCurrentBusinessId();
  const navigate = useNavigate();
  const uploadFile = useUploadFile();
  const {sectionId} = useParams<{
    sectionId: string;
  }>();
  const lang = useAppLanguage();
  const intl = useIntl();
  const [loading, setLoading] = React.useState(false);
  const [createBlock] = useCreateBusinessInformationSectionBlockMutation();
  const {data: section, ...query} = useGetBusinessInformationSectionByIdQuery(
    {
      business,
      id: sectionId as string,
    },
    {
      skip: !sectionId,
    },
  );

  const handleCreate = async ({
    image,
    aspectRatio,
    headingIntl,
    contentIntl,
    business,
    section,
  }: any) => {
    let file: any;
    try {
      setLoading(true);
      if (image) {
        file = await uploadFile(image);
      }

      await createBlock({
        image: file ? file._id : undefined,
        aspectRatio,
        headingIntl,
        contentIntl,
        business,
        section,
      });
      NotificationManager.success(
        intl.formatMessage({
          id: 'createBusinessInformationSectionBlock.messageSuccess',
        }),
      );
      navigate(-1);
    } catch (err) {
      internalError(err);
    } finally {
      setLoading(false);
    }
  };

  useQueryInternalError(query);

  if (query.isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <PageAction
      title={intl.formatMessage({
        id: 'createBusinessInformationSectionBlock.title',
        values: {
          name: section?.nameIntl[lang],
        },
      })}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          image: '',
          heading: '',
          headingIntl: {},
          content: '',
          contentIntl: {},
          aspectRatio: '16:9',
          section: sectionId ?? '',
        }}
        onSubmit={({image, headingIntl, contentIntl, section, aspectRatio}) =>
          handleCreate({
            image: image ? image : undefined,
            headingIntl,
            contentIntl,
            business,
            section,
            aspectRatio,
          })
        }>
        {({handleSubmit, values}) => (
          <Stack pb={8} spacing={2} direction="column">
            <FormGroup>
              <FormLabel>
                <FormattedMessage id="createBusinessInformationSectionBlock.image" />
              </FormLabel>
              <FormikFileInputAspectRatio
                name="image"
                aspectRatioName="aspectRatio"
              />
            </FormGroup>
            <FormikTextField
              name="heading"
              label={
                <FormattedMessage id="createBusinessInformationSectionBlock.heading" />
              }
              enableIntl
              automaticTranslation
            />
            <FormikRichText
              name="content"
              label={
                <FormattedMessage id="createBusinessInformationSectionBlock.content" />
              }
              required
              enableIntl
              automaticTranslation
            />
            <FormikBusinessInformationSectionsPicker
              label={
                <FormattedMessage id="createBusinessInformationSectionBlock.section" />
              }
              name="section"
              disabled={!!sectionId}
            />
            <SaveButton
              sx={{
                position: 'fixed',
                bottom: theme => theme.spacing(0.5),
                left: theme => theme.spacing(0.5),
                right: theme => theme.spacing(0.5),
                margin: 'auto',
                zIndex: 99999,
              }}
              loading={loading}
              onClick={loading ? undefined : () => handleSubmit()}>
              <FormattedMessage id="common.save" />
            </SaveButton>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default CreateBusinessInformationSectionBlock;
