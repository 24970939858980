import * as yup from 'yup';
import type {IntlKeys} from '../intl';

yup.addMethod(
  yup.object,
  'intlRequired',
  function (message: string = 'validation.requiredField' as IntlKeys) {
    return this.test({
      name: 'intlRequired',
      message: '${path} is required',
      test: function (intlValue: {[key: string]: string}) {
        if (
          typeof intlValue === 'object' &&
          Object.keys(intlValue).length !== 0 &&
          Object.values(intlValue).some(value => !!value)
        ) {
          return true;
        } else {
          return this.createError({
            message,
            path: this.path,
          });
        }
      },
    });
  },
);

declare module 'yup' {
  // @ts-ignore
  interface ObjectSchema {
    intlRequired(message: IntlKeys): StringSchema;
  }
}
