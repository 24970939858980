import firebase from '../app/firebase';
import tryAsync from '../helpers/tryAsync';

import RecaptchaVerifier = firebase.auth.RecaptchaVerifier;

declare global {
  interface Window {
    recaptchaVerifier: typeof RecaptchaVerifier.prototype;
    recaptchaWidgetId: number;
  }
}

export const RECAPTCHA_CONTAINER_ID = 'recaptcha-container';

const authService = (() => {
  const obj: {
    phoneNumber: string;
    latestCode: string;
    confirmationResult?: any;
  } = {
    phoneNumber: '',
    latestCode: '',
  };

  let recaptchaVerifier: RecaptchaVerifier | null = null;

  return {
    getRecaptchaVerifier: ({
      onCallback,
    }: {
      onCallback?: (response: any) => void | Promise<void>;
    }) => {
      if (recaptchaVerifier) {
        return recaptchaVerifier;
      }

      const recaptchaContainer = document.createElement('div');
      recaptchaContainer.id = RECAPTCHA_CONTAINER_ID;
      document.body.appendChild(recaptchaContainer);

      recaptchaVerifier = new RecaptchaVerifier(RECAPTCHA_CONTAINER_ID, {
        size: 'invisible',
        callback: async (response: any) => {
          if (onCallback) {
            await onCallback(response);
            recaptchaVerifier = null;
            document.body.removeChild(recaptchaContainer);
          }
        },
      });

      return recaptchaVerifier;
    },
    signIn: async (phoneNumber: string) => {
      return new Promise(resolve => {
        tryAsync(async () => {
          obj.phoneNumber = phoneNumber;
          obj.confirmationResult = await firebase.auth().signInWithPhoneNumber(
            phoneNumber,
            authService.getRecaptchaVerifier({
              onCallback: response => {
                resolve(response);
              },
            }),
          );
        }, 3);
      });
    },
    confirmCode: async (code: string): Promise<any | null> => {
      return tryAsync(async () => {
        obj.latestCode = code;
        if (obj.confirmationResult) {
          return obj.confirmationResult.confirm(code);
        }

        throw {
          code: 'signInRequiredForConfirmCode',
        };
      }, 1);
    },
    resendCode: async () => {
      if (obj.phoneNumber) {
        tryAsync(async () => {
          obj.confirmationResult = await firebase
            .auth()
            .signInWithPhoneNumber(obj.phoneNumber, window.recaptchaVerifier);
        }, 3);
      }
    },
    getCredential: () => {
      if (!obj.confirmationResult) {
        throw {
          code: 'confirmationResultRequiredForCredential',
        };
      }

      if (!obj.latestCode) {
        throw {
          code: 'latestCodeRequiredForCredential',
        };
      }

      return firebase.auth.PhoneAuthProvider.credential(
        obj.confirmationResult.verificationId,
        obj.latestCode,
      );
    },
    logout: () => {
      return firebase.auth().signOut();
    },
  };
})();

export default authService;
