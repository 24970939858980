import React from 'react';

import {
  Autocomplete,
  AutocompleteProps,
  Stack,
  Typography,
} from '@mui/material';

import {useField} from 'formik';

const FormikAutoComplete: React.FC<
  {
    name: string;
  } & AutocompleteProps<any, any, any, any>
> = ({name, options, onChange, ...props}) => {
  const [field, meta, helpers] = useField(name);

  return (
    <Stack>
      <Autocomplete
        {...props}
        options={options}
        onChange={(event, newValue, reason, details) => {
          helpers.setValue(newValue?.id || newValue);
          if (typeof onChange === 'function') {
            onChange(event, newValue, reason, details);
          }
        }}
        value={options.find((option: any) => option.id === field.value) || ''}
      />
      {meta.touched && meta.error && (
        <Typography variant="body2" color="error">
          {meta.error}
        </Typography>
      )}
    </Stack>
  );
};

export default FormikAutoComplete;
