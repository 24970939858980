export default {
  'authPhoneNumber.invalidPhoneNumber':
    'Die von Ihnen eingegebene Telefonnummer ist ungültig (Beispiel: +33611222333, +12481237654, +34600000000)',
  'authPhoneNumber.tooManyRequests':
    'Sie haben mehrmals versucht, sich anzumelden. Versuchen Sie es später erneut',
  'completeInformation.title': 'Erstellen Sie Ihre Einrichtung',
  'completeInformation.accountInformation': 'Kontoinformationen',
  'completeInformation.phoneNumber': 'Telefonnummer',
  'completeInformation.changePhoneNumber': 'Telefonnummer ändern',
  'completeInformation.generalInformation': 'Allgemeine Informationen',
  'completeInformation.businessName': `Name der Einrichtung`,
  'completeInformation.uploadLogo': 'Ein Logo hochladen',
  'completeInformation.email': 'E-Mail',
  'completeInformation.lastName': 'Name',
  'completeInformation.firstName': 'Vorname',
  'completeInformation.activitySector': `Aktivitätssektor`,
  'completeInformation.description': 'Beschreibung',
  'completeInformation.website': 'Website',
  'completeInformation.receivedCode': 'Der per SMS erhaltene Validierungscode',
  'completeInformation.confirmationCode': 'Bestätigungscode',
  'completeInformation.successMessage': 'Ihre Registrierung ist abgeschlossen',
  'formikPhoneNumberInput.countryNotFound': 'Kein Land entspricht Ihrer Suche',
  'googleRecaptchaDisclaimer.message': `Diese Website ist durch reCAPTCHA geschützt und es gelten die Datenschutzbestimmungen und Nutzungsbedingungen von Google.`,
  'helpSection.bullet1': `Erstellen Sie Ihre Abschnitte und Unterabschnitte: Fügen Sie die Beschreibung Ihrer Dienste mit Ihren besten Fotos ein`,
  'helpSection.bullet2': `Laden Sie Ihren QR-Code herunter und zeigen Sie ihn an: Ihre Kunden haben über einen einzigen QR-Code Zugriff auf alle Ihre Dienste.`,
  'helpSection.bullet3': `Erstellen Sie personalisierte professionelle QR-Codes: Entwerfen Sie einzigartige QR-Codes für Ihre Restaurantmenüs, Hotelverzeichnisse oder Servicepräsentationen mit erweiterten Anpassungsoptionen.`,
  'helpSection.bullet4': `Mehrere Einrichtungen verwalten: Sie können so viele Einrichtungen erstellen, wie Sie möchten. Jede Einrichtung verfügt über einen eigenen QR-Code`,
  'helpSection.bullet5': `Genießen Sie reaktionsschnellen Kundensupport: Unser engagiertes Team ist hier, um Ihnen bei jedem Schritt zu helfen, sei es bei der Lösung eines technischen Problems oder der Beratung zu Best Practices für professionelle QR-Codes.`,
  'helpSection.bullet6': `Verwalten Sie Ihre QR-Codes mühelos: Verwalten und aktualisieren Sie Ihre QR-Codes in Echtzeit mit mehreren Funktionen.`,
  'helpSection.forMoreInfo': 'Für weitere Informationen über',
  'phoneNumberForm.digitalizeServices': 'Digitalisieren Sie Ihre Dienste',
  'phoneNumberForm.printedSupports':
    'Erwecken Sie Ihre gedruckten Medien mit QR-Codes zum Leben',
  'phoneNumberForm.yourMobileNumber': 'Ihre Handynummer:',
  'phoneNumberForm.cgu1': `Wenn Sie fortfahren, autorisieren Sie FluXence Pro, Ihre Nummer zu speichern
            Telefonnummer, und Sie akzeptieren unsere`,
  'phoneNumberForm.cgu2': `Nutzungsbedingungen`,
  'phoneNumberForm.cgu3': `und`,
  'phoneNumberForm.cgu4': `unsere Datenschutzrichtlinie`,
  'resendCodeButton.didNotReceiveCode': `Ich habe keinen Code erhalten!`,
  'signinConfirmationCode.invalidCode':
    'Der von Ihnen eingegebene Code ist ungültig',
  'signinConfirmationCode.tooManyRequests':
    'Sie haben mehrmals versucht, sich anzumelden. Versuchen Sie es später erneut',
  'signinConfirmationCode.smsSending': 'SMS-Versand läuft. Bitte warten',
  'signupConfirmationCode.invalidCode':
    'Der von Ihnen eingegebene Code ist ungültig',
  'signupConfirmationCode.tooManyRequests':
    'Sie haben mehrmals versucht, sich anzumelden. Versuchen Sie es später erneut',
  'signupConfirmationCode.smsSending': 'SMS-Versand läuft. Bitte warten',
  'helpSection.whyChooseUs': 'Warum uns wählen?',
  'helpSection.forWho': 'Für wen?',
  'helpSection.forWho1': `Restaurants: Ihr Menü auf QR-Code`,
  'helpSection.forWho2': `Hotels, AirBnb: Ihr Willkommensheft auf QR-Code`,
  'helpSection.forWho3': `Profis: Ihre Dienstleistungen auf QR-Code`,
};
